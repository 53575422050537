import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getMyposTerminalList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/mypos_terminals' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getMyposTerminalDetails = async (id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/mypos_terminals/' + id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const saveMyposTerminalDetails = async (id, data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.apiV2Url + '/mypos_terminals/' + id, data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const pairMyposTerminal = async (id, data={}) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/mypos_terminals/' + id + '/pair', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const unpairMyposTerminal = async (id, data={}) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/mypos_terminals/' + id + '/unpair', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getMyposTerminalStatus = async (id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/mypos_terminals/' + id + '/status', { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const createMyposTerminal = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/mypos_terminals', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteMyposTerminal = async (id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.apiV2Url + '/mypos_terminals/' + id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const importMyposTerminals = async (formData) => {
    try {
        let headers = getRequestHeaders();
        headers['Content-Type'] = 'multipart/form-data';    
        let response = await axios.post(config.apiV2Url + '/mypos_terminals/import', formData,
            {
                mode: 'cors',
                headers: headers
            }
        )
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getMyposTerminalList,
    getMyposTerminalDetails,
    saveMyposTerminalDetails,
    pairMyposTerminal,
    unpairMyposTerminal,
    getMyposTerminalStatus,
    createMyposTerminal,
    deleteMyposTerminal,
    importMyposTerminals
}
