import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { translate } from '../../i18n/customI18nProvider';
import { request } from './../../util';
import config from './../../config';
import Swal from 'sweetalert2';
// import axios
import axios from 'axios';
import {
    FileInput,
    FileField,
    SimpleForm,
    Toolbar
} from 'react-admin';
import { convertMessageCodeToMessage } from './../../util';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: 'whitesmoke',
    // border: '2px solid #000',
    borderRadius: '10px',
    // boxShadow: '10px 10px 5px #8d9093',
    padding: '20px',
  },
};

const DBRestoreToolbar = props => (
    <Toolbar {...props} style={{visibility: 'hidden', display: 'none'}} ></Toolbar>
);

export default class DBRestore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            version: "",
            name: "",
            selected_file: null,
            selected_file_buffer: null,
            upload_enabled: true
        }
    }

    handleOpen = () => {
        this.setState({open: true});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleFileChange = async (e) => {

        if (e) {
            this.setState({
                upload_enabled: false
            })
            var reader = new FileReader();
            reader.onload = (e) => {
                let arrayBuffer = reader.result;
                let uInt8Array = new Uint8Array(arrayBuffer);
                this.setState({selected_file_buffer: (uInt8Array.toString()).split(','), upload_enabled: true});
            }
            reader.readAsArrayBuffer(e);
            this.setState({selected_file: e})
        } else {
            this.setState({selected_file: null});
            this.setState({selected_file_buffer: null});
        }
    }

    delay = async (ms) => {
        await this.timer(ms);
    }

    timer = (ms) => {
        return new Promise(res => setTimeout(res, ms));
    }

    uploadBackupFile = async () => {

        if (!this.state.selected_file) {
            return
        }
        
        this.handleClose();

        const inProgressSwal = Swal.fire({
            title: translate('custom.processing'),
            allowOutsideClick: false,
            showCancelButton: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        try {

            let response = await request('/events/db_restore', 'POST', null, {
                'backup_file': this.state.selected_file,
                'backup_file_buffer': this.state.selected_file_buffer
            });

            if(response.error) {
                inProgressSwal.close();
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? (convertMessageCodeToMessage(response.error_code)) : (response.error ? response.error : translate('custom.unknown_error')),
                    icon: 'error',
                    confirmButtonText: translate('custom.ok'),
                    confirmButtonColor: config.baseColor
                });
            } else {
                inProgressSwal.close();
                Swal.fire({

                    title: translate('custom.db_restore_success'),
                    icon: 'success',
                    confirmButtonText: translate('custom.ok'),
                    confirmButtonColor: config.baseColor
                }).then(() => {
                    window.location.reload();
                });
            }
        } catch(e) {
            inProgressSwal.close();
            Swal.fire({
                title: translate('custom.error'),
                text: e.message ? e.message : translate('custom.unknown_error'),
                icon: 'error',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            });
        }
    }

    render() {
        return (
            <>
                <Button
                    color="primary"
                    style={{marginLeft: '10px', marginTop: 'auto', marginBottom: 'auto'}}
                    onClick={this.handleOpen}
                    variant="outlined"
                    startIcon={<PostAddIcon />}
                    size="small"
                >
                    {translate('custom.db_restore')}
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={styles.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div style={styles.paper}>


                            <SimpleForm toolbar={<DBRestoreToolbar />} >


                                <FileInput
                                    source="files"
                                    label={translate('custom.select_backup_file')}
                                    accept=".backup"
                                    multiple={false}
                                    onChange={this.handleFileChange}
                                >
                                    <FileField source="src" title='title' />
                                </FileInput>

                                <Button
                                    color="primary"
                                    onClick={this.uploadBackupFile}
                                    variant="contained"
                                    size="small"
                                    fullWidth={true}
                                    disabled={!this.state.upload_enabled}
                                >
                                    {translate('custom.upload')}
                                </Button>

                            </SimpleForm>

                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }

}
