import React, { Component, forwardRef } from 'react';
import '../css/App.css';

import 'whatwg-fetch';

import  { Redirect } from 'react-router-dom';
import config from '../config';

// ----- Locaization -------
import { i18nProvider, translate } from "../i18n/customI18nProvider";
// ----- Localization Ends ----

import { fetchUtils, Admin, Resource, useLogout, useRefresh } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { UserList, UserEdit, UserCreate, UserIcon } from '../forms/Users';
import { BarList, BarEdit, BarCreate, BarIcon } from '../forms/Bars';
import { ConsumptionList, ConsumptionEdit, ConsumptionCreate, ConsumptionIcon } from '../forms/AllConsumptions';
import { DepositList, DepositEdit, DepositCreate, DepositIcon } from '../forms/Deposits';
import { ZoneList, ZoneEdit, ZoneIcon } from '../forms/Zones';
import { EntranceList, EntranceEdit, EntranceCreate, EntranceIcon } from '../forms/Entrances';
import { EntranceGroupList, EntranceGroupEdit, EntranceGroupCreate, EntranceGroupIcon } from '../forms/EntranceGroups';
import { ClientList, ClientEdit, ClientIcon } from '../forms/Clients';
import { EventList, EventEdit, EventCreate, EventIcon } from './../forms/Events';
import { AutoLoginEdit, AutoLoginList, AutoLoginCreate, AutoLoginIcon } from '../forms/AutoLogins';
import { ReplicaSetList, ReplicaSetIcon } from '../forms/ReplicaSets';
import { VoucherEdit, VoucherList, VoucherCreate, VoucherIcon } from '../forms/Vouchers';
import { VoucherPackEdit, VoucherPackList, VoucherPackCreate, VoucherPackIcon } from '../forms/VoucherPacks';
import { SmartphonesList, SmartphoneShow, SmartphoneIcon } from '../forms/Smartphones';
import { RefundsList, RefundIcon } from '../forms/Refunds';
import { RefundErrorsList, RefundErrorIcon } from '../forms/RefundErrors';
import { SuspiciousClientList, SuspiciousClientIcon } from '../forms/SuspiciousClients';
import { ReportsList, ReportIcon } from '../forms/Reports';
import { LatestAppList, LatestAppIcon } from '../forms/LatestApp';
import { FBIcon, FeedbackList } from '../forms/Feedbacks';

import {languageCheckAndReload, request} from "../util";

import theme from '../components/reactAdmin/Theme';
import authProvider from '../components/AuthProvider';
import layout from '../components/reactAdmin/Layout';
import ActionLogout from '@mui/icons-material/PowerSettingsNew';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getName, getRight, getToken } from '../services/storage';

// import myDataProvider from '../myDataProvider';

const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <ListItem button  variant="disabled"
                  onClick={handleClick}
        >
            <ListItemIcon >
                <ActionLogout />
            </ListItemIcon>
            <ListItemText primary={translate('custom.logout')} />
        </ListItem>
    );
});

const httpClient = (url, options = {}) => {

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('x-access-token', getToken());
    // We fetch all entrances instead of the ones only available at the selected date
    // The code isn't really pretty but that's the only way of doing it
    // https://marmelab.com/react-admin/DataProviders.html
    url = url.replace('/api/entrances?', '/api/entrances/all?');
    url = url.replace('/api/entrance_groups?', '/api/entrance_groups/all?');
    // fix for the bars list while creating users
    url = url.replace('/api/bars?', '/api/bars/all?');
    // fix for the zones list while creating entrance control
    url = url.replace('/api/zones?', '/api/zones/all?');
    // fix for the events list while creating users
    url = url.replace('/api/events?', '/api/events/all?');
    return fetchUtils.fetchJson(url, options);
};


// --------------- Adding custom data provider to handle image upload ----------------
const dataProvider = simpleRestProvider(config.apiUrl, httpClient);

const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {


        if (resource !== 'events') {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }

        if (resource == 'events') {
            /**
             * For event parameters update only, convert uploaded image in base 64 and attach it to
             * the `picture` sent property, with `src` and `title` attributes.
             */
            
            // Freshly dropped pictures are File objects and must be converted to base64 strings
            let newPictures = params.data?.customization?.logo.filter(
                p => p.rawFile instanceof File
            );
            let formerPictures = params.data?.customization?.logo.filter(
                p => !(p.rawFile instanceof File)
            );

            if (!newPictures || newPictures.length === 0) {
                // No need to convert non-new pictures
                return dataProvider.update(resource, params);
            }

            if (!formerPictures || formerPictures.length === 0) {
                formerPictures = []
            }

            return Promise.all(newPictures.map(convertFileToBase64))
                .then(base64Pictures =>
                    base64Pictures.map(picture64 => ({
                        src: picture64,
                        title: `${params.data.title}`,
                    }))
                )
                .then(transformedNewPictures => {
                    let paramsObj = {...params}
                    if (!paramsObj.data.customization) {
                        paramsObj.data.customization = {}
                    }
                    paramsObj.data.customization.logo = [
                        ...transformedNewPictures,
                        ...formerPictures,
                    ]
                    return dataProvider.update(resource, paramsObj)
                });
        }
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });


// --------------- Adding custom data provider to handle image upload Ends ----------------

const knownResources = [
    {
        key: "events",
        element: <Resource name="events" key="events" options={{label: (getRight() < config.permissions.SUPERADMIN.value) ? translate('custom.parameters') : translate('custom.events')}} list={EventList} edit={EventEdit}
                    create={EventCreate} icon={EventIcon}/>
    },
    {
        key: "bars",
        element: <Resource name="bars" key="bars" options={{label: translate('custom.point_of_sales')}} list={BarList} edit={BarEdit}
                    create={BarCreate} icon={BarIcon} />
    },
    {
        key: "all_consumptions",
        element: <Resource name="all_consumptions" key="all_consumptions" options={{label: translate('custom.consumptions')}} list={ConsumptionList} edit={ConsumptionEdit}
                    create={ConsumptionCreate} icon={ConsumptionIcon} />
    },
    {
        key: "deposits",
        element: <Resource name="deposits" key="deposits" options={{label: translate('custom.deposits')}} list={DepositList} edit={DepositEdit}
                    create={DepositCreate} icon={DepositIcon} />
    },
    {
        key: "zones",
        element: <Resource name="zones" key="zones" options={{label: translate('custom.zones')}} list={ZoneList} edit={ZoneEdit}
                    icon={ZoneIcon}/>
    },
    {
        key: "entrances",
        element: <Resource name="entrances" key="entrances" options={{label: translate('custom.entrances')}} list={EntranceList} edit={EntranceEdit}
                    create={EntranceCreate} icon={EntranceIcon}/>
    },
    {
        key: "users",
        element: <Resource name="users" key="users" options={{ label: translate('custom.users') }} list={UserList} edit={UserEdit}
                    create={UserCreate} icon={UserIcon} />
    },
    {
        key: "entrance_groups",
        element: <Resource name="entrance_groups" key="entrance_groups" options={{label: translate('custom.entrance_control')}} list={EntranceGroupList}
                    edit={EntranceGroupEdit} create={EntranceGroupCreate} icon={EntranceGroupIcon}/>
    },
    {
        key: "clients",
        element: <Resource name="clients" key="clients" options={{label: translate('custom.clients')}} list={ClientList} edit={ClientEdit}
                    icon={ClientIcon}/>
    },
    {
        key: "transactions",
        element: <Resource name="transactions" key="transactions" options={{label: translate('custom.transactions')}}/>
    },
    {
        key: "entrance_checks",
        element: <Resource name="entrance_checks" key="entrance_checks" options={{label: translate('custom.entrance_checks')}}/>
    },
    // {
    //     key: "auto_logins",
    //     element: <Resource name="auto_logins" key="auto_logins" options={{label: translate('custom.auto_logins')}} list={AutoLoginList} edit={AutoLoginEdit}
    //                 create={AutoLoginCreate} icon={AutoLoginIcon}/>
    // },
    {
        key: "replica_sets",
        element: <Resource name="replica_sets" key="replica_sets" options={{label: translate('custom.replica_sets')}} list={ReplicaSetList}
                    icon={ReplicaSetIcon}/>
    },
    {
        key: "vouchers",
        element: <Resource name="vouchers" key="vouchers" options={{label: translate('custom.vouchers')}} list={VoucherList} edit={VoucherEdit}
                    create={VoucherCreate} icon={VoucherIcon}/>
    },
    {
        key: "voucher_packs",
        element: <Resource name="voucher_packs" key="voucher_packs" options={{label: translate('custom.voucher_packs')}} list={VoucherPackList} edit={VoucherPackEdit}
                    create={VoucherPackCreate} icon={VoucherPackIcon}/>
    },
    {
        key: "smartphones",
        element: <Resource name="smartphones" key="smartphones" options={{label: translate('custom.smartphones')}} list={SmartphonesList}
                    show={SmartphoneShow} // edit={VoucherPackEdit} create={VoucherPackCreate} 
                    icon={SmartphoneIcon} />
    },
    {
        key: "refunds",
        element: <Resource name="refunds" key="refunds" options={{label: translate('custom.refunds')}} list={RefundsList} icon={RefundIcon} />
    },
    {
        key: "reports",
        element: <Resource name="reports" key="reports" options={{label: translate('custom.reports')}} list={ReportsList} icon={ReportIcon} />
    },
    {
        key: "feedbacks",
        element: <Resource name="feedbacks" key="feedbacks" options={{label: translate('custom.feedbacks')}} list={FeedbackList} icon={FBIcon} />
    },
    {
        key: "refund_errors",
        element: <Resource name="refund_errors" key="refund_errors" options={{label: translate('custom.refund_errors')}} list={RefundErrorsList} icon={RefundErrorIcon} />
    },
    {
        key: "suspicious_clients",
        element: <Resource name="suspicious_clients" key="suspicious_clients" options={{label: translate('custom.suspicious_clients')}} list={SuspiciousClientList} icon={SuspiciousClientIcon}/>
    },
    {
        key: "latest_app",
        element: <Resource name="latest_app" key="latest_app" options={{label: translate('custom.latest_app')}} list={LatestAppList} icon={LatestAppIcon}/>
    }
]

class AdminA extends Component {

    constructor(props){
        super(props);
        this.state = {
            token: getToken(),
            right: getRight(),
            auto_login: false,
            currency: '',
            currencyCode: '',
            currencySymbol: '',
        };
        this.autoLogin = null;
        this.hash = null;
    }

    // Required for pagination
    shouldComponentUpdate() {
        if(this.autoLogin !== this.state.auto_login) {
            this.autoLogin = this.state.auto_login;
            return true;
        }
        return false;
    }

    getFilteredResources(keys) {
        let fltRes = [];
        knownResources.map((knwRes) => {
            if(keys.indexOf(knwRes['key']) !== -1) {
                // console.log(knwRes['element']);
                knwRes['element'].props.options.currency = this.state.currency;
                knwRes['element'].props.options.currencyCode = this.state.currencyCode;
                knwRes['element'].props.options.currencySymbol = this.state.currencySymbol;
                fltRes.push(knwRes['element']);
            }
            return knwRes;
        });
        return fltRes;
    }

    filterKnownResources() {

        let displayResources = [];

        if (this.state.right >= config.permissions.SUPERADMIN.value) {
            displayResources = this.getFilteredResources(["events", "bars", "users", "transactions", "entrance_checks", "clients", "replica_sets", "latest_app"]);
        } else if (this.state.right >= config.permissions.ADMIN.value) {
            displayResources = this.getFilteredResources(["events", "bars", "all_consumptions", "deposits", "zones", "entrances", "users", "entrance_groups", "clients", "suspicious_clients", "vouchers", "voucher_packs", "smartphones", "refunds", "refund_errors", "reports", "transactions", "entrance_checks", "feedbacks"]);
            // displayResources = this.getFilteredResources(["events", "bars", "all_consumptions", "zones", "entrances", "users", "entrance_groups", "clients", "suspicious_clients", "vouchers", "voucher_packs", "smartphones", "refunds", "refund_errors", "reports", "transactions"]);
        } else {
            displayResources = this.getFilteredResources(["bars", "users"]);
        }

        return displayResources;
    }

    render() {
        if(!this.state.token)
            return (<Redirect to='/login' />);
        else
            return this.admin_render();
    }

    admin_render() {
        document.title = getName() ? getName() : config.applicationName;
        return (
            <Admin
                theme={theme}
                i18nProvider={i18nProvider}
                // dataProvider={simpleRestProvider(config.apiUrl, httpClient)}
                dataProvider={myDataProvider}
                authProvider={authProvider}
                layout={layout}
                logoutButton={MyLogoutButton}
                style={{backgroundColor: 'white'}}
            >

                {this.filterKnownResources()}

            </Admin>
        )
    }
};

export default AdminA;
