import React, { useState } from 'react';
import {
    ReferenceInput,
    SelectInput,
    PasswordInput,
    ReferenceField,
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    required,
    SimpleList,
    FormWithRedirect,
    SaveButton,
    DeleteButton,
    Pagination,
    useNotify,
    Filter,
    useRefresh,
    FormDataConsumer,
    AutocompleteArrayInput,
    Button,
    AutocompleteInput,
    useRedirect
} from 'react-admin';
import Person from '@mui/icons-material/Person';
import RightField from '../fields/RightField';
import RightInput from '../fields/RightInput';
import Actions from '../components/reactAdmin/Actions';
import { Grid, useMediaQuery } from '@material-ui/core';
import { getLabel } from "../util";
import config from "../config";
import { Typography, Box, Toolbar } from '@material-ui/core';
import {translate} from "../i18n/customI18nProvider";
import SaveIcon from '@mui/icons-material/Save';
import { useFormState } from 'react-final-form';
import { request } from '../util';
import Chip from '@material-ui/core/Chip';
import RestoreIcon from '@mui/icons-material/Restore';
import MaterialButton from '@material-ui/core/Button';

import { convertMessageCodeToMessage } from '../util';
import { clear, getRight } from '../services/storage';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom';
import { getDeviceList } from '../services/device.service';

export const UserIcon = Person;

const headerTextStyles = {
    width: '100%',
    borderBottom: '2px solid' + config.baseColor,
    marginBottom: '50px',
    color: config.baseColor
}

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const UserListFilter = (props) => (
    <Filter {...props}>
        <TextInput label={translate('custom.username')} source="email" variant='outlined' alwaysOn />
        {(props.right >= config.permissions.SUPERADMIN.value) ? (
            <ReferenceInput variant='outlined' label={translate('custom.event')} reference="events" source="event_id" filterToQuery={searchText => ({ name: searchText })} alwaysOn >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        ) : null}
        {(props.right >= config.permissions.ADMIN.value) ? (
            <TextInput label={translate('custom.smartphone_number')} source="smartphones" variant='outlined' alwaysOn />
        ) : null}
        {(props.right >= config.permissions.SUPERADMIN.value) ? (
            <QuickFilter source="archived" label={translate('custom.archived')} defaultValue={true} />
        ) : null}
        {(props.right >= config.permissions.SUPERADMIN.value) ? (
            <QuickFilter source="event_ended" label={translate('custom.event_ended')} defaultValue={true} />
        ) : null}
        <QuickFilter source="deleted_only" label={translate('custom.deleted_only')} defaultValue={true} />
        <QuickFilter source="with_deleted" label={translate('custom.with_deleted')} defaultValue={true} />
    </Filter>
);

const UserListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(record.deleted) {
        return (
            <>
                <MaterialButton
                    color="primary"
                    startIcon={<RestoreIcon />}
                    onClick={async () => {
                        try {
                            await request('/users/' + record._id + '/restore', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px'}}
                >
                    {translate('custom.restore')}
                </MaterialButton>
            </>
        )
    } else {
        return (
            <>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
            </>
        )
    }
}

const SmartphonesList = ({ record }) => {
    let smartphones = record?.smartphones ? record.smartphones : [];
    return (
        smartphones.map((smartphone) => (
            <Chip label={smartphone} style={{margin: 1}} />
        ))
    )
}

export const UserList = (props) => {

    const right = getRight();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }
    return (
        <List {...props}
            actions={<Actions />}
            filters={<UserListFilter right={right} />}
            pagination={<UserListPagination />}
            perPage={50}
        >
            {isSmall ? (
                    <SimpleList
                        primaryText={record => record.email}
                        secondaryText={record => (
                            <ReferenceField basePath="bars" record={record} source="bar_id" reference="bars" linkType={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        )}
                        tertiaryText={record => getLabel(record.right, config.permissions)}
                    />
            ) : (
                <Datagrid>
                    {(right >= config.permissions.SUPERADMIN.value) ? (
                        <ReferenceField label={translate('custom.event')} source="event_id" reference="events" sortable={false} >
                            <TextField source="name" />
                        </ReferenceField>
                    ) : null}
                    <ReferenceField label="Bar" source="bar_id" reference="bars">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField label={translate('custom.username')} source="email" />
                    <RightField label="Permission" source="right" />
                    {(right >= config.permissions.ADMIN.value) ? (
                        <SmartphonesList label={translate('custom.smartphones')} />
                    ) : null}
                    <CustomButtons />
                </Datagrid>
            )}
        </List>
    )
};

const PostTitle = ({ record }) => {
    return <span>{translate('custom.user')} {record ? `"${record.email}"` : ''}</span>;
};

const SaveAndAddNewToolbar = (props) => {

    const { values } = useFormState();
    const notify = useNotify();

    return (
        <Toolbar>
            <SaveButton
                {...props}
                label="ra.action.save"
                redirect="edit"
                submitOnEnter={false}
                style={{marginRight: 10}}
            />
            {(props.right >= config.permissions.ADMIN.value) ? (
                <MaterialButton
                    color="primary"
                    variant="contained"
                    submitOnEnter={false}
                    startIcon={<SaveIcon />}
                    style={{marginRight: 10}}
                    onClick={async () => {
                        let user = await request('/users/force', 'POST', null, values);
                        if(user.error) {
                            notify('custom.force_create_failed' + ' ' + user.error_code ? convertMessageCodeToMessage(user.error_code) : user.error, {type: 'error'});
                        } else {
                            notify('custom.force_create_successful', { type: 'info' });
                        }
                    }}
                >
                    {translate('custom.force_save')}
                </MaterialButton>
            ) : null}
            <SaveButton
                {...props}
                label={translate('custom.save_and_add')}
                redirect={() => {
                    window.location.reload();
                }}
                submitOnEnter={true}
            />
        </Toolbar>
    )
}

const CopySecureLoginLink = (props) => {
    const record = props.record;
    const [copied, setCopied] = useState(false);
    return (
        <MaterialButton
            style={{marginTop: 10}}
            variant="outlined"
            color="primary"
            onClick={() => {
                const link = `${window.location.origin}/login?token=${record.token}`
                navigator.clipboard.writeText(link)
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 2000);
            }}
            disabled={copied}
        >
            {copied ? translate('custom.copied') : translate('custom.copy_secure_login_link')}
        </MaterialButton>
    );
}
    

const form = (right, edit) => {

    return (
        <>
            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.identifiers')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth source="email" label={translate('custom.username')} validate={required()} variant='outlined' autoFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <PasswordInput fullWidth  source="password" label={translate('custom.password')} validate={edit ? null : required()} variant='outlined' />
                </Grid>
            </Grid>

            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.configuration')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                {(right >= config.permissions.SUPERADMIN.value) ? (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <ReferenceInput fullWidth source="bar_id" reference="bars" label={translate('custom.point_of_sale')} validate={required()} variant='outlined' >
                            <SelectInput
                                optionText={(record) => {
                                    return record.event_name + ' - ' + record.name
                                }}
                            />
                        </ReferenceInput>
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <ReferenceInput fullWidth source="bar_id" reference="bars" label={translate('custom.point_of_sale')} validate={required()} variant='outlined' >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RightInput fullWidth source="right" label={translate('custom.permission')} validate={required()} variant='outlined' />
                </Grid>
            </Grid>
        </>
    );
}


const SaveToolbar = (props) => {

    const { values } = useFormState();
    const notify = useNotify();
    const history = useHistory();

    return (
        <Toolbar>
            <SaveButton
                {...props}
                label="ra.action.save"
                redirect="list"
                submitOnEnter={false}
                onClick={() => {
                    setTimeout(async () => {
                        let token_verified = await request('/users/token_valid', 'GET', null)
                        if(token_verified.error || !token_verified.token_valid) {
                            clear();
                            window.location.reload()
                        }                        
                    }, 2000);
                }}
            />
            {(props.right >= config.permissions.ADMIN.value) ? (
                <MaterialButton
                    color="primary"
                    label={translate('custom.force_save')}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    style={{marginLeft: 10}}
                    onClick={async () => {
                        const record = props.record;
                        let user = await request('/users/'+String(record._id)+'/force', 'PUT', null, values);
                        if(user.error) {
                            notify('custom.force_update_failed' + ' ' + user.error, {type: 'error'});
                        } else {
                            notify('custom.force_update_successful', { type: 'info' });
                            history.goBack();
                        }
                    }}
                >
                    {translate('custom.force_save')}
                </MaterialButton>
            ) : null}
        </Toolbar>
    )
}

const validateUserForm = async (values) => {

    let errors = {};


    let payload = {
        "email" : values['email'],
        "smartphones" : values['smartphones']
    }
    if ("_id" in values) {
        payload["_id"] = values['_id'];
    }

    let user_validate = await request('/users/validate', 'POST', null, payload);

    if (user_validate?.email) {
        errors['email'] = convertMessageCodeToMessage(user_validate?.email)
    }
    
    if (user_validate?.smartphones) {
        if (user_validate.smartphones?.length > 0) {
            errors['smartphones'] = '';
            for (let i=0; i<user_validate.smartphones.length; i++) {
                if (errors['smartphones'] != '') {
                    errors['smartphones'] += ' & ';
                }
                errors['smartphones'] += translate('custom.smartphone') + ' - ' + user_validate.smartphones[i].smartphone_number + ' ' + translate('custom.is_already_taken_by') + ' ' + user_validate.smartphones[i].email + ' @ ' + user_validate.smartphones[i].event_name;
            }
        }
    }

    return errors;

}

const smartphone_form = (state) => {

    let myChoices = state.smartphoneChoices;

    return (
        <AutocompleteArrayInput fullWidth label={translate('custom.smartphone_number')} source="smartphones" choices={myChoices} variant='outlined'
            defaultHighlightedIndex={0}
            onCreate={(text) => {
                if(text === "") {
                    text = prompt(translate('custom.enter_a_new_one_to_create'));
                    if(text === null) {
                        return null;
                    }

                    if (text.includes('\n')) {
                        text = text.split('\n');
                        let newTags = [];
                        for (let i=0; i<text.length; i++) {
                            text[i] = text[i].trim();
                            if (text[i] === "")
                                continue;
                            const newTag = { id: String(text[i]), name: text[i] };
                            if (myChoices.find((choice) => choice.id === newTag.id)) {
                                newTags.push(myChoices.find((choice) => choice.id === newTag.id));
                            } else {
                                myChoices.push(newTag);
                                newTags.push(newTag);
                            }
                        }
                        return newTags[0];
                    } else {
                        const newTag = { id: String(text), name: text };
                        if (myChoices.find((choice) => choice.id === newTag.id)) {
                            return myChoices.find((choice) => choice.id === newTag.id);
                        } else {
                            myChoices.push(newTag);
                            return newTag;
                        }
                    }
                } else {
                    const newTag = { id: String(text), name: text };
                    if (myChoices.find((choice) => choice.id === newTag.id)) {
                        return myChoices.find((choice) => choice.id === newTag.id);
                    } else {
                        myChoices.push(newTag);
                        return newTag;
                    }
                }
            }}
            // suggestionLimit={25}
        />
    );
}

export class UserEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            right: getRight(),
            smartphoneChoices: [],
        }
    }

    async componentDidMount() {

        try {

            // -------- Smartphones --------
            let user = await request(this.props.location.pathname, 'GET', null);

            let smartphone_devices_array = [];
            let smartphone_devices = await getDeviceList({
                minimal: true,
                type: config.device_types.SMARTPHONE.key
            })
            if (smartphone_devices.error) {
                console.log(smartphone_devices.error);
            } else {
                smartphone_devices_array = smartphone_devices.devices.map((device) => {
                    return String(device.number);
                });
            }

            // smartphone_choices set
            let smartphone_choices = Array.from(new Set([...user.smartphones, ...smartphone_devices_array]));
            
            let smts = smartphone_choices.map((smartphone) => {
                return {
                    id: String(smartphone),
                    name: String(smartphone)
                }
            });
            
            this.setState({
                smartphoneChoices: smts
            });

        } catch(e) {
            console.log("Error", String(e));
        }

    }

    render() {
        return (
            <Edit title={<PostTitle />} {...this.props}>
                <SimpleForm redirect="edit" variant="standard" validate={validateUserForm}
                    toolbar={<SaveToolbar right={this.state.right} />}
                >
                    {form(this.state.right, true)}
    
                    <CopySecureLoginLink />

                    {(this.state.right >= config.permissions.ADMIN.value) ? (
                        <>
                            <div style={{width: '100%'}}>
                                <h3 style={headerTextStyles}>{translate('custom.smartphones')}</h3>
                            </div>
                            <Grid container style={{width: '100%'}} spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {smartphone_form(this.state)}
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                </SimpleForm>
            </Edit>
        );
    }
};

export class UserCreate extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            right: getRight(),
            smartphoneChoices: [],
        }
    }

    async componentDidMount() {

        try {

            // -------- Smartphones --------
            let smartphone_devices_array = [];
            let smartphone_devices = await getDeviceList({
                minimal: true,
                type: config.device_types.SMARTPHONE.key
            })
            if (smartphone_devices.error) {
                console.log(smartphone_devices.error);
            } else {
                smartphone_devices_array = smartphone_devices.devices.map((device) => {
                    return String(device.number);
                });
            }

            // smartphone_choices set
            let smartphone_choices = Array.from(new Set([...smartphone_devices_array]));
            
            let smts = smartphone_choices.map((smartphone) => {
                return {
                    id: String(smartphone),
                    name: String(smartphone)
                }
            });
            
            this.setState({
                smartphoneChoices: smts
            });

        } catch(e) {
            console.log("Error", String(e));
        }

    }
    
    render() {
        return (
            <Create title={translate('custom.create_user')} {...this.props}>
                <SimpleForm variant="standard" validate={validateUserForm}
                    toolbar={<SaveAndAddNewToolbar right={this.state.right} />}
                >
                    {form(this.state.right, false)}

                    {(this.state.right >= config.permissions.ADMIN.value) ? (
                        <>
                            <div style={{width: '100%'}}>
                                <h3 style={headerTextStyles}>{translate('custom.smartphones')}</h3>
                            </div>
                            <Grid container style={{width: '100%'}} spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {smartphone_form(this.state)}
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                </SimpleForm>
            </Create>
        );
    }
}