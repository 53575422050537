import React, { useState } from 'react';
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    PasswordInput,
    required,
    SimpleList,
    Filter,
    Pagination,
    AutocompleteArrayInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    Button,
    useNotify,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    ReferenceFieldController,
    useRefresh
} from 'react-admin';
import PhonelinkLock from '@mui/icons-material/PhonelinkLock';
import Actions from '../components/reactAdmin/Actions';
import { Grid, useMediaQuery } from '@material-ui/core';
import { translate } from '../i18n/customI18nProvider';
import { request } from '../util';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@mui/icons-material/Save';
import { useFormState } from 'react-final-form';
import config from '../config';
import MaterialButton from '@material-ui/core/Button';
import { convertMessageCodeToMessage } from '../util';
import { getRight } from '../services/storage';

const SmartphonesList = ({ record }) => {
    let smartphones = record?.smartphones ? record.smartphones : [];
    return (
        smartphones.map((smartphone) => (
            <Chip label={smartphone} style={{margin: 1}} />
        ))
    )
}

export const AutoLoginIcon = PhonelinkLock;

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const AutoLoginFilter = (props) => {

    let permission_keys = Object.keys(config.permissions);

    let roles = permission_keys.map((role) => ({ id: config.permissions[role].value, name: config.permissions[role].name }));

    return (
        <Filter {...props}>
            <ReferenceInput variant='outlined' label={translate('custom.event')} reference="events" source="event_id" filterToQuery={searchText => ({ name: searchText })} alwaysOn >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            {/* Filter by role */}
            <AutocompleteInput variant='outlined' label={translate('custom.role')} source="right" choices={roles} alwaysOn />
            <TextInput variant='outlined' label={translate('custom.smartphone_number')} source="smartphones" alwaysOn />
            <QuickFilter source="archived" label={translate('custom.archived')} defaultValue={true} />
        </Filter>
    )
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CustomLink = ({ record }) => {
    if (record?.token) {
        let link = `${window.location.origin}/login?token=${record.token}`
        return (
            <a href={link} style={{padding: 10, border: '1px solid gray', borderRadius: 5, margin: 10, marginTop: 20, textDecoration: 'none', color: config.baseColor, borderColor: config.baseColor}}>
                {translate('custom.login')}
            </a>
        );
    }
    return null;
}

export const AutoLoginList = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const right = getRight();
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }
    return (
        <>

            <List
                {...props}
                actions={<Actions />}
                filters={<AutoLoginFilter />}
                pagination={<PostPagination />}
                perPage={50}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={
                            (right < config.permissions.SUPERADMIN.value) ? (
                                <ReferenceField label={translate('custom.user')} source="user_id" reference="users" sortable={false} >
                                    <TextField source="email" />
                                </ReferenceField>
                            ) : (
                                <ReferenceField label={translate('custom.user')} source="user_id" reference="users" sortable={false} link={null} >
                                    <TextField source="email" />
                                </ReferenceField>
                            )
                        }
                    />
                ) : (
                    <Datagrid hasBulkActions={false}>
                        <ReferenceFieldController label={translate('custom.event')} source="user_id" reference="users" sortable={false} >
                            {({referenceRecord, ...props}) => (
                                <ReferenceField basePath="/events" resource="events" reference="events" source="event_id" record={referenceRecord || {}} link="show">
                                    <TextField source="name" />
                                </ReferenceField>
                            )}
                        </ReferenceFieldController>
                        {(right < config.permissions.SUPERADMIN.value) ? (
                            <ReferenceField label={translate('custom.user')} source="user_id" reference="users" sortable={false} >
                                <TextField source="email" />
                            </ReferenceField>
                        ) : (
                            <ReferenceField label={translate('custom.user')} source="user_id" reference="users" sortable={false} link={null} >
                                <TextField source="email" />
                            </ReferenceField>
                        )}
                        <SmartphonesList label={translate('custom.smartphones')} />
                        {/* <EditButton /> */}
                        {/* <ReferenceField label={translate('custom.login')} source="user_id" reference="users" sortable={false}>
                            <CustomLink />
                        </ReferenceField> */}
                    </Datagrid>
                )}
            </List>

        </>
    );
};

// const PostTitle = (props) => {
//     let record = props.record;
//     let users = props.users || [];
//     let user = users.find(user => user.id === record.user_id);
//     return <span>{translate('custom.auto_login') + (user ? ' \'' + user.name + '\'' : '')}</span>;
// };

// const validateAutoLoginForm = async (values) => {

//     let errors = {};


//     let payload = {
//         "smartphones" : values['smartphones'],
//         "user_id" : values['user_id']
//     }
//     if ("_id" in values) {
//         payload["_id"] = values['_id'];
//     }

//     let autologins_validate = await request('/auto_logins/validate', 'POST', null, payload);

//     if (autologins_validate?.user_id) {
//         errors['user_id'] = convertMessageCodeToMessage(autologins_validate?.user_id)
//     }
    
//     if (autologins_validate?.smartphones) {
//         if (autologins_validate.smartphones?.length > 0) {
//             errors['smartphones'] = '';
//             for (let i=0; i<autologins_validate.smartphones.length; i++) {
//                 if (errors['smartphones'] != '') {
//                     errors['smartphones'] += ' & ';
//                 }
//                 errors['smartphones'] += translate('custom.smartphone') + ' - ' + autologins_validate.smartphones[i].smartphone_number + ' ' + translate('custom.is_already_taken_by') + ' ' + autologins_validate.smartphones[i].email + ' @ ' + autologins_validate.smartphones[i].event_name;
//             }
//         }
//     }

//     return errors;

// }

// const smartphone_form = (state) => {

//     let myChoices = state.smartphoneChoices;

//     return (
//         <AutocompleteArrayInput fullWidth label={translate('custom.smartphone_number')} source="smartphones" choices={myChoices}
//             defaultHighlightedIndex={0}
//             onCreate={(text) => {
//                 if(text === "")
//                     text = prompt(translate('custom.enter_a_new_one_to_create'));
//                 const newTag = { id: Number(text), name: text };
//                 myChoices.push(newTag);
//                 return newTag;
//             }}
//             suggestionLimit={5}
//         />
//     );
// }

// const useToolbarStyles = makeStyles({
//     toolbar: {
//         display: 'flex',
//         justifyContent: 'space-between',
//     },
// });

// const SaveAndForceSaveToolbar = (props) => {
    
//     const { values } = useFormState();
//     const notify = useNotify();
//     const refresh = useRefresh();

//     return (
//         <Toolbar classes={useToolbarStyles()} >
//             <SaveButton
//                 {...props}
//                 label="ra.action.save"
//                 redirect="edit"
//                 submitOnEnter={true}
//                 style={{marginRight: 10}}
//             />
//             <Button
//                 label={translate('custom.force_save')}
//                 variant="contained"
//                 size="medium"
//                 startIcon={<SaveIcon />}
//                 onClick={async () => {
//                     const record = props.record;
//                     let autologins = await request('/auto_logins/'+String(record._id)+'/force', 'PUT', null, values);
//                     if(autologins.error) {
//                         notify('custom.force_update_failed' + ' ' + autologins.error, {type: 'error'});
//                     } else {
//                         window.location.reload();
//                         notify('custom.force_update_successful', { type: 'info' });
//                     }
//                 }}
//             />
            
//             <DeleteButton
//                 {...props}
//                 redirect="list"
//             />
//         </Toolbar>
//     )
    
    
// }

// const CopySecureLoginLink = (props) => {
//     const record = props?.record;
//     const [copied, setCopied] = useState(false);
//     if (record?.user?.token) {
//         return (
//             <MaterialButton
//                 variant="contained"
//                 color="primary"
//                 onClick={() => {
//                     const link = `${window.location.origin}/login?token=${record.user.token}`
//                     navigator.clipboard.writeText(link)
//                     setCopied(true)
//                     setTimeout(() => {
//                         setCopied(false)
//                     }, 2000);
//                 }}
//                 disabled={copied}
//             >
//                 {copied ? translate('custom.copied') : translate('custom.copy_secure_login_link')}
//             </MaterialButton>
//         );
//     }
//     return null;
// }

// export class AutoLoginEdit extends React.Component {

//     constructor() {
//         super();

//         this.state = {
//             smartphoneChoices: [],
//             events: [],
//             users: []
//         };
//     }

//     async componentDidMount() {

//         try {

//             // -------- Smartphones --------
//             let autologin = await request(this.props.location.pathname, 'GET', null);

//             let smartphone_choices = autologin.smartphones;
//             let smts = [];
//             for(let i = 0; i < smartphone_choices.length; i++) {
//                 smts.push({
//                     id: smartphone_choices[i],
//                     name: String(smartphone_choices[i])
//                 });
//             }

//             this.setState({
//                 smartphoneChoices: smts
//             });



//             // -------- Users --------
//             let event_map = {};

//             let usr = [];
//             let users = await request('/users/all', 'GET', null)
//             let events = await request('/events/all', 'GET', null)
//             if (users.error) {
//                 alert(users.error);
//                 return;
//             }
//             if (events.error) {
//                 alert(events.error);
//                 return;
//             }

//             for (let i = 0; i < events.length; i++) {
//                 event_map[events[i].id] = events[i].name;
//             }

//             // filter users, not to see superadmin
//             users = users?.filter((user) => {
//                 return user.right < config.permissions.SUPERADMIN.value;
//             });

//             if (!users) {
//                 return;
//             }

//             for(let i=0; i<users.length; i++) {
//                 usr.push({
//                     id: users[i].id,
//                     name: event_map[users[i].event_id] + ' - ' + users[i].email
//                 });
//             }

//             if(usr.length)
//                 usr.sort((a, b) => a.name > b.name);
//             this.setState({
//                 users: usr
//             });



//         } catch(e) {
//             console.log("Error", String(e));
//         }

//     }

//     render() {
//         return (
//             <Edit undoable={false} title={<PostTitle users={this.state.users} />} {...this.props}>
//                 <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={validateAutoLoginForm}
//                     toolbar={<SaveAndForceSaveToolbar />}
//                 >
    
//                     <div>
//                         <h3>{translate('custom.credentials')}</h3>
//                     </div>
//                     {autologin_credentials_form(this.state.users)}

//                     <CopySecureLoginLink />
    
//                     <div>
//                         <h3>{translate('custom.smartphones')}</h3>
//                     </div>
//                     {smartphone_form(this.state)}
    
//                 </SimpleForm>
//             </Edit>
//         )
//     }

// }


// const SaveAndAddNewToolbar = (props) => {

//     const { values } = useFormState();
//     const notify = useNotify();
//     const refresh = useRefresh();

//     return (
//         <Toolbar classes={useToolbarStyles()}>
//             <SaveButton
//                 {...props}
//                 label="ra.action.save"
//                 redirect="edit"
//                 submitOnEnter={false}
//                 style={{marginRight: 10}}
//             />
//             <Button
//                 label={translate('custom.force_save')}
//                 variant="contained"
//                 size="medium"
//                 submitOnEnter={false}
//                 startIcon={<SaveIcon />}
//                 style={{marginRight: 10}}
//                 onClick={async () => {
//                     let autologins = await request('/auto_logins/force', 'POST', null, values);
//                     if(autologins.error) {
//                         notify('custom.force_create_failed' + ' ' + autologins.error_code ? convertMessageCodeToMessage(autologins.error_code) : autologins.error, {type: 'error'});
//                     } else {
//                         window.location.reload();
//                         notify('custom.force_create_successful', { type: 'info' });
//                     }
//                 }}
//             />
//             <SaveButton
//                 {...props}
//                 label={translate('custom.save_and_add')}
//                 redirect={() => {
//                     window.location.reload();
//                 }}
//                 submitOnEnter={true}
//             />
//         </Toolbar>
//     )
// }

// const autologin_credentials_form = (users) => {
//     return (
//         <Grid container spacing={2} style={{width: '100%'}}>
//             <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
//                 {/* <AutocompleteArrayInput fullWidth label={translate('custom.user')} source="users" choices={users} validate={required()} variant="outlined" /> */}
//                 <AutocompleteInput fullWidth label={translate('custom.user')} source="user_id" choices={users} validate={required()} variant="outlined" />
//             </Grid>
//         </Grid>
//     )
// }

// export class AutoLoginCreate extends React.Component {

//     constructor(props) {
//         super(props);

//         this.state = {
//             users: [],
//             events: []
//         }
//     }

//     async componentDidMount() {

//         let event_map = {};

//         let usr = [];
//         let users = await request('/users/all', 'GET', null)
//         let events = await request('/events/all', 'GET', null)
//         if (users.error) {
//             alert(users.error);
//             return;
//         }
//         if (events.error) {
//             alert(events.error);
//             return;
//         }

//         for (let i = 0; i < events.length; i++) {
//             event_map[events[i].id] = events[i].name;
//         }

//         // filter users, not to see superadmin
//         users = users?.filter((user) => {
//             return user.right < config.permissions.SUPERADMIN.value;
//         });

//         if (!users) {
//             return;
//         }

//         for(let i=0; i<users.length; i++) {
//             usr.push({
//                 id: users[i].id,
//                 name: event_map[users[i].event_id] + ' - ' + users[i].email
//             });
//         }

//         if(usr.length)
//             usr.sort((a, b) => a.name > b.name);
//         this.setState({
//             users: usr
//         });
//     }

//     render() {
//         return (
//             <Create title={translate('custom.create_auto_login')} {...this.props}>
//                 <SimpleForm variant="standard" warnWhenUnsavedChanges validate={validateAutoLoginForm}
//                     toolbar={<SaveAndAddNewToolbar />}
//                 >

//                     <div>
//                         <h3>{translate('custom.credentials')}</h3>
//                     </div>
//                     {autologin_credentials_form(this.state.users)}

//                     <div>
//                         <h3>{translate('custom.smartphones')}</h3>
//                     </div>
//                     {smartphone_form({smartphoneChoices: []})}

//                 </SimpleForm>
//             </Create>
//         )
//     }

// }
