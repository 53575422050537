import react from 'react';
import V2Layout from './Layout';
import { Box, Button, Grid, LinearProgress, Paper, TextField, OutlinedInput, Stack } from '@mui/material';
import { translate } from '../../i18n/customI18nProvider';
import { createMyposTerminal, getMyposTerminalDetails, getMyposTerminalStatus, pairMyposTerminal, saveMyposTerminalDetails, unpairMyposTerminal } from '../../services/mypos_terminal.service';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import CircleIcon from '@mui/icons-material/Circle';

class AddEditMyposTerminal extends react.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getInitialState() {
        return {
            mode: null,
            mypos_terminal_id: null,
            title: translate('custom.mypos_terminals'),
            loading: false,
            is_form_valid: true,
            is_form_pristine: true,
            form_error: {},
            // ------
            terminal_code: '', // string
            terminal_id: '', // string,
            is_paired: false, // boolean ( read only )
        }
    }

    async resetState() {
        await this.setStateAsync(this.getInitialState());
    }

    async componentDidMount() {
        // get the mode, if /add, then create new
        // if /:mypos_terminal_id, then fetch details
        let mode = this.props?.match?.params?.mypos_terminal_id ? 'edit' : 'add';
        let mypos_terminal_id = this.props?.match?.params?.mypos_terminal_id ? this.props.match.params.mypos_terminal_id : null;
        await this.setState({
            loading: true,
            mode,
            mypos_terminal_id,
            title: mode === 'add' ? translate('custom.add_mypos_terminal') : translate('custom.edit_mypos_terminal')
        });

        if (mode === 'edit')
            await this.getMyposTerminal();
        
        this.setState({ loading: false });
    }   

    async getMyposTerminal() {
        // if mypos_terminal_id, fetch details
        let mypos_terminal = await getMyposTerminalDetails(this.state.mypos_terminal_id);
        if (mypos_terminal.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: mypos_terminal.error_code ? convertMessageCodeToMessage(mypos_terminal.error_code) : mypos_terminal.error
            });
            return;
        }
        mypos_terminal = mypos_terminal.mypos_terminal;

        await this.setState({
            terminal_code: mypos_terminal.terminal_code ? mypos_terminal.terminal_code : '',
            terminal_id: mypos_terminal.terminal_id ? mypos_terminal.terminal_id : '',
            is_paired: mypos_terminal.is_paired ? mypos_terminal.is_paired : false
        });
    }

    async save() {

        if (!this.isFormValid()) return;

        let payload = {
            terminal_code: this.state.terminal_code,
            terminal_id: this.state.terminal_id
        }

        let response;
        if (this.state.mode === 'add') {
            response = await createMyposTerminal(payload);
        } else if (this.state.mode === 'edit') {
            response = await saveMyposTerminalDetails(this.state.mypos_terminal_id, payload);
        }

        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error
            });
            return;
        }

        if (this.state.mode === 'add') {
            this.props.history.replace(`/v2/admin/mypos_terminals/${response.mypos_terminal._id}`);
        }

        await this.resetState();
        this.componentDidMount();

        Swal.fire({
            icon: 'success',
            title: translate('custom.success'),
            text: translate('custom.saved_successfully'),
            showConfirmButton: false,
            timer: 1500
        });


    }

    isFormValid(key=null) {

        let form_error = this.state.form_error;

        if (!key || key == 'terminal_id') {
            if (this.state.terminal_id === '' || !this.state.terminal_id) {
                form_error['terminal_id'] = translate('custom.required');
            } else {
                delete form_error['terminal_id'];
            }
        }
        
        if (!key || key == 'terminal_code') {
            if (this.state.terminal_code === '' || !this.state.terminal_code) {
                form_error['terminal_code'] = translate('custom.required');
            } else {
                delete form_error['terminal_code'];
            }
        }



        if (Object.keys(form_error).length > 0) {
            this.setState({ form_error, is_form_valid: false });
            return false;
        } else {
            this.setState({ form_error: {}, is_form_valid: true });
            return true;
        }

    }

    async getPairStatus() {
        const pairStatus = await getMyposTerminalStatus(this.state.mypos_terminal_id);
        if (pairStatus.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: pairStatus.error_code ? convertMessageCodeToMessage(pairStatus.error_code) : pairStatus.error
            });
            return;
        }
        await this.setState({
            is_paired: pairStatus.is_paired
        });
        return pairStatus;
    }

    async pair() {
        let response = await Swal.fire({
            title: translate('custom.are_you_sure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: translate('custom.pair'),
            confirmButtonColor: 'green',
            cancelButtonText: translate('custom.cancel')
        });

        if (response.isConfirmed) {
            const pairRes = await pairMyposTerminal(this.state.mypos_terminal_id);
            console.log(pairRes);
            if (pairRes.error) {
                Swal.fire({
                    icon: 'error',
                    title: translate('custom.error'),
                    text: pairRes.error_code ? convertMessageCodeToMessage(pairRes.error_code) : pairRes.error
                });
                return;
            }



            let interval;
    
            Swal.fire({
                title: translate("custom.terminal_pairing_in_progress"),
                html: pairRes?.code ? (`${translate("custom.TEXT.pair_unpair_sentence")}<br /><br /><b>${pairRes?.code}</b>`) : null,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: translate("custom.cancel"),
                didOpen: () => {
                    Swal.showLoading();
                }
            }).then((result) => {
                if (result.isDismissed) {
                    if (interval)
                    clearInterval(interval);
                }
            });

            interval = setInterval(async () => {
                let terminalStatus = await this.getPairStatus();
                if (terminalStatus?.is_paired) {
                    Swal.fire({
                    icon: "success",
                    title: translate("custom.terminal_paired_successfully"),
                    confirmButtonText: translate("custom.ok"),
                    });
                    clearInterval(interval);
                    return;
                }
            }, 5000);

        }
    }

    async unpair() {
        let response = await Swal.fire({
            title: translate('custom.are_you_sure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: translate('custom.unpair'),
            confirmButtonColor: 'red',
            cancelButtonText: translate('custom.cancel')
        });

        if (response.isConfirmed) {
            const unpairRes = await unpairMyposTerminal(this.state.mypos_terminal_id);
            console.log(unpairRes);
            if (unpairRes.error) {
                Swal.fire({
                    icon: 'error',
                    title: translate('custom.error'),
                    text: unpairRes.error_code ? convertMessageCodeToMessage(unpairRes.error_code) : unpairRes.error
                });
                return;
            }



            let interval;

            Swal.fire({
                title: translate("custom.terminal_unpairing_in_progress"),
                html: unpairRes?.code ? (`${translate("custom.TEXT.pair_unpair_sentence")}<br /><br /><b>${unpairRes?.code}</b>`) : null,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: translate("custom.cancel"),
                didOpen: () => {
                    Swal.showLoading();
                },
            }).then((result) => {
                if (result.isDismissed) {
                    if (interval)
                    clearInterval(interval);
                }
            });

            interval = setInterval(async () => {
                let terminalStatus = await this.getPairStatus();
                if (!terminalStatus?.is_paired) {
                    Swal.fire({
                    icon: "success",
                    title: translate("custom.terminal_unpaired_successfully"),
                    confirmButtonText: translate("custom.ok"),
                    });
                    clearInterval(interval);
                    return;
                }
            }, 5000);

        }
    }

    render() {
        return (
            <V2Layout
                currentMenu='mypos_terminals'
                title={this.state.title}
            >

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Paper sx={{mt: 2, padding: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <TextField fullWidth label={translate('custom.terminal_code')} variant='outlined'
                                value={this.state.terminal_code}
                                onChange={(e) => this.setState({ terminal_code: e.target.value, is_form_pristine: false })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <OutlinedInput
                                fullWidth
                                placeholder={translate('custom.terminal_id')}
                                value={this.state.terminal_id}
                                onChange={(e) => this.setState({ terminal_id: e.target.value, is_form_pristine: false })}
                                endAdornment={((this.state.mode == 'add') || !this.state.is_form_pristine) ? null : (
                                    (this.state.is_paired) ? (
                                        <CircleIcon style={{color: 'green'}} />
                                    ) : (
                                        <CircleIcon style={{color: 'red'}} />
                                    )
                                )}
                            />
                        </Grid>
                        {(this.state.mode == 'edit' && this.state.is_form_pristine) ? (
                            <Grid item xs={12} md={6} lg={4} xl={3} sx={{display: 'flex', alignItems: 'center'}}>
                                <Stack direction="row" spacing={1}>
                                    <Button variant='contained' color='primary'
                                        onClick={async () => {
                                            this.getPairStatus();
                                        }}
                                    >{translate('custom.refresh')}</Button>
                                    {(this.state.is_paired) ? (
                                        <Button variant='contained' color='error'
                                            onClick={async () => {
                                                this.unpair();
                                            }}
                                        >{translate('custom.unpair')}</Button>
                                    ) : (
                                        <Button variant='contained' color='success'
                                            onClick={async () => {
                                                this.pair();
                                            }}
                                        >{translate('custom.pair')}</Button>
                                    )}
                                </Stack>
                            </Grid>
                        ) : null}
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 2, justifyContent: 'space-around'}}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Button variant='contained' color='primary' fullWidth
                                onClick={async () => {
                                    await this.save();
                                }}
                            >{this.state.loading ? translate('custom.loading') : translate('custom.save')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </V2Layout>
        );
    }
}

export default AddEditMyposTerminal;
