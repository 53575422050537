import React, { Component } from 'react';
import '../css/App.css';

import AppBar from '@material-ui/core/AppBar';
// import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ActionMenu from '@mui/icons-material/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import Hidden from '@material-ui/core/Hidden';
import ActionLogout from '@mui/icons-material/PowerSettingsNew';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ActionBar from '@mui/icons-material/LocalBar'; // commented by MJ
import ActionHome from '@mui/icons-material/Home';
import ActionEdit from '@mui/icons-material/Edit';
import TicketsIcon from '@mui/icons-material/LocalActivity';
import { MuiThemeProvider, withStyles, withTheme } from '@material-ui/core/styles';
import logo from '../images/LogoBlanc.png';
// import { MenuItemLink, getResources } from 'react-admin';  // commented by MJ
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import theme from './Theme';
import BarListSidebarView from "./BarListSidebarView";
import { request } from '../util';

import { translate } from "../i18n/customI18nProvider";
import RefreshIcon from '@mui/icons-material/Refresh';
import config from '../config';
import { clear, getRight, getToken, removePeriodID, setPeriodID } from '../services/storage';

class Header extends Component {

    constructor(props){
        super();
        this.state = {
            mobileOpen: false,
            periodId: ''
        }
        removePeriodID();
    }

    async componentDidMount() {
        const token = getToken();
        const right = getRight();
        if (token && (right < config.permissions.SUPERADMIN.value) && (right >= config.permissions.ADMIN.value) && !this.props.noSide) {
            let infomaniak_info = await request('/events/parameter/infomaniak', 'GET', null);
            if (infomaniak_info.error) {
                alert(infomaniak_info.error);
                return;
            }
            let infomaniak = infomaniak_info.infomaniak;
            this.setState({
                periodId: infomaniak?.PERIOD_ID
            });
            if (infomaniak?.PERIOD_ID)
                setPeriodID(infomaniak.PERIOD_ID);
        }
    }



    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    logout = () => {
        Swal.fire({
            title: 'Are you sure you want to logout?',
            showCancelButton: true,
            confirmButtonText: 'Logout',
            confirmButtonColor: config.baseColor
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                clear();
                window.location.replace('/login');
            }
        })
    };

    refreshClicked() {
        this.props.refreshClicked();
    }

    render(){

        let eventName = this.props.name || '';

        // name = this.props.name ? this.props.name : name;
        let items = this.props.items ? this.props.items : [];

        // Removed by MJ
        // let itemsView = items.map((item) =>
        //     item ?
        //         <MenuItemLink
        //             button
        //             key={item.name}
        //             to={item.link}
        //             leftIcon={<ActionBar />}
        //             primaryText={item.name}
        //             sidebarIsOpen={this.state.mobileOpen}
        //         >
        //         </MenuItemLink> : null
        // );

        let admin =
            <ListItem button
                      component={Link}
                      to='/admin'
            >
                <ListItemIcon>
                    <ActionEdit />
                </ListItemIcon>
                <ListItemText primary={translate('custom.modify')} />
            </ListItem>;

        let home =
            <ListItem button
                      component={Link}
                      to={this.props.home}
            >
                <ListItemIcon>
                    <ActionHome />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>;

        let tickets = 
            <ListItem button
                        component="a"
                        href={`https://manager.infomaniak.com/v3/${this.state.periodId}/tickets/all`}
                        target="_blank"
            >
                <ListItemIcon>
                    <TicketsIcon />
                </ListItemIcon>
                <ListItemText primary={translate('custom.tickets')} />
            </ListItem>;

        let listLogout = (this.props.logout !== false) ?
            <ListItem button  variant="disabled"
                      onClick={this.logout}
            >
                <ListItemIcon >
                    <ActionLogout />
                </ListItemIcon>
                <ListItemText primary={translate('custom.logout')} />
            </ListItem>
            : null;

        let list = (
            <List style={{width: 220}}>
                <ListItem>
                    <ListItemText
                        primary="JM Contactless"
                        secondary={eventName}
                    />
                </ListItem>
                <Divider />
                {home}
                { (this.state.periodId && this.state.periodId != "undefined") ? tickets : null}
                {admin}
                <Divider />

                {/* {itemsView} */}
                {/*Added by MJ*/}
                <BarListSidebarView items={items}></BarListSidebarView>

                <Divider />
                {listLogout}
                <Divider />
            </List>
        );
        return (
            <MuiThemeProvider theme={theme}>
                <div className={this.props.noSide ? null : this.props.classes.container}>
                    <div  className="noPrint">
                        <AppBar position="fixed">
                            <div className={this.props.noSide ? this.props.classes.headerNoSide : this.props.classes.header} >
                                <div style={{display: "flex", alignItems: "center"}}>
                                    { this.props.noSide ? null :
                                        <Hidden mdUp>
                                            <div style={{width: 40}}><IconButton onClick={this.handleDrawerToggle}><ActionMenu style={{color: "white"}} /></IconButton></div>
                                        </Hidden>
                                    }
                                    <p style={{fontSize: 20, marginLeft: 30, color: "white"}}>{this.props.title}</p>
                                </div>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    {this.props.refreshClicked && <RefreshIcon 
                                        style={{
                                            cursor: "pointer",
                                            color: "white"
                                        }}
                                        onClick={(() => {
                                            this.refreshClicked();
                                        })}
                                    />}
                                    <img alt="logo" style={{height: "40px", marginLeft: "20px", marginRight: "20px"}} src={logo} />
                                </div>
                            </div>
                            {this.props.loading ? <LinearProgress  color="secondary" /> : null}
                        </AppBar>
                    </div>
                    <div className="print" style={{
                        backgroundColor: "white",
                        position: "fixed",
                        width: "100%",
                        zIndex: 1000
                    }}>
                        <div style={{
                            display: "flex",
                            height: 50,
                            flexAlign: "center",
                            justifyContent: "space-between"
                        }}>
                            <div>
                                {this.props.title}
                            </div>
                            <div>
                                {(new Date()).toLocaleDateString().replace(/\//g,'.')}
                            </div>
                        </div>
                    </div>
                    <div className="print" style={{
                        backgroundColor: "white",
                        position: "fixed",
                        width: "100%",
                        bottom: 0,
                        zIndex: 1000
                    }}>
                        <div style={{
                            display: "flex",
                            flexAlign: "center",
                            justifyContent: "space-between"
                        }}>
                            <div>
                                JM Contactless Sàrl
                            </div>
                            <div>
                                {eventName}
                            </div>
                        </div>
                    </div>
                    { this.props.noSide ? null :
                        <div>
                            <Hidden mdUp>
                                <SwipeableDrawer
                                    variant="temporary"
                                    onOpen={()=>this.setState({mobileOpen: true})}
                                    onClose={()=>this.setState({mobileOpen: false})}
                                    onClick={this.handleDrawerToggle}
                                    open={this.state.mobileOpen}
                                >
                                    {list}
                                </SwipeableDrawer>
                            </Hidden>
                            <Hidden smDown implementation="css">
                                <Drawer
                                    variant="permanent"
                                >
                                    {list}
                                </Drawer>
                            </Hidden>
                        </div>
                    }
                    <div className="container-center">
                        <div className={this.props.classes.center} id="container-center" >
                            <div className="print" style={{height: 50}} />
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

const styles = theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up('md')]: {
            marginLeft: 220
        }
    },
    headerNoSide: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    center: {
        marginTop: 64,
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        maxWidth: "1000px",
        backgroundColor: "#fff",
        [theme.breakpoints.up('md')]: {
            padding: 30
        }
    },
    container: {
        [theme.breakpoints.up('md')]: {
            marginLeft: 220
        },
    }
});
export default withTheme(withStyles(styles)(Header));
