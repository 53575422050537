import React from 'react';
import {
    NumberInput,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    SimpleList,
    BooleanInput,
    DateTimeInput,
    Pagination,
    useRedirect,
    Toolbar,
    SaveButton,
    SelectInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ImageInput,
    ImageField,
    Create,
    useRefresh,
    Filter,
    useRecordContext,
    DateField,
    TopToolbar,
} from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Chip } from '@material-ui/core';
import {translate} from "../i18n/customI18nProvider";
import Actions from '../components/reactAdmin/Actions';
import RichTextInput from 'ra-input-rich-text';
import { convertMessageCodeToMessage, downloadFileFromResponse, getChoices, getRequestOptions, request } from '../util';
import Grid from '@material-ui/core/Grid';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';

import { validateRequired, validatePositiveNumberNonRequired } from '../validators/number_validators';
import { validateDate } from '../validators/date_validators';
import config from '../config';
import DBRestore from '../components/event/DBRestore.event';
import ArchiveIcon from '@mui/icons-material/Archive';
import { getRight, setLocale, setName, setPeriodID } from '../services/storage';
import AssetUpload from '../components/common/assetUpload';
import { useForm } from 'react-final-form';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tab, Tabs } from '@mui/material';
import AddEditEventSmartphones from '../screens/v2/AddEditEventSmartphones';
import AddEditEventMyposTerminals from '../screens/v2/AddEditEventMyposTerminals';

export const EventIcon = SettingsIcon;

const PostPagination = props => {
    const right = getRight();
    if ((right < config.permissions.SUPERADMIN.value) && props.ids.length == 1) {
        // redirect to edit first event
        const redirect = useRedirect();
        redirect('/events/' + props.ids[0]);
    }
    return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
};

const ArchivedField = ({ record }) => {
    if (record?.archived) {
        return (
            <Chip style={{margin: 8, marginLeft: 0}} label={translate('custom.archived')} />
        )
    } else {
        return null;
    }
};

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(record?.deleted) {
        return (
            <Box display={"flex"} style={{justifyContent: 'space-around'}}>
                <Button
                    color="primary"
                    startIcon={<RestoreIcon />}
                    onClick={async () => {
                        try {
                            await request('/events/' + record._id + '/restore', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{margin: 2}}
                >
                    {translate('custom.restore')}
                </Button>
                
                <Button
                    variant="outlined"
                    startIcon={<DeleteForeverIcon />}
                    onClick={async () => {

                        // get the event name from swal
                        const event_name = await Swal.fire({
                            icon: 'warning',
                            title: translate('custom.warning'),
                            text: translate('custom.are_you_sure_you_want_delete_forever'),
                            input: 'text',
                            inputLabel: translate('custom.enter_event_name') + ' : ' + record.name,
                            inputPlaceholder: translate('custom.event_name'),
                            showCancelButton: true,
                            confirmButtonText: translate('custom.delete_forever'),
                            cancelButtonText: translate('custom.cancel'),
                            confirmButtonColor: config.dangerColor,
                            inputValidator: (value) => {
                                if (!value) {
                                    return translate('custom.please_enter_event_name');
                                }
                                if (value != record.name) {
                                    return translate('custom.event_name_doesnt_match');
                                }
                            }
                        })

                        if (!event_name.isConfirmed) {
                            return;
                        }

                        let payload = {
                            name: event_name.value
                        }

                        try {
                            // show loading swal
                            Swal.fire({
                                title: translate('custom.deleting'),
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                didOpen: () => {
                                    Swal.showLoading();
                                }
                            });
                            await request('/events/' + record._id + '/force', 'DELETE', null, payload);
                            refresh();
                            // close loading swal and show success
                            Swal.fire({
                                icon: 'success',
                                title: translate('custom.success'),
                                text: translate('custom.event_deleted_forever'),
                                confirmButtonText: translate('custom.ok'),
                                confirmButtonColor: config.primaryColor
                            });
                        } catch(e) {
                            console.log(e.message)
                            // close loading swal and show error
                            Swal.fire({
                                icon: 'error',
                                title: translate('custom.error'),
                                text: translate('custom.something_went_wrong'),
                                confirmButtonText: translate('custom.ok'),
                                confirmButtonColor: config.primaryColor
                            });
                        }
                    }}
                    style={{margin: 2, color: config.dangerColor, borderColor: config.dangerColor}}
                >
                    {translate('custom.delete_forever')}
                </Button>
            </Box>
        )
    } else {

        const right = getRight();

        let loginLink = null;
        if (record?.token) {
            loginLink = `${window.location.origin}/login?token=${record.token}`
        }

        let goToUsersLink = null;
        if (record?._id) {
            goToUsersLink = `/v2/admin/users?event={"id":"${record._id}","label":"${record.name}"}`
            goToUsersLink = encodeURI(goToUsersLink);
        }

        return (
            <Box display={"flex"} style={{justifyContent: 'space-around'}}>
                <EditButton record={record} style={{margin: 2}} />
                {(loginLink) ? (
                    <a href={loginLink} target='_blank' style={{display: 'flex', alignItems: 'center', textAlign: 'center', padding: 8, border: '1px solid gray', borderRadius: 5, margin: 2, textDecoration: 'none', color: config.baseColor, borderColor: config.baseColor}}>
                        {translate('custom.login')}
                    </a>
                ) : null}

                {/* Go to users */}
                {(goToUsersLink) ? (
                    <a href={goToUsersLink} style={{display: 'flex', alignItems: 'center', textAlign: 'center', padding: 8, border: '1px solid gray', borderRadius: 5, margin: 2, textDecoration: 'none', color: config.baseColor, borderColor: config.baseColor}}>
                        {translate('custom.go_to_users')}
                    </a>
                ) : null}

                {/* archive button */}
                {(right >= config.permissions.SUPERADMIN.value && !record?.archived) ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ArchiveIcon />}
                        onClick={async () => {
                            try {

                                // Confirm archive
                                const confirm_archive = await Swal.fire({
                                    icon: 'warning',
                                    title: translate('custom.warning'),
                                    text: translate('custom.are_you_sure_you_want_to_archive') + ' ' + translate('custom.you_will_not_be_able_to_unarchive_once_it_is_archived'),
                                    showCancelButton: true,
                                    confirmButtonText: translate('custom.archive'),
                                    cancelButtonText: translate('custom.cancel'),
                                    confirmButtonColor: config.baseColor
                                });

                                if (!confirm_archive.isConfirmed) {
                                    return;
                                }

                                // Show loading swal
                                Swal.fire({
                                    title: translate('custom.archiving'),
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    didOpen: () => {
                                        Swal.showLoading();
                                    }
                                });
                                let archive_response = await fetch(config.apiUrl + '/events/' + record._id + '/archive', getRequestOptions('POST'));
                                let responseJson = await archive_response.json();
                                
                                if(responseJson.error) {
                                    // alert(responseJson.error);
                                    Swal.fire({
                                        title: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
                                        icon: 'error',
                                        confirmButtonColor: config.baseColor,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    });
                                    return;
                                } else {
                                    refresh();
                                    // alert(translate('custom.archived_success'));
                                    Swal.fire({
                                        title: translate('custom.successfully_archived'),
                                        icon: 'success',
                                        confirmButtonColor: config.baseColor,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    });
                                }
                                
                            } catch(e) {
                                console.log(e.message)
                                // Close swal
                                Swal.fire({
                                    title: translate('custom.error'),
                                    text: e.message,
                                    icon: 'error',
                                    confirmButtonColor: config.baseColor,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }}
                        style={{margin: 2}}
                    >
                        {translate('custom.archive')}
                    </Button>
                ) : null}

                {/* backup button */}
                {(right >= config.permissions.SUPERADMIN.value) ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<RestoreIcon />}
                        onClick={async () => {
                            try {
                                // Show loading swal
                                Swal.fire({
                                    title: translate('custom.backing_up'),
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    didOpen: () => {
                                        Swal.showLoading();
                                    }
                                });
                                let backup_response = await fetch(config.apiUrl + '/events/' + record._id + '/db_backup', getRequestOptions('POST'));
                                
                                if(backup_response.status !== 200) {
                                    let responseJson = await backup_response.json();
                                    // alert(responseJson.error);
                                    Swal.fire({
                                        title: responseJson.error,
                                        icon: 'error',
                                        confirmButtonColor: config.baseColor,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    });
                                    return;
                                }


                                try {

                                    await downloadFileFromResponse(backup_response, record.name + '.backup');
                                    Swal.fire({
                                        icon: 'success',
                                        title: translate('custom.success'),
                                        text: translate('custom.db_backup_success'),
                                        confirmButtonText: translate('custom.ok'),
                                        confirmButtonColor: config.baseColor
                                    });
                    
                                } catch(e) {
                                    console.log("Error", e.message);
                                    // alert(translate('custom.download_failed'));
                                    Swal.fire({
                                        title: translate('custom.download_failed'),
                                        text: e.message,
                                        icon: 'error',
                                        confirmButtonColor: config.baseColor,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    });
                                }
                                
                            } catch(e) {
                                console.log(e.message)
                                // Close swal
                                Swal.fire({
                                    title: translate('custom.error'),
                                    text: e.message,
                                    icon: 'error',
                                    confirmButtonColor: config.baseColor,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }}
                        style={{margin: 2}}
                    >
                        {translate('custom.db_backup')}
                    </Button>
                ) : null}

                {/* duplicate button */}
                {(right >= config.permissions.SUPERADMIN.value) ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ContentCopyIcon />}
                        onClick={async () => {
                            try {

                                // Confirm duplicate
                                const confirm_duplicate = await Swal.fire({
                                    icon: 'warning',
                                    title: translate('custom.warning'),
                                    text: translate('custom.are_you_sure_you_want_to_duplicate') + ' ' + translate('custom.you_will_not_be_able_to_undo_this_action'),
                                    showCancelButton: true,
                                    confirmButtonText: translate('custom.duplicate'),
                                    cancelButtonText: translate('custom.cancel'),
                                    confirmButtonColor: config.baseColor,
                                    // Show input for event name
                                    input: 'text',
                                    inputLabel: translate('custom.enter_event_name'),
                                    inputPlaceholder: translate('custom.event_name'),
                                    inputValidator: (value) => {
                                        if (!value || value.length < 1) {
                                            return translate('custom.please_enter_event_name');
                                        }
                                    },
                                });

                                if (!confirm_duplicate.isConfirmed) {
                                    return;
                                }

                                // if no event name entered, show error
                                if (!confirm_duplicate.value) {
                                    Swal.fire({
                                        title: translate('custom.error'),
                                        text: translate('custom.please_enter_event_name'),
                                        icon: 'error',
                                        confirmButtonColor: config.baseColor,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    });
                                    return;
                                }


                                // Show loading swal
                                Swal.fire({
                                    title: translate('custom.duplicating'),
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    didOpen: () => {
                                        Swal.showLoading();
                                    }
                                });
                                let duplicate_response = await fetch(config.apiUrl + '/events/' + record._id + '/duplicate', {
                                    ...getRequestOptions('POST'),
                                    body: JSON.stringify({name: confirm_duplicate.value})
                                });
                                duplicate_response = await duplicate_response.json();
                                
                                if(duplicate_response.error) {
                                    Swal.fire({
                                        title: duplicate_response?.error_code ? convertMessageCodeToMessage(duplicate_response.error_code) : duplicate_response.error,
                                        icon: 'error',
                                        confirmButtonColor: config.baseColor,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    });
                                    return;
                                }

                                Swal.fire({
                                    icon: 'success',
                                    title: translate('custom.success'),
                                    text: translate('custom.duplicate_successful'),
                                    confirmButtonText: translate('custom.ok'),
                                    confirmButtonColor: config.baseColor,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });

                                refresh();

                                
                            } catch(e) {
                                console.log(e.message)
                                // Close swal
                                Swal.fire({
                                    title: translate('custom.error'),
                                    text: e.message,
                                    icon: 'error',
                                    confirmButtonColor: config.baseColor,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }}
                        style={{margin: 2}}
                    >
                        {translate('custom.duplicate')}
                    </Button>
                ) : null}

            </Box>
        )
    }
}

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const EventListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" variant='outlined' alwaysOn />
        {(props.right >= config.permissions.SUPERADMIN.value) ? <QuickFilter source="active" label={'custom.active'} defaultValue={true} /> : null }
        {(props.right >= config.permissions.SUPERADMIN.value) ? <QuickFilter source="currently_running" label={'custom.currently_running'} defaultValue={true} /> : null }
        {(props.right >= config.permissions.SUPERADMIN.value) ? <QuickFilter source="archived" label={'custom.archived'} defaultValue={true} /> : null }
        {(props.right >= config.permissions.SUPERADMIN.value) ? <QuickFilter source="deleted_only" label={'custom.deleted_only'} defaultValue={true} /> : null }
        {(props.right >= config.permissions.SUPERADMIN.value) ? <QuickFilter source="with_deleted" label={'custom.with_deleted'} defaultValue={true} /> : null }
    </Filter>
);

const EventActions = (props) => {
    const right = getRight();
    return (
        <TopToolbar>
            <Actions {...props} />
            {(right >= config.permissions.SUPERADMIN.value) ? <DBRestore /> : null}
        </TopToolbar>
    )
};

export class EventList extends React.Component {

    constructor() {
        super();
    }

    render() {

        const opt = {
            ...this.props
        };
        const right = getRight();
        if (right < config.permissions.SUPERADMIN.value) {
            opt.hasCreate = false;
            opt.bulkActionButtons = false;
        }

        // let isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <>
                <List
                    {...opt}
                    actions={<EventActions />}
                    filters={<EventListFilter right={right} />}
                    pagination={<PostPagination right={right} />}
                    perPage={50}
                    filterDefaultValues={(right >= config.permissions.SUPERADMIN.value) ? { active: true } : {}}
                >
                    {this.isSmall ? (
                        <SimpleList
                            primaryText={record => record.name}
                        />
                    ) : (
                        <Datagrid>
                            <TextField label={translate('custom.name')} source="name" />
                            <ArchivedField />
                            {/* <TextField label={translate('custom.start_date')} source="dateBegin" />
                            <TextField label={translate('custom.end_date')} source="dateEnd" /> */}
                            <DateField label={translate('custom.start_date')} source="dateBegin" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                            <DateField label={translate('custom.end_date')} source="dateEnd" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                            <CustomButtons />
                            {/* <EditButton /> */}
                        </Datagrid>
                    )}
                </List>
            </>
        )
    }

}

const PostTitle = ({ record }) => {
    return <span>{record?.name ? '' : translate('custom.Create')} {translate('custom.event')} {record?.name ? ("'" + record.name + "'") : ''}</span>;
};

const validateEventCreateForm = async (values) => {
    const errors = {};
    const right = getRight();
    if (right < config.permissions.SUPERADMIN.value) {
        setName(values.name);
        if (values?.infomanik?.PERIOD_ID) {
            setPeriodID(values.infomaniak.PERIOD_ID);
        }
    }
    return errors
}

const validateEventEditForm = async (values) => {
    const right = getRight();
    const errors = {};
    if (right < config.permissions.SUPERADMIN.value) {
        setName(values.name);
        if (values?.infomanik?.PERIOD_ID) {
            setPeriodID(values.infomaniak.PERIOD_ID);
        }
        if (values?.language) {
            setLocale(values.language);
        }
    }
    return errors
}

const CustomToolbar = props => {
    return (
        <Toolbar {...props}>
            <SaveButton {...props} />
        </Toolbar>
    )
};

const HybridModeEnabled = (props) => {
    
    const record = useRecordContext(props);

    const dateBegin = record?.dateBegin;
    const currentDate = (new Date()).toISOString();

    let disabled = false;
    if (currentDate > dateBegin) {
        disabled = true;
    }

    return (
        <BooleanInput fullWidth={true} source="hybridMode" label={translate('custom.hybrid_mode')} disabled={disabled} />
    )
}

const FormRefundRichTextInputRenderer = (props) => {
    const record = useRecordContext(props);

    const form_refund_closed_text = record?.form_refund?.form_refund_closed_text;
    const form_refund_success_text = record?.form_refund?.form_refund_success_text;

    if (form_refund_closed_text && form_refund_success_text) {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <RichTextInput source="form_refund.form_refund_closed_text" label={translate('custom.form_refund_parameter.closed_text')} fullWidth={true} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <RichTextInput source="form_refund.form_refund_success_text" label={translate('custom.form_refund_parameter.success_text')} fullWidth={true} variant='outlined' />
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextInput multiline minRows={4} fullWidth={true} source="form_refund.form_refund_closed_text" label={translate('custom.form_refund_parameter.closed_text')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextInput multiline minRows={4} fullWidth={true} source="form_refund.form_refund_success_text" label={translate('custom.form_refund_parameter.success_text')} variant='outlined' />
                </Grid>
            </>
        );
    }
}

const DonationWebAppGreetingTextRenderer = (props) => {
    const record = useRecordContext(props);

    const webapp_greeting_text = record?.donation?.webapp_greeting_text;

    if (webapp_greeting_text) {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RichTextInput source="donation.webapp_greeting_text" label={translate('custom.donation_parameter.webapp_greeting_text')} fullWidth={true} variant='outlined' />
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextInput multiline minRows={4} fullWidth={true} source="donation.webapp_greeting_text" label={translate('custom.donation_parameter.webapp_greeting_text')} variant='outlined' />
                </Grid>
            </>
        )
    }
}

const DonationGreetingTextRenderer = (props) => {
    const record = useRecordContext(props);

    const greeting_text_for_donation_if_event_liked = record?.donation?.greeting_text_for_donation_if_event_liked;
    const greeting_text_for_donation_if_event_not_liked = record?.donation?.greeting_text_for_donation_if_event_not_liked;
    const greeting_text_for_donation_if_they_prefer_not_to_say = record?.donation?.greeting_text_for_donation_if_they_prefer_not_to_say;

    if (greeting_text_for_donation_if_event_liked && greeting_text_for_donation_if_event_not_liked && greeting_text_for_donation_if_they_prefer_not_to_say) {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <RichTextInput source="donation.greeting_text_for_donation_if_event_liked" label={translate('custom.donation_parameter.greeting_text_for_donation_if_event_liked')} fullWidth={true} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <RichTextInput source="donation.greeting_text_for_donation_if_event_not_liked" label={translate('custom.donation_parameter.greeting_text_for_donation_if_event_not_liked')} fullWidth={true} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <RichTextInput source="donation.greeting_text_for_donation_if_they_prefer_not_to_say" label={translate('custom.donation_parameter.greeting_text_for_donation_if_they_prefer_not_to_say')} fullWidth={true} variant='outlined' />
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextInput multiline minRows={4} fullWidth={true} source="donation.greeting_text_for_donation_if_event_liked" label={translate('custom.donation_parameter.greeting_text_for_donation_if_event_liked')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextInput multiline minRows={4} fullWidth={true} source="donation.greeting_text_for_donation_if_event_not_liked" label={translate('custom.donation_parameter.greeting_text_for_donation_if_event_not_liked')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextInput multiline minRows={4} fullWidth={true} source="donation.greeting_text_for_donation_if_they_prefer_not_to_say" label={translate('custom.donation_parameter.greeting_text_for_donation_if_they_prefer_not_to_say')} variant='outlined' />
                </Grid>
            </>
        )
    }
}

const DonationFinalGreetingTextRenderer = (props) => {
    const record = useRecordContext(props);

    const final_greeting_text_if_they_choose_to_donate = record?.donation?.final_greeting_text_if_they_choose_to_donate;
    const final_greeting_text_if_they_choose_not_to_donate = record?.donation?.final_greeting_text_if_they_choose_not_to_donate;

    if (final_greeting_text_if_they_choose_to_donate && final_greeting_text_if_they_choose_not_to_donate) {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <RichTextInput source="donation.final_greeting_text_if_they_choose_to_donate" label={translate('custom.donation_parameter.final_greeting_text_if_they_choose_to_donate')} fullWidth={true} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <RichTextInput source="donation.final_greeting_text_if_they_choose_not_to_donate" label={translate('custom.donation_parameter.final_greeting_text_if_they_choose_not_to_donate')} fullWidth={true} variant='outlined' />
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextInput multiline minRows={4} fullWidth={true} source="donation.final_greeting_text_if_they_choose_to_donate" label={translate('custom.donation_parameter.final_greeting_text_if_they_choose_to_donate')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextInput multiline minRows={4} fullWidth={true} source="donation.final_greeting_text_if_they_choose_not_to_donate" label={translate('custom.donation_parameter.final_greeting_text_if_they_choose_not_to_donate')} variant='outlined' />
                </Grid>
            </>
        )
    }
}

const DonationSettings = (props) => {

    const record = useRecordContext(props);

    return (
        <>
            <Grid container style={{width: '100%', ...sectionContainerStyles}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={headerDashedTextStyles}>
                    <BooleanInput source="donation.enabled" label={translate('custom.donation')} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <TextInput fullWidth={true} source="donation.notification_title" label={translate('custom.donation_parameter.notification_title')} variant='outlined' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <TextInput fullWidth={true} source="donation.notification_text" label={translate('custom.donation_parameter.notification_text')} variant='outlined' />
                        </Grid>
                    </Grid>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <DonationWebAppGreetingTextRenderer />
                    </Grid>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <BooleanInput fullWidth={true} source="donation.enable_suggestion_if_not_liked" label={translate('custom.donation_parameter.enable_suggestion_if_not_liked')} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <TextInput fullWidth={true} source="donation.suggestion_input_label" label={translate('custom.donation_parameter.suggestion_input_label')} variant='outlined' />
                        </Grid>
                    </Grid>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <DonationGreetingTextRenderer />
                    </Grid>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <DonationFinalGreetingTextRenderer />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}

const TwintDirectSettings = (props) => {

    // <FunctionField
    //     label="Name"
    //     render={record => `${record.first_name} ${record.last_name}`}
    // />;

    const record = useRecordContext(props);
    const form = useForm();

    return (
        <>
            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.twint_details')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TextInput fullWidth={true} source="twint_direct.merchant_uuid" label={translate('custom.merchant_uuid')} variant='outlined'
                        onChange={(e) => {
                            if (!record.twint_direct) {
                                record.twint_direct = {};
                            }
                            record.twint_direct.merchant_uuid = e.target.value;
                            form.change('twint_direct', record.twint_direct);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TextInput fullWidth={true} source="twint_direct.cashregister_id" label={translate('custom.cashregister_id')} variant='outlined'
                        onChange={(e) => {
                            if (!record.twint_direct) {
                                record.twint_direct = {};
                            }
                            record.twint_direct.cashregister_id = e.target.value;
                            form.change('twint_direct', record.twint_direct);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AssetUpload
                        accept={['.p12', '.pks']}
                        helperText={record?.twint_direct?.certificate ? (((record?.twint_direct?.certificate?.name) ? (record?.twint_direct?.certificate?.name + ' - ') : '') + translate('custom.certificate_already_exists_upload_new_one_to_replace')) : null}
                        uploadSuccess={(file_ids) => {
                            if (file_ids.length > 0) {
                                if (!record.twint_direct) {
                                    record.twint_direct = {};
                                }
                                record.twint_direct.certificate = file_ids[0];
                                form.change('twint_direct', record.twint_direct);
                            }
                        }}
                        uploadError={(error) => {
                            alert(translate('error_while_uploading'))
                            console.log("Error", error)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TextInput fullWidth={true} source="twint_direct.certificate_password" label={translate('custom.certificate_password')} variant='outlined'
                        onChange={(e) => {
                            if (!record.twint_direct) {
                                record.twint_direct = {};
                            }
                            record.twint_direct.certificate_password = e.target.value;
                            form.change('twint_direct', record.twint_direct);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const validateBankAccountName = (value) => {
    // it should be less than 70 chars
    if (value && value.length > 70) {
        return translate('custom.bank_account_name_too_long');
    }
    return null;
}


const event_form = (right, mode, amounts_to_display_choices = []) => {

    let myAmountChoices = config.amounts_to_display_choices;
    myAmountChoices = myAmountChoices.concat(amounts_to_display_choices.filter(item => myAmountChoices.indexOf(item) < 0));
    
    return (
        <>
            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.general_information')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth={true} source="name" label={translate('custom.name')} autoFocus variant='outlined' validate={validateRequired} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput fullWidth={true} source="dateBegin" label={translate('custom.start_date')}  variant='outlined' validate={(mode == 'edit') ? validateDate : null} required={(mode == 'edit')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput fullWidth={true} source="dateEnd" label={translate('custom.end_date')}  variant='outlined' validate={(mode == 'edit') ? validateDate : null} required={(mode == 'edit')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput
                        fullWidth={true}
                        source="dayStartTime"
                        label={translate('custom.day_start_time')}
                        variant='outlined'
                        validate={(mode == 'edit') ? validateDate : null}
                        required={(mode == 'edit')}
                        options={{ format: translate('custom.OPTIONS.i18n_time_format') }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SelectInput fullWidth={true} source="round" label={translate('custom.round')} choices={getChoices(config.round)} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SelectInput fullWidth={true} source="language" label={translate('custom.language')} choices={getChoices(config.languages)} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth={true} source="currencyCode" label={translate('custom.currency_code')} variant='outlined' validate={(mode == 'edit') ? validateRequired : null} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth={true} source="currencySymbol" label={translate('custom.currency_symbol')} variant='outlined' validate={(mode == 'edit') ? validateRequired : null} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <NumberInput fullWidth={true} source="maxLoadAmount" label={translate('custom.max_load_amount')} validate={validatePositiveNumberNonRequired} variant='outlined' onWheel={(e) => e.target.blur()} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <NumberInput
                        fullWidth={true} source="warningLoadAmount" label={translate('custom.warning_load_amount')} validate={validatePositiveNumberNonRequired} variant='outlined' onWheel={(e) => e.target.blur()}
                        helperText={translate('custom.warning_load_amount_description')}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <NumberInput fullWidth={true} source="maxEntranceAmount" label={translate('custom.max_entrance_amount')} validate={validatePositiveNumberNonRequired} variant='outlined' onWheel={(e) => e.target.blur()} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <NumberInput fullWidth={true} source="maxConsumptionPrice" label={translate('custom.max_consumption_price')} validate={validatePositiveNumberNonRequired} variant='outlined' onWheel={(e) => e.target.blur()} />
                </Grid>
                {(right >= config.permissions.SUPERADMIN.value) ? (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <TextInput fullWidth={true} source="shared_token" label={translate('custom.shared_token')} variant='outlined' />
                    </Grid>
                ) : null}
                {(right >= config.permissions.SUPERADMIN.value) ? (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <TextInput fullWidth={true} source="deposit_return_token" label={translate('custom.deposit_return_token')} variant='outlined' />
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={12} md={6} lg={8} xl={6}>
                    <AutocompleteArrayInput fullWidth source="amounts_to_display" label={translate('custom.amounts_to_display')} choices={myAmountChoices} style={{margin: '10px'}} variant='outlined'
                        defaultHighlightedIndex={0}
                        onCreate={(text) => {
                            if(text === "")
                                text = prompt(translate('custom.enter_a_new_one_to_create'));
                            const newTag = { id: text, name: text };
                            myAmountChoices.push(newTag);
                            return newTag;
                        }}
                        suggestionLimit={5}
                    />
                </Grid>
            </Grid>
            
            <Grid container style={{width: '100%'}} spacing={2}>
                {(right >= config.permissions.SUPERADMIN.value) ? (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <HybridModeEnabled />
                        {/* <BooleanInput fullWidth={true} source="hybridMode" label={translate('custom.hybrid_mode')} /> */}
                    </Grid>
                ) : null}
                {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="readOnly" label={translate('custom.read_only')} />
                </Grid> */}
            </Grid>

            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.customization')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ImageInput source="customization.logo" label={translate('custom.logo')} accept="image/*" maxSize={5000000} multiple>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </Grid>
            </Grid>



            <Grid container style={{width: '100%', ...sectionContainerStyles}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={headerDashedTextStyles}>
                    <BooleanInput source="form_refund.enabled" label={translate('custom.form_refund')} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <DateTimeInput fullWidth={true} source="form_refund.dateEnd" label={translate('custom.end_date')} variant='outlined' />
                        </Grid>
                    </Grid>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <FormRefundRichTextInputRenderer />
                    </Grid>
                </Grid>
            </Grid>

            {(right >= config.permissions.SUPERADMIN.value) ? (
            <Grid container style={{width: '100%', ...sectionContainerStyles}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={headerDashedTextStyles}>
                    <BooleanInput source="online_payment" label={translate('custom.online_payment')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth source="enable_credit_card" label={translate('custom.enable_credit_card')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="twint.enable_twint" label={translate('custom.enable_twint_payrexx')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="enable_cards" label={translate('custom.enable_cards_payrexx')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="twint.enable_twint_direct" label={translate('custom.enable_twint_direct')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="twint.always_refund_by_cash" label={translate('custom.always_refund_by_cash')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <NumberInput fullWidth={true} source="twint.freight" step="0.01" label={translate('custom.freight')} validate={validatePositiveNumberNonRequired} variant='outlined' onWheel={(e) => e.target.blur()} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="twint.disable_refund_by_twint" label={translate('custom.disable_refund_by_twint')} />
                </Grid>
            </Grid>
            ) : null}

            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.bank_information')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TextInput
                        fullWidth={true}
                        source="bank.account_name"
                        label={translate('custom.account_name')}
                        variant='outlined'
                        // limit to 70 chars
                        validate={validateBankAccountName}
                        error={validateBankAccountName}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TextInput fullWidth={true} source="bank.iban" label="IBAN" variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TextInput fullWidth={true} source="bank.bic" label="BIC" variant='outlined' />
                </Grid>
            </Grid>

            {(right >= config.permissions.ADMIN.value) ? (
                <>
                    <div style={{width: '100%'}}>
                        <h3 style={headerTextStyles}>{translate('custom.payrexx_details')}</h3>
                    </div>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={7} xl={6}>
                            <TextInput fullWidth={true} source="pay.URL" label={translate('custom.url')} variant='outlined' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                            <TextInput fullWidth={true} source="pay.SECRET" label={translate('custom.secret')} variant='outlined' />
                        </Grid>
                    </Grid>


                    <TwintDirectSettings />

                </>
            ) : null}

            {(right >= config.permissions.SUPERADMIN.value) ? (
            <Grid container style={{width: '100%', ...sectionContainerStyles}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={headerDashedTextStyles}>
                    <BooleanInput source="ticketsEnabled" label={translate('custom.tickets')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={7} xl={6}>
                    <TextInput fullWidth={true} source="infomaniak.BASE_URL" label={translate('custom.url')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                    <TextInput fullWidth={true} source="infomaniak.KEY" label={translate('custom.key')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                    <TextInput fullWidth={true} source="infomaniak.USERNAME" label={translate('custom.username')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                    <TextInput fullWidth={true} source="infomaniak.PASSWORD" label={translate('custom.password')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                    <TextInput fullWidth={true} source="infomaniak.SHARED_KEY" label={translate('custom.shared_key')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                    <TextInput fullWidth={true} source="infomaniak.PERIOD_ID" label={translate('custom.period_id')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
                    <SelectArrayInput fullWidth={true} source="zone.ages" label={translate('custom.ages')} choices={getChoices(config.entrance_ages)} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <SelectArrayInput fullWidth={true} source="zone.payment_types" label={translate('custom.payment_types')} choices={getChoices(config.payment_types)} variant='outlined' />
                </Grid>
            </Grid>
            ) : null}
            
            {(right >= config.permissions.SUPERADMIN.value) ? (
                <>
                    <div style={{width: '100%'}}>
                        <h3 style={headerTextStyles}>{translate('custom.auto_login')}</h3>
                    </div>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={7} xl={6}>
                            <TextInput fullWidth={true} source="autoLogin.SECURE_KEY" label={translate('custom.secure_key')} variant='outlined' />
                        </Grid>
                    </Grid>
                </>
            ) : null}
            
            {(right >= config.permissions.SUPERADMIN.value) ? (
                <>
                    <div style={{width: '100%'}}>
                        <h3 style={headerTextStyles}>{translate('custom.wristband')}</h3>
                    </div>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={7} xl={6}>
                            <TextInput fullWidth={true} source="wristband.password" label={translate('custom.password')} variant='outlined' />
                        </Grid>
                    </Grid>
                </>
            ) : null}
            

            {(right >= config.permissions.SUPERADMIN.value) ? (
            <>
                <div style={{width: '100%'}}>
                    <h3 style={headerTextStyles}>{translate('custom.app_preferences')}</h3>
                </div>
                <Grid container style={{width: '100%'}} spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <NumberInput fullWidth={true} min={0} source="app_preferences.refresh_timeout" label={translate('custom.refresh_timeout')} variant='outlined' onWheel={(e) => e.target.blur()}
                            onBlur={(e) => {
                                // if blank, set to 0
                                if (e.target.value === '') {
                                    e.target.value = 0;
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </>
            ) : null}
            
            <div style={{width: '100%'}}>
                <h3 style={headerTextStyles}>{translate('custom.wifi')}</h3>
            </div>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth={true} min={0} source="wifi.ssid" label={translate('custom.ssid')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth={true} min={0} source="wifi.password" label={translate('custom.password')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="wifi.is_wep" label={translate('custom.is_wep')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="wifi.is_hidden" label={translate('custom.is_hidden')} variant='outlined' />
                </Grid>
            </Grid>

            {/* props on change */}
            <DonationSettings />

        </>
    )
}

export class EventCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            amounts_to_display_choices: []
        };
    }

    async componentDidMount() {

    }

    render() {
        const right = getRight();
        return (
            <Create title={<PostTitle />} {...this.props}>
                <SimpleForm variant="standard" validate={validateEventCreateForm}>
                    {event_form(right, 'create')}
                </SimpleForm>
            </Create>
        )
    }

}



export class EventEdit extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            amounts_to_display_choices: [],
            current_tab: 'edit_event'
        };
    }

    componentDidMount = async () => {
        let amounts = [];
        try {
            let parameters = await request(this.props.location.pathname, 'GET', null);
            if(parameters) {
                if(parameters.error) {
                    alert(parameters.error);
                    return;
                }
                let amounts_to_display_choices = parameters.amounts_to_display;
                for(let i = 0; i < amounts_to_display_choices.length; i++) {
                    amounts.push({
                        id: amounts_to_display_choices[i],
                        name: amounts_to_display_choices[i]
                    });
                }
            }
            this.setState({
                amounts_to_display_choices: amounts
            });
        } catch(e) {
            console.log("Error", String(e));
        }
    }

    render() {
        const right = getRight();


        return (

            <>

            {(right >= config.permissions.SUPERADMIN.value) ? (
                <Tabs
                    value={this.state.current_tab}
                    onChange={(e, value) => {
                        this.setState({current_tab: value});
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label={translate('custom.details')} value="edit_event" />
                    <Tab label={translate('custom.smartphones')} value="edit_event_smartphones" />
                    <Tab label={translate('custom.mypos_terminals')} value="edit_event_mypos_terminals" />
                </Tabs>
            ) : null}

            {(this.state.current_tab == 'edit_event') ? (
                <Edit title={<PostTitle />} {...this.props} undoable={false}
                    onSuccess={() => {
                        // Swal bottom centre toast
                        Swal.fire({
                            toast: true,
                            position: 'bottom',
                            icon: 'success',
                            title: translate('custom.saved'),
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    }}
                >
                    <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={validateEventEditForm} toolbar={<CustomToolbar />}>
                        {event_form(right, 'edit', this.state.amounts_to_display_choices)}
                    </SimpleForm>
                </Edit>
            ) : null}

            {(this.state.current_tab == 'edit_event_smartphones') ? (
                <AddEditEventSmartphones
                    event_id={this.props.id}
                />
            ) : null}
            
            {(this.state.current_tab == 'edit_event_mypos_terminals') ? (
                <AddEditEventMyposTerminals
                    event_id={this.props.id}
                />
            ) : null}


            </>
        )
    }


}

const headerTextStyles = {
    width: '100%',
    borderBottom: '2px solid' + config.baseColor,
    marginBottom: '30px',
    color: config.baseColor
}

const headerDashedTextStyles = {
    width: '100%',
    borderBottom: '2px dashed' + config.baseColor,
    marginBottom: '30px',
    color: config.baseColor
}

const sectionContainerStyles = {
    width: '100%',
    border: '2px solid' + config.baseColor,
    borderRadius: '10px',
    marginBottom: '20px'
}
