import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import ActionHome from '@mui/icons-material/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TicketsIcon from '@mui/icons-material/LocalActivity';
import Divider from '@material-ui/core/Divider';
import ActionEdit from '@mui/icons-material/Edit';
import config from '../../config';
import { translate } from '../../i18n/customI18nProvider';
import { getName, getPeriodID, getRight } from '../../services/storage';
import Button from '@mui/material/Button';
import RouterIcon from '@mui/icons-material/Router';
import UserIcon from '@mui/icons-material/People';
import Link from '@mui/material/Link';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import TokenIcon from '@mui/icons-material/Token';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';


const Menu = ({ onMenuClick, logout }) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const name = getName();
    const periodId = getPeriodID();
    const version = config.version;
    const right = getRight();
    return (
        <List style={{width: 220}}>
            <ListItem>
                <ListItemText
                    primary="JM Contactless"
                    secondary={ (right < config.permissions.SUPERADMIN.value) ? name : ''}
                />
            </ListItem>
            
            
            {(right < config.permissions.SUPERADMIN.value) ? (
                <>
                    <Divider />
                    <a href='/statistics' style={{ textDecoration: "inherit", color: "inherit" }} >
                        <ListItem button>
                            <ListItemIcon>
                                <ActionHome />
                            </ListItemIcon>
                            <ListItemText primary={translate('custom.home')} />
                        </ListItem>
                    </a>
                    {(periodId?.length && periodId != "undefined" && periodId != "null" && right >= config.permissions.ADMIN.value) ? (
                        <a href={`https://manager.infomaniak.com/v3/${periodId}/tickets/all`} target={"_blank"} style={{ textDecoration: "inherit", color: "inherit" }} >
                            <ListItem button>
                                <ListItemIcon>
                                    <TicketsIcon />
                                </ListItemIcon>
                                <ListItemText primary={translate('custom.tickets')} />
                            </ListItem>
                        </a>
                    ) : null}
                    <a href='/admin' style={{ textDecoration: "inherit", color: "inherit" }} >
                        <ListItem button>
                            <ListItemIcon>
                                <ActionEdit />
                            </ListItemIcon>
                            <ListItemText primary={translate('custom.modify')} />
                        </ListItem>
                    </a>
                </>
            ) : null}
            
            <Divider />
            {resources
                .filter((resource) => {
                    // dont show transactions
                    if (resource.name === 'transactions' || resource.name === 'entrance_checks') {
                        return false;
                    }
                    //  if right is superadmin, dont show users
                    if (right == config.permissions.SUPERADMIN.value && (resource.name === 'bars')) {
                        return false;
                    }
                    return true;
                })
                .map(resource => (
                <MenuItemLink
                    button
                    key={resource.name}
                    onClick={onMenuClick}
                    to={`/${resource.name}`}
                    sidebarIsOpen={open}
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    primaryText={resource.options.label}
                >

                </MenuItemLink>
            ))}
            <Divider/>
            
            {(right >= config.permissions.SUPERADMIN.value) ? (
                <Link
                    href="/v2/admin/users"
                    underline='none'
                    color='gray'
                    style={{textDecoration: 'none'}}
                >
                    <Button
                        variant="text"
                        startIcon={<UserIcon style={{marginRight: 8}} />}
                        style={{width: '100%', justifyContent: 'flex-start', marginLeft: 15, textTransform: 'none'}}
                    >
                        {translate('custom.users')}
                    </Button>
                </Link>
            ) : null}
            
            {(right >= config.permissions.SUPERADMIN.value) ? (
                <Link
                    href="/v2/admin/devices"
                    underline='none'
                    color='gray'
                    style={{textDecoration: 'none'}}
                >
                    <Button
                        variant="text"
                        startIcon={<RouterIcon style={{marginRight: 8}} />}
                        style={{width: '100%', justifyContent: 'flex-start', marginLeft: 15, textTransform: 'none'}}
                    >
                        {translate('custom.devices')}
                    </Button>
                </Link>
            ) : null}

            {(right >= config.permissions.SUPERADMIN.value) ? (
                <Link
                    href="/v2/admin/mypos_terminals"
                    underline='none'
                    color='gray'
                    style={{textDecoration: 'none'}}
                >
                    <Button
                        variant="text"
                        startIcon={<PointOfSaleIcon style={{marginRight: 8}} />}
                        style={{width: '100%', justifyContent: 'flex-start', marginLeft: 15, textTransform: 'none'}}
                    >
                        {translate('custom.mypos_terminals')}
                    </Button>
                </Link>
            ) : null}

            {(right >= config.permissions.SUPERADMIN.value) ? (
                <Link
                    href="/v2/admin/tickets"
                    underline='none'
                    color='gray'
                    style={{textDecoration: 'none'}}
                >
                    <Button
                        variant="text"
                        startIcon={<LocalActivityIcon style={{marginRight: 8}} />}
                        style={{width: '100%', justifyContent: 'flex-start', marginLeft: 15, textTransform: 'none'}}
                    >
                        {translate('custom.tickets')}
                    </Button>
                </Link>
            ) : null}

            {(right >= config.permissions.SUPERADMIN.value) ? (
                <Link
                    href="/v2/admin/tokens"
                    underline='none'
                    color='gray'
                    style={{textDecoration: 'none'}}
                >
                    <Button
                        variant="text"
                        startIcon={<TokenIcon style={{marginRight: 8}} />}
                        style={{width: '100%', justifyContent: 'flex-start', marginLeft: 15, textTransform: 'none'}}
                    >
                        {translate('custom.tokens')}
                    </Button>
                </Link>
            ) : null}

            <Divider/>
            {logout}
            <Divider/>
            <div>
                <small>{version}</small>
            </div>
        </List>
    );

};

export default Menu;