import React, { Component } from 'react';
import '../css/App.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ActionDebit from '@mui/icons-material/LocalBar';
import ActionCredit from '@mui/icons-material/LocalAtm';
import ActionTwint from '@mui/icons-material/CreditCard';
import ActionUnpaid from '@mui/icons-material/Lock';
import ActionEntrance from '@mui/icons-material/LocalActivity';
import ActionZone from '@mui/icons-material/Toll';
import ActionCheckout from '@mui/icons-material/ExitToApp';
import { MuiThemeProvider, withStyles, withTheme } from '@material-ui/core/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { nativeTranslate as translate } from "../i18n/customI18nProvider";
import braceletImage from '../images/bracelet2.png';
import backgroundImage from '../images/client-background-image.jpg';
import Grid from '@material-ui/core/Grid';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditedIcon from '@mui/icons-material/Add';
import DebitedIcon from '@mui/icons-material/Remove';
import RefundedIcon from '@mui/icons-material/CallMissed';
import DonationIcon from '@mui/icons-material/Redeem';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import theme from './../components/Theme';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MoveDownIcon from '@mui/icons-material/MoveDown';

// Firebase Notifications
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";


import {formatCurrencySign, convertMessageCodeToMessage, request, formatCurrency} from "../util";


import 'whatwg-fetch';

import config from '../config';
import { Box, Card, CardContent, FormControlLabel, Hidden, IconButton, Modal, Switch, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { getClientNotificationToken, setClientNotificationToken } from '../services/storage';
import IOSSwitch from '../components/common/iosSwitch';

let messaging;
try {

const firebaseConfig = config.firebaseConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// // Initialize Firebase Cloud Messaging and get a reference to the service
messaging = getMessaging(app);

onMessage(messaging, (payload) => {
    // console.log("on Message",payload);
    window.alert(payload.notification.title + '\n' + payload.notification.body);
});
} catch (e) {
    console.warn("Firebase not loaded - " + e.message);
}



const customStyles = {
    glassBackgroundMain: {
        background: 'rgba(255, 255, 255, 0.46)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        borderRadius: '20px'
    },
    glassBackground: {
        background: 'rgba(255, 255, 255, 0.17)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        width: '100%'
    },
    iconStyle: {
        fontSize: '30px',
        padding: '5px',
        backgroundColor :'white',
        borderRadius: '5px'
    }
}

const ICONS = {
    'credit' : {
        'text' : translate('custom.recharge'),
        'color' : "#060",
        'icon' : <ActionCredit style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_cash' : {
        'text' : translate('custom.recharge'),
        'color' : "#060",
        'icon' : <ActionCredit style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_twint' : {
        'text' : translate('custom.recharge_twint'),
        'color' : "#060",
        'icon' : <ActionTwint style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_card' : {
        'text' : translate('custom.recharge_card'),
        'color' : "#060",
        'icon' : <ActionTwint style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_wristband' : {
        'text' : translate('custom.balance_transfer'),
        'color' : "#060",
        'icon' : <MoveDownIcon style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'unpaid' : {
        'text' : translate('custom.recharge_non_refundable'),
        'color' : "#060",
        'icon' : <ActionUnpaid style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'unpaid_cash' : {
        'text' : translate('custom.recharge_non_refundable'),
        'color' : "#060",
        'icon' : <ActionUnpaid style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'debit': {
        'text' : translate('custom.consumption'),
        'color' : "#08607e",
        'icon' : <ActionDebit style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'entrance': {
        'text' : translate('custom.entrance'),
        'color' : "#08607e",
        'icon' : <ActionEntrance style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'zone': {
        'text' : translate('custom.zone'),
        'color' : "#08607e",
        'icon' : <ActionZone style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'checkout': {
        'text' : translate('custom.refund'),
        'color' : "#c00",
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'voucher' : {
        'text' : translate('custom.voucher'),
        'color' : "#060",
        'icon' : <ConfirmationNumberIcon style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'refund' : {
        'text' : translate('custom.refund'),
        'color' : "#c00",
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'forced_refund' : {
        'text' : translate('custom.forced_refund'),
        'color' : "#c00",
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'form_refund' : {
        'text' : translate('custom.form_refund'),
        'color' : "#c00",
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'card_refund' : {
        'text' : translate('custom.card_refund'),
        'color' : "#c00",
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'twint_refund' : {
        'text' : translate('custom.twint_refund'),
        'color' : "#c00",
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'donation' : {
        'text' : translate('custom.donation'),
        'color' : "#08607e",
        'icon' : <DonationIcon style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'balance_transfer_from_wristband' : {
        'text' : translate('custom.balance_transfer'),
        'color' : "#08607e",
        'icon' : <MoveUpIcon style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
};

const canCollapse = transaction => transaction.type === config.transaction_types.DEBIT;


class Client extends Component {

    constructor(){
        super();
        this.state = {
            general_information: {
                name: '',
                online_payment: false,
            },
            donation_amounts: config.donation_amounts,
            age: null,
            uid: '',
            short_id: '',
            name: null,
            balance: '',
            unpaid: 0,
            loading: true,
            transactions: [],
            vouchers: [],
            vouchers_names: [],
            animationCount: 0,
            notifications_enabled: false,
            notifications_permission_denied: false,
            logo: null,
            donationModalOpen: false,
            error: false,
            error_code: '',
            eventLiked: '',
            eventNotLikedText: '',
            donationChoice: null,
            donationType: '',
            donationAmount: null
        }
        this.currency = '';
    }

    componentDidMount = async () => {
        try {

            this.setState({loading: true, error: false});

            this.event = this.props.match.params.event;
            this.client = this.props.match.params.client;
            let response;
            if (this.event) {
                response = await request("/clients/short/" + this.client + "?event_id=" + this.event, 'GET');
            } else {
                response = await request("/clients/short/" + this.client, 'GET');
            }

            if(response.error) {
                if(response.error_code) {
                    if (response.error_code == '|event_is_archived|' || response.error_code == '|wallets_are_not_open_yet|') {
                        await this.setState({loading: false, error: true, error_code: response.error_code});
                        return;
                    } else {
                        alert(convertMessageCodeToMessage(response.error_code));
                    }
                } else {
                    alert(response.error);
                }
                window.location.href = "/client";
                return;
            }

            let client_uid = response.uid;

            let general_information;
            if (this.event) {
                general_information = await request(`/events/general?event_id=${this.event}`, 'GET');
            } else {
                general_information = await request(`/events/general?client_uid=${client_uid}`, 'GET');
            }
            if (general_information.error) {
                alert(general_information.error);
                return;
            }

            let urlParams = new URLSearchParams(window.location.search);
            let donation = urlParams.get('donation');
            let donation_exists = this.props.location.pathname;
            donation_exists = donation_exists.split('/').includes('donation');
            if(donation_exists) {
                donation = "true";
            }

            if(donation && donation == "true" && general_information?.donation?.enabled) {
                // let donationChoiceMade = response?.feedback?.donationChoice;
                // if (donationChoiceMade) {
                //     Swal.fire({
                //         icon: 'info',
                //         iconColor: config.baseColor,
                //         title: translate('custom.thank_you'),
                //         text: translate('custom.you_have_already_made_your_choice'),
                //         showCancelButton: false,
                //         confirmButtonColor: config.baseColor
                //     }).then(() => {
                //         window.location.href = "/client/" + this.state.uid;
                //     });
                // } else {
                    this.setState({donationModalOpen: true});
                // }
            }

            let customization;
            if (this.event) {
                customization = await request(`/events/parameter/customization?client_uid=${client_uid}&event_id=${this.event}`, 'GET');
            } else {
                customization = await request(`/events/parameter/customization?client_uid=${client_uid}`, 'GET');
            }
            if (customization.error) {
                alert(customization.error);
                return;
            }
            customization = customization?.customization;
            if(customization) {
                let logo = customization?.logo?.[0]?.src;
                if(logo) {
                    this.setState({logo});
                }
            }

            this.setState({general_information});
            this.currency = general_information.currencySymbol ? general_information.currencySymbol : general_information.currencyCode;

            if(response.vouchers) {
                response.vouchers = response.vouchers.filter((voc) => {
                    if(voc.quantity)
                        return voc
                })
            }

            await this.setState({
                uid: response.uid,
                short_id: response.short_id,
                general_information,
                name: response.name,
                balance: response.balance,
                transactions: response.transactions,
                vouchers: response.vouchers,
                vouchers_names: response.vouchers_names,
                unpaid: response.split_balance?.unpaid,
                loading: false,
                spent: response.split_balance?.spent,
                age: response.age,
                eventLiked: response.feedback?.eventLiked ? response.feedback?.eventLiked : '',
                eventNotLikedText: response.feedback?.eventNotLikedText,
                donationChoice: response.feedback?.donationChoice,
                donationType: response.feedback?.donationType,
                donationAmount: response.feedback?.donationAmount
            });

            try {

                if (!this.event) {

                    let permission = await this.getNotificationPermissionStatus();
                    if (permission === "granted") {
                        // do noting
                    } else {
                        this.setState({notifications_enabled: false});
                        await this.RequestPermission();
                    }


                    let notification_token = await this.getNotificationToken();
                    if (!notification_token) {
                        return;
                    }
                    let notifications_enabled = await request(`/clients/${client_uid}/notification_token/exists`, 'POST', null, { notification_token }, false)
                    
                    if (notifications_enabled?.exists) {
                        this.setState({notifications_enabled: true});
                    } else {
                        let notifications_diabled = await request(`/clients/${client_uid}/notification_token/exists_disabled`, 'POST', null, { notification_token }, false)
                        if (notifications_diabled?.exists) {
                            this.setState({notifications_enabled: false});
                        } else {
                            await this.getStartToken();
                        }
                    }

                }
                
                
                // this.getStartToken();
            } catch (error) {
                console.log("Error Getting Notification Token - " + error.message);
            }

        } catch (error){
            console.log(error.message);
            setTimeout(this.componentDidMount, 500);
        }
    }

    getNotificationToken = async () => {
        try {
            let current_client_token = getClientNotificationToken(this.state.uid);
            if (current_client_token) {
                return current_client_token;
            }

            if (messaging) {
                const token = await getToken(messaging, {
                    vapidKey: config.firebaseVapidKey
                });
                setClientNotificationToken(this.state.uid, token);
                return token;
            }
        } catch (error) {
            console.warn("Error Getting Notification Token - " + error.message);
        }
    }

    async getStartToken(){
        try {
            let currentToken = await this.getNotificationToken();
            if (currentToken) {
                // Send token to server
                await this.sendTokenToServer(currentToken);
            } else {
                this.RequestPermission();
            }
        } catch (error) {
            console.warn("Error Getting Notification Token - " + error.message);
        }
    }

    getNotificationPermissionStatus = async () => {
        try {
            let permission = await Notification.permission;
            return permission;
        } catch (error) {
            console.warn("Error getting notification permission stauts - " + error.message);
        }
    }

    
    RequestPermission = async () => {
        try {

            let permission = await Notification.requestPermission();
            if (permission === "granted") {
                await this.getStartToken();
            } else {
                await this.setState({notifications_permission_denied: true});
                return false
            }
        } catch (error) {
            console.warn("Error Requesting Permission - " + error.message);
        }
    }

    sendTokenToServer = async (currentToken) => {
        try {
            let client_uid = this.state.uid;
            let response = await request('/clients/' + client_uid + '/notification_token', 'POST', null, {notification_token: currentToken}, false);
            if(response.error) {
                console.log(response.error);
                return;
            }
            this.setState({notifications_enabled: true});
        } catch (error) {
            console.log(error.message);
        }
    }

    toggleNotificationsEnabled = async (e) => {
        try {

            if (!e.target.checked) {
                let client_uid = this.state.uid;
                deleteToken(messaging);
                let currentToken = await this.getNotificationToken();
                if (currentToken) {
                    let response = await request('/clients/' + client_uid + '/notification_token', 'DELETE', null, {notification_token: currentToken}, false);
                    if(response.error) {
                        console.log(response.error);
                        return;
                    }
                    this.setState({notifications_enabled: false});
                }
            } else {
                this.getStartToken();
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    getErrorRender() {
        return (
            <div
                style={{
                    backgroundImage: "linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(" + backgroundImage + ")",
                    height: '100vh',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    overflow: 'auto',
                    backgroundPosition: 'center center'
                }}
            >
                <Grid container alignItems='center' justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={8} lg={6}
                        style={customStyles.glassBackground}
                    >
                        <Grid item
                            style={customStyles.glassBackgroundMain}
                        >
                            {this.customizationRender()}
                            {this.braceletHeaderRender()}
                            
                            <div style={{textAlign: 'center'}}>
                                {(this.state.error_code == '|event_is_archived|') ? (
                                    <h4 style={{color: config.baseColor}}>{translate('custom.event_is_over')}</h4>
                                ) : null}
                                {(this.state.error_code == '|wallets_are_not_open_yet|') ? (
                                    <>
                                        <h3 style={{color: config.baseColor}}>{translate('custom.TEXT.wallets_will_be_opened_on_line1')}</h3>
                                        <h4 style={{color: config.baseColor}}>{translate('custom.TEXT.wallets_will_be_opened_on_line2')}</h4>
                                    </>
                                ) : null}
                            </div>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    render(){

        if (this.state.error && !this.event) {
            return (
                <>
                    {this.getErrorRender()}
                </>
            )
        }

        return (
            <>
                {this.getNewRender()}
            </>
        )

    }

    customizationRender() {
        return (
            <Grid container>
                {this.state.logo ? (
                    <Grid item xs={12} sm={12}>
                        <Hidden mdUp>
                            <div style={{textAlign: 'center'}}>
                                <img alt="logo" src={this.state.logo} height="80px" width="auto" style={{maxWidth: '100%'}} />
                            </div>
                        </Hidden>
                        <Hidden smDown>
                            <div style={{textAlign: 'right'}}>
                                <img alt="logo" src={this.state.logo} height="80px" width="auto" />
                            </div>
                        </Hidden>
                    </Grid>
                ) : null}
            </Grid>
        )
    }

    braceletHeaderRender() {

        let event_name = this.state.general_information.name ? this.state.general_information.name : null;

        return (

            <div style={{textAlign: 'center'}}>
                {(this.state.name || this.state.short_id) ? (
                    <h3 style={{color: config.baseColor}}>{translate('custom.bracelet')} {this.state.name ? this.state.name : this.state.short_id}</h3>
                ) : null}

                <img alt="bracelet" style={{width: '100%'}} src={braceletImage} />

                {event_name ? (
                    <h4 style={{color: config.baseColor}}>{event_name}</h4>
                ) : null}

            </div>

        )
    }

    braceletDetailsRender() {
        return (
            <div>
                <h3 style={{textAlign: 'left'}}>{translate('custom.bracelet_details')}</h3>

                <Hidden xsDown>
                    <Grid container justifyContent='space-around' style={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            <h4>{translate('custom.balance')}</h4>
                            <h3 style={(this.state.balance < 0) ? {color: '#c00'} : {color: '#060'}}>{this.currency} {formatCurrencySign(this.state.balance)}</h3>
                        </Grid>
                        {(this.state.age != null) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.age')}</p>
                                <h4 style={{color: config.baseColor}}>{translate('custom.' + config.ages_reverse_map[this.state.age])}</h4>
                            </Grid>
                        : null}
                        {(this.state.unpaid > 0) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.unpaid')}</p>
                                <h4 style={{color: 'gray'}}>{this.currency} {formatCurrencySign(this.state.unpaid)}</h4>
                            </Grid>
                        : null}
                        {(this.state.spent != null) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.total_spent')}</p>
                                <h4 style={{color: config.baseColor}}>{this.currency} {formatCurrencySign(this.state.spent)}</h4>
                            </Grid>
                        : null}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <div style={{margin: '20px'}}>
                        <h4>
                            <span>{translate('custom.balance')}</span>
                            <span style={{color: 'green', float: 'right', fontWeight: '800'}}>{this.currency} {formatCurrencySign(this.state.balance)}</span>
                        </h4>
                    </div>
                    {(this.state.unpaid > 0) ?
                        <div style={{margin: '20px'}}>
                            <h4>
                                <span>{translate('custom.unpaid')}</span>
                                <span style={{color: 'gray', float: 'right', fontWeight :'800'}}>{this.currency} {formatCurrencySign(this.state.unpaid)}</span>
                            </h4>
                        </div>
                    : null}
                    {(this.state.age != null) ?
                        <div style={{margin: '20px'}}>
                            <h4>
                                <span>{translate('custom.age')}</span>
                                <span style={{color: config.baseColor, float: 'right', fontWeight: '800'}}>{translate('custom.' + config.ages_reverse_map[this.state.age])}</span>
                            </h4>
                        </div>
                    : null}
                </Hidden>

                

            </div>
        )
    }

    addMoneyRender() {
        if(this.props.match.params.app)
            return(<></>)

        if(!this.state.general_information.online_payment)
            return(<></>)

        let end_date = new Date(this.state.general_information.dateEnd);
        let curr_date = new Date()
        if(curr_date > end_date)
            return(<></>)


        let enable_twint = this.state?.general_information?.twint?.enable_twint;
        let enable_cards = this.state?.general_information?.enable_cards;
        let enable_twint_direct = this.state?.general_information?.twint?.enable_twint_direct;
        let twint_payment_types = ['twint', 'twint_direct'];
        let random_twint_payment_type = twint_payment_types[Math.floor(Math.random() * twint_payment_types.length)];

        return (
            <div style={{textAlign: 'center'}}>



                <Grid container justifyContent='center' style={{textAlign: 'center'}}>
                    {/* <Hidden xsDown>
                        <Grid item sm={4} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto'}}>
                            {this.state.animationCount == 3 ? <img src={rightAnimated1} style={{width: '50%', height: 'auto'}} /> : null}
                            {this.state.animationCount == 2 ? <img src={rightAnimated2} style={{width: '50%', height: 'auto'}} /> : null}
                            {this.state.animationCount == 1 ? <img src={rightAnimated3} style={{width: '50%', height: 'auto'}} /> : null}
                            {this.state.animationCount == 0 ? <img src={rightAnimated4} style={{width: '50%', height: 'auto'}} /> : null}
                        </Grid>
                    </Hidden> */}

                    {(enable_twint && enable_twint_direct) ? (
                        // If both twint & twint direct are enabled, show only one button that chooses between the two dynamically
                        <>
                            <Grid item sm={12} md={6} lg={6} style={{verticalAlign: 'middle'}}>
                                <Link style={{textDecoration: 'none'}}
                                    to={this.state.short_id ? `/client/${this.state.short_id}/pay?payment_type=${random_twint_payment_type}` : '#'}
                                >
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: config.baseColor,
                                            color: 'white'
                                        }}
                                        startIcon={<CreditCardIcon />}
                                    >
                                        {translate('custom.add_money_with_twint')}
                                    </Button>
                                </Link>
                            </Grid>
                        </>
                    ) : (
                        // If any one of twint or twint direct is enabled, show the button for that one
                        <>
                            {enable_twint ? (
                                <Grid item sm={12} md={6} lg={6} style={{verticalAlign: 'middle'}}>
                                    <Link style={{textDecoration: 'none'}}
                                        to={this.state.short_id ? '/client/'+this.state.short_id+'/pay?payment_type=twint' : '#'}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: config.baseColor,
                                                color: 'white'
                                            }}
                                            startIcon={<CreditCardIcon />}
                                        >
                                            {translate('custom.add_money_with_twint')}
                                        </Button>
                                    </Link>
                                </Grid>
                            ) : null}
                            
                            {enable_twint_direct ? (
                                <Grid item sm={12} md={6} lg={6} style={{verticalAlign: 'middle'}}>
                                    <Link style={{textDecoration: 'none'}}
                                        to={this.state.short_id ? '/client/'+this.state.short_id+'/pay?payment_type=twint_direct' : '#'}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: config.baseColor,
                                                color: 'white'
                                            }}
                                            startIcon={<CreditCardIcon />}
                                        >
                                            {translate('custom.add_money_with_twint')}
                                        </Button>
                                    </Link>
                                </Grid>
                            ) : null}
                        </>
                    )}
                </Grid>
                
                {(enable_cards) ? (
                    <Grid item sm={12} md={12} lg={12} style={{verticalAlign: 'middle', marginTop: 10}}>
                        <Link style={{textDecoration: 'none'}}
                            to={this.state.short_id ? '/client/'+this.state.short_id+'/pay?payment_type=other' : '#'}
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    borderColor: config.baseColor,
                                    color: config.baseColor
                                }}
                                startIcon={<CreditCardIcon />}
                            >
                                {translate('custom.recharge_with_other_methods')}
                            </Button>
                        </Link>
                    </Grid>
                ) : null}

            </div>
        )
    }

    refundRender() {
        if(this.props.match.params.app)
            return(<></>)

        if(!this.state.general_information?.form_refund?.enabled)
            return(<></>)

        if(this.state.general_information?.form_refund?.dateEnd) {
            let end_date = new Date(this.state.general_information.form_refund.dateEnd);
            let curr_date = new Date()
            if(curr_date > end_date)
                return(<></>)
        }

        return(
            <Grid item
                style={{...customStyles.glassBackgroundMain, marginTop: '3px'}}
            >
                <h3 style={{textAlign: 'left', marginTop: 0, marginBottom: 0}}>{translate('custom.refunds')}</h3>
                <Grid container justifyContent='center' style={{textAlign: 'center'}}>
                    <Grid item style={{verticalAlign: 'middle'}}>
                        {(this.state?.general_information?.twint?.disable_refund_by_twint) ? null : (
                            <p>{translate('custom.refund_using_form_message')}</p>
                        )}
                        <Link style={{textDecoration: 'none'}}
                            to={this.state.uid ? '/refund/'+this.state.uid+'?redirect_url='+window.location.pathname : '#'}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: config.baseColor,
                                    color :'white'
                                }}
                                startIcon={<LinkIcon />}
                            >
                                {translate('custom.form_refund_button')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    feedbackAndDonationPreferencesRender() {
        if(this.props.match.params.app)
            return(<></>)

        if(!this.state.general_information?.donation?.enabled)
            return(<></>)

        return(
            <Grid item
                style={{...customStyles.glassBackgroundMain, marginTop: '3px'}}
            >
                <h3 style={{textAlign: 'left', marginTop: 0, marginBottom: 0}}>{translate('custom.donation_parameter.feedback_and_donation_preferences')}</h3>
                <Grid container justifyContent='center' style={{textAlign: 'center'}}>
                    <Grid item style={{verticalAlign: 'middle'}}>
                        <p>{translate('custom.donation_parameter.feedback_and_donation_message')}</p>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: 'green',
                                color :'white'
                            }}
                            startIcon={<DonationIcon />}
                            size="large"
                            onClick={() => this.setState({donationModalOpen: true})}
                        >
                            {translate('custom.feedback_and_donation')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // ----------------- TRANSACTIONS -------------------------

    getType(tran) {
        if (tran.type == 'credit' && tran.payment_type == 'wristband') {
            return 'credit_wristband';
        }
        return tran.type;
    }

    getTransactionIcon(tran) {
        let type = this.getType(tran);
        return (ICONS[type].icon);
    }

    getTransactionTitle(tran) {
        let type = this.getType(tran);
        return (ICONS[type].text);
    }

    getTransactionDate(tran) {
        let datetime = tran.date;
        return datetime.split(" ").slice(1).join(" ");
    }

    getTransactionTime(tran) {
        let datetime = tran.date;
        return datetime.split(" ")[0];
    }

    getTransactionType(tran) {
        return (ICONS[tran.type].type);
    }

    getTransactionTypeIcon(tran) {

        if(ICONS[tran.type].type == "credited")
            return (
                <CreditedIcon
                    style={{color: 'white', fontSize: '15px', backgroundColor: ICONS[tran.type].color, padding: '2px', borderRadius: '3px'}}
                />
            )
        else if(ICONS[tran.type].type == "refunded")
            return (
                <RefundedIcon
                    style={{color: 'white', fontSize: '15px', backgroundColor: ICONS[tran.type].color, padding: '2px', borderRadius: '3px'}}
                />
            )
        else
            return (
                <DebitedIcon
                    style={{color: 'white', fontSize: '15px', backgroundColor: ICONS[tran.type].color, padding: '2px', borderRadius: '3px'}}
                />
            )
    }

    vouchersRender() {
        return (
            <div>
            
                {this.state?.vouchers?.length > 0 && 
                    <>
                        <h3 style={{marginBottom: '0'}}>{translate('custom.vouchers')} :</h3>

                        <div style={{overflowY: 'auto', whiteSpace: 'nowrap'}}>
                            {this.state.vouchers.map((voucher, idx) => {
                                let voucher_name_obj = this.state.vouchers_names.find((voc) => voc._id === voucher.voucher_id);
                                return (

                                    <span key={idx}>
                                        <Button
                                            variant="contained"
                                            style={{padding: '10px', backgroundColor: 'white', color: ICONS.voucher.color, marginTop: '10px', marginBottom: '10px', marginLeft: '5px', textTransform: 'none', fontSize: '20px', borderRight: '1px dashed darkgrey', borderColor: 'darkgray', borderTopRightRadius: '8px', borderBottomRightRadius: '8px'}}
                                            disabled
                                        >{voucher.quantity + ' x'}</Button>                                        
                                        <Button
                                            variant="contained"
                                            style={{padding: '10px', backgroundColor: 'white', color: 'black', marginTop: '10px', marginBottom: '10px', marginRight: '5px', textTransform: 'none', fontSize: '20px', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', minWidth: '150px'}}
                                            disabled
                                        >{voucher_name_obj.name}</Button>
                                    </span>

                                )
                            })}
                        </div>
                        
                    </>
                }
            
            </div>
        )
    }

    transactionOrdersRender(transaction) {
        
        if(transaction.card_transaction_id) {
            return (
                <div style={{backgroundColor: 'white', padding: '10px', margin: '20px', borderRadius: '10px'}}>
                    <div key={transaction.card_transaction_id}>
                        <ListItem>
                            <ListItemText
                                primary={<div style={{color: ICONS[transaction.type].color}}>Transaction</div>}
                            />
                            <ListItemSecondaryAction style={{marginRight: 10}}>
                                <ListItemText
                                    primary={<div style={{color: ICONS[transaction.type].color}}>{transaction.card_transaction_id}</div>}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </div>
                </div>
            )
        }

        let orders = transaction.order;
        if(!(orders))
            return null;

        orders.filter((ord) => ord.quantity ? ord : null);

        if(!orders || orders.length == 0)
            return null;

        let counter = 0;
        
        return (

            <div style={{backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', margin: '1px', borderRadius: '10px'}}>
                {orders.map((order) => {
                    if(!(order.quantity))
                        return null;

                    return (
                        <div key={order._id + '_' + counter++}
                            // border bottom except last row
                            style={counter == (orders.length - 1) ? null : {borderBottom: '1px dashed darkgray', marginTop: 2, marginBottom: 2}}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <p style={{color: "#08607e"}}>{order.quantity + " x " + order.name}</p>
                                    {/* {order.deposit ? <p style={{margin: 5, color: "gray", fontSize: '12px'}}>{translate('custom.with') + ' ' + order.quantity + ' x ' + order.deposit.name + ' ' + translate('custom.of') + ' ' + this.currency + ' ' + formatCurrency(order.deposit.price)}</p> : null} */}
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
                                    { order.voucher ? 
                                        <p style={{color: "#060", flex: 1, textAlign: 'right'}}>{order.voucher.name + " " + translate('custom.voucher')}</p> :
                                        <p style={{color: "#08607e", flex: 1, textAlign: 'right'}}>{formatCurrencySign(order.total * (transaction.type === config.transaction_types.CREDIT || transaction.type === config.transaction_types.UNPAID ? 1 : -1))}</p>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            </div>

        )
    }

    transactionsRender(isSmall) {
        
        let prevTranDate = null;

        return (
            <div>
                <h3 style={{marginBottom: '0'}}>{translate('custom.transactions')} :</h3>

                <List>
                    {this.state.transactions.map((transaction, idx) => {

                        let icon = this.getTransactionIcon(transaction);
                        let title = this.getTransactionTitle(transaction);
                        let secondary_title = null;
                        let tranType = transaction.type;
                        let amount = transaction.amount  * (tranType === config.transaction_types.CREDIT || tranType === config.transaction_types.UNPAID ? 1 : -1);
                        let order_number = transaction.order_number ? transaction.order_number : '';
                        let table_number = transaction.table_number ? transaction.table_number : '';
                        let type = this.getTransactionType(transaction);
                        let typeIcon = this.getTransactionTypeIcon(transaction);
                        let currTranDate = this.getTransactionDate(transaction);
                        let currTranDateDisplay = currTranDate;
                        let currTranTime = this.getTransactionTime(transaction);


                        if(tranType === config.transaction_types.ENTRANCE) {
                            secondary_title = transaction['order'][0]['name'];
                        } else if(tranType === config.transaction_types.ZONE) {
                            title = transaction['order'][0]['name']
                            // use the fields entrance begin date & entrance end date
                            secondary_title = transaction.begin_date ? new Date(transaction.begin_date).toLocaleString(translate('custom.OPTIONS.i18n_date_format'), {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}) : '';
                        } else if(tranType === config.transaction_types.CREDIT && (transaction.payment_type === config.payment_types.TWINT.value || transaction.payment_type === config.payment_types.CARD.value)) {
                            secondary_title = transaction.card_transaction_id;
                        } else if(tranType === config.transaction_types.CREDIT && transaction.payment_type === config.payment_types.TWINT_DIRECT.value) {
                            // only last 8 characters of twint_direct_merchant_tran_id
                            secondary_title = transaction.twint_direct_merchant_tran_id;
                        } else if(tranType === config.transaction_types.TWINT_REFUND) {
                            secondary_title = transaction?.card_transaction_id ? transaction.card_transaction_id : transaction?.twint_direct_merchant_tran_id ? transaction.twint_direct_merchant_tran_id : '';
                        } else if (tranType === config.transaction_types.CARD_REFUND) {
                            secondary_title = transaction?.card_transaction_id ? transaction.card_transaction_id : '';
                        }

                        if(prevTranDate != null && prevTranDate == currTranDate) {
                            currTranDateDisplay = '';
                            prevTranDate = currTranDate;
                        }

                        if(prevTranDate != null && prevTranDate != currTranDate) {
                            prevTranDate = currTranDate;
                        }

                        if(prevTranDate == null) {
                            prevTranDate = currTranDate;
                        }

                        return (
                            <div key={'transactionsRender-'+idx}>
                                {(currTranDateDisplay != '') && 
                                    (
                                        <ListItem style={isSmall ? {padding: 0} : {paddingTop: 0, paddingBottom: 0}}>
                                            <ListItemText>
                                                {(idx != 0) &&
                                                    <Divider style={{marginBottom: '10px', height: '2px'}} />
                                                }
                                                {currTranDateDisplay}
                                            </ListItemText>
                                        </ListItem>
                                    )
                                }
                                
                                
                                <ListItem style={isSmall ? {padding: 0, height: 60, marginTop: 10, marginBottom: 10} : {paddingTop: 0, paddingBottom: 0}}
                                    onClick={()=>{
                                        if(!canCollapse)
                                            return;
                                        var new_state = {};
                                        new_state[transaction._id] = !this.state[transaction._id];
                                        this.setState(new_state);
                                    }}
                                >

                                    <ListItemText style={isSmall ? {minWidth: '80%'} : {minWidth :'40%'}} >
                                        <Button
                                            disabled
                                            style={{color: 'black', textTransform: 'none'}}
                                        >
                                            {icon}
                                            <ListItemText
                                                style={{paddingLeft: '10px', textAlign: 'left'}}
                                                primary={<b>{title}</b>}
                                                secondary={
                                                    <>
                                                        {secondary_title ? (<Hidden smDown>{secondary_title}</Hidden>) : null }
                                                        <Hidden mdUp>{currTranTime} {secondary_title ? "- " + secondary_title : null}</Hidden>
                                                        <Hidden smDown>
                                                            <div>
                                                                {order_number ? translate('custom.order_number') + ' - ' + order_number : ''} {table_number ? ' [ ' + translate('custom.table_number') + ' - ' + table_number + ' ]' : ''}
                                                            </div>
                                                        </Hidden>
                                                        <Hidden mdUp>
                                                            <div>
                                                                {order_number ? translate('custom.order_number') + ' - ' + order_number : ''}
                                                            </div>
                                                        </Hidden>
                                                    </>
                                                }
                                            />
                                        </Button>
                                    </ListItemText>
                                    <Hidden smDown>
                                        <ListItemText style={{minWidth: '25%'}}>
                                            <Button
                                                disabled
                                                style={{color: 'black', textTransform: 'none'}}
                                            >
                                                {typeIcon}
                                                &nbsp;
                                                {translate('custom.'+type)}
                                            </Button>
                                        </ListItemText>
                                        <ListItemText style={{textAlign: 'right'}}>
                                            {currTranTime}
                                        </ListItemText>
                                    </Hidden>
                                    <ListItemText style={{textAlign: 'right', color: ICONS[transaction.type].color, width: '100%', paddingRight: '20px'}}>
                                        {formatCurrencySign(amount)}
                                    </ListItemText>
                                    <ListItemText style={{textAlign: 'right'}}>
                                        {canCollapse(transaction) ? this.state[transaction._id] ? <ExpandLess /> : <ExpandMore /> : <ExpandLess style={{visibility: 'hidden'}} />}
                                    </ListItemText>
                                </ListItem>
                                {canCollapse(transaction) ? 
                                    <Collapse in={this.state[transaction._id]} timeout="auto" unmountOnExit>
                                        {this.transactionOrdersRender(transaction)}
                                    </Collapse>
                                    : null
                                }
                                
                            </div>
                        )
                    })}
                </List>
            </div>
        )
        
    }

    // --------------- TRANSACTIONS ENDS --------------------

    // --------------- DONATION -----------------------------

    donationRender() {
        let donation = this.state?.general_information?.donation;
        if(!donation) {
            // this.setState({donationModalOpen: false});
            return null;
        }
        return (
            <>
                
                <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: donation.webapp_greeting_text }}></div>
                
                {/* Show two buttons */}
                <div style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                    <Button
                        variant={this.state.eventLiked == 'yes' ? "contained" : "outlined"}
                        color="primary"
                        style={{marginRight: '10px', textTransform: 'none'}}
                        onClick={()=>{
                            this.setState({eventLiked: 'yes'});
                        }}
                    >
                        {translate('custom.yes')}
                    </Button>
                    <Button
                        variant={this.state.eventLiked == 'no' ? "contained" : "outlined"}
                        color="primary"
                        style={{marginLeft: '10px', textTransform: 'none'}}
                        onClick={()=>{
                            this.setState({eventLiked: 'no'});
                        }}
                    >
                        {translate('custom.no')}
                    </Button>
                    <Button
                        variant={this.state.eventLiked == 'no_comments' ? "contained" : "outlined"}
                        color="primary"
                        style={{marginLeft: '10px', textTransform: 'none'}}
                        onClick={()=>{
                            this.setState({eventLiked: 'no_comments'});
                        }}
                    >
                        {translate('custom.i_prefer_not_to_say')}
                    </Button>
                </div>

                {(this.state.eventLiked == 'no' && donation.enable_suggestion_if_not_liked) ? (
                    // text area with placeholder
                    <div style={{textAlign: 'center', padding: '10px'}}>
                        <TextField
                            id="outlined-multiline-static"
                            label={donation.suggestion_input_label}
                            multiline
                            minRows={4}
                            variant="outlined"
                            style={{width: '100%'}}
                            onChange={(e)=>{
                                this.setState({eventNotLikedText: e.target.value});
                            }}
                        />
                    </div>
                ) : null}

                {/* If yes show donation.greeting_text_for_donation_if_event_liked */}
                {(this.state.eventLiked == 'yes') ? (
                    <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: donation.greeting_text_for_donation_if_event_liked }}></div>
                ) : null}

                {/* If no show donation.greeting_text_for_donation_if_event_not_liked */}
                {this.state.eventLiked == 'no' ? (
                    <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: donation.greeting_text_for_donation_if_event_not_liked }}></div>
                ) : null}

                {/* If no_comments show donation.greeting_text_for_donation_if_they_prefer_not_to_say */}
                {this.state.eventLiked == 'no_comments' ? (
                    <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: donation.greeting_text_for_donation_if_they_prefer_not_to_say }}></div>
                ) : null}


                {/* Show two buttons */}
                {this.state.eventLiked ? (
                    <div style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <Button
                            variant={this.state.donationChoice == 'yes' ? "contained" : "outlined"}
                            color="primary"
                            style={{marginRight: '10px', textTransform: 'none'}}
                            onClick={()=>{
                                this.setState({donationChoice: 'yes'});
                            }}
                        >
                            {translate('custom.yes')}
                        </Button>
                        <Button
                            variant={this.state.donationChoice == 'no' ? "contained" : "outlined"}
                            color="primary"
                            style={{marginLeft: '10px', textTransform: 'none'}}
                            onClick={()=>{
                                this.setState({donationChoice: 'no'});
                            }}
                        >
                            {translate('custom.no')}
                        </Button>
                    </div>
                ) : null}

                {/* If yes donation show two buttons to donate */}
                {this.state.donationChoice == 'yes' ? (
                    <>
                        {/* Show an text saying please choose an amount */}
                        <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}}>
                            {translate('custom.please_choose_an_amount')}
                        </div>
                        
                        <Grid container spacing={3} style={{padding: '10px', justifyContent: 'space-around'}}>
                            {(this.state.donation_amounts.map((amount, idx) => {
                                return (
                                    <Button
                                        key={idx}
                                        variant={this.state.donationAmount == amount ? "contained" : "outlined"}
                                        color="primary"
                                        style={{margin: '10px', textTransform: 'none'}}
                                        onClick={()=>{
                                            this.setState({donationAmount: amount, donationType: 'fixed'});
                                        }}
                                    >
                                        {this.state.general_information.currencyCode} {amount}
                                    </Button>
                                )
                            }))}

                            <Button
                                variant={this.state.donationType == 'custom' ? "contained" : "outlined"}
                                color="primary"
                                style={{margin: '10px', textTransform: 'none'}}
                                onClick={()=>{
                                    this.setState({donationType: 'custom', donationAmount: ''});
                                }}
                            >
                                {translate('custom.custom')}
                            </Button>


                            {(this.state.donationType == 'custom') ? (
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        label={translate('custom.donation_amount')}
                                        value={this.state.donationAmount}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            try {
                                                if(value != '')
                                                    value = parseFloat(value);
                                            } catch(e) {
                                                value = ''
                                            }
                                            this.setState({donationAmount: value})
                                        }}
                                        onWheel={(e) => {
                                            e.target.blur();
                                        }}
                                    />
                                </Grid>
                            ) : null}

                        </Grid>
                    </>
                ) : null}

                {/* If donationAmount or donationChoice is 'no', then show submut button */}
                {this.state.donationAmount || this.state.donationChoice == 'no' ? (

                    <>

                    {(this.state.donationChoice == "yes" && this.state.donationAmount) ? (
                        <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: donation.final_greeting_text_if_they_choose_to_donate }}></div>
                    ) : null}

                    {(this.state.donationChoice == 'no') ? (
                        <div style={{color: 'gray', textAlign: 'center', padding: '10px', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: donation.final_greeting_text_if_they_choose_not_to_donate }}></div>
                    ) : null}

                    <div style={{textAlign: 'center', padding: '10px'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{textTransform: 'none'}}
                            onClick={async ()=>{
                                try {
                                    // send PATCH request to server
                                    let feedbackRes = await request('/clients/short_info/' + this.state.uid, 'PATCH', null, {
                                        feedback: {
                                            eventLiked: this.state.eventLiked,
                                            eventNotLikedText: this.state.eventNotLikedText,
                                            donationChoice: this.state.donationChoice,
                                            donationType: this.state.donationType,
                                            donationAmount: this.state.donationAmount
                                        }
                                    });
                                    
                                    if (!feedbackRes.error) {
                                        // Show success message
                                        this.setState({donationModalOpen: false});
                                        Swal.fire({
                                            icon: 'success',
                                            title: translate('custom.thank_you'),
                                            showConfirmButton: false,
                                            timer: 1500
                                        }).then(() => {
                                            window.location.href = '/client/' + this.state.uid;
                                        });
                                    } else {
                                        // Show error message
                                        this.setState({donationModalOpen: false});
                                        Swal.fire({
                                            icon: 'error',
                                            title: translate('custom.unknown_error'),
                                            showConfirmButton: false,
                                            timer: 1500
                                        });
                                    }
                                } catch (e) {
                                    console.log(e)
                                    this.setState({donationModalOpen: false});
                                    Swal.fire({
                                        icon: 'error',
                                        title: translate('custom.unknown_error'),
                                        showConfirmButton: false,
                                        timer: 1500
                                    });
                                }
                            }}
                        >
                            {translate('custom.validate')}
                        </Button>
                    </div>

                    </>
                ) : null}
            
            </>
        )
    }

    getNewRender() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <MuiThemeProvider theme={theme}>
            <div>

                <div
                    style={{
                        backgroundImage: "linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(" + backgroundImage + ")",
                        height: '100vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        overflow: 'auto',
                        backgroundPosition: 'center center'
                    }}
                >

                    <Grid container alignItems='center' justifyContent="center" direction="column">
                        <Grid item xs={12} sm={12} md={8} lg={6}
                            style={customStyles.glassBackground}
                        >
                            <Grid item
                                style={customStyles.glassBackgroundMain}
                            >
                                {this.customizationRender()}
                                {this.braceletHeaderRender()}

                                {/* Toggle Notifications Switch */}
                                {(!this.event) ? (
                                    <Grid container justifyContent='flex-end' style={{textAlign: 'right'}}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <IOSSwitch
                                                        checked={this.state.notifications_enabled}
                                                        onChange={this.toggleNotificationsEnabled}
                                                        name="notifications"
                                                    />
                                                }
                                                label={translate('custom.notifications')}
                                            />
                                        </Grid>
                                        {(this.state.notifications_permission_denied) ? (
                                            <Grid item xs={12}>
                                                {/* Typograph in red color showing message please give notification permission to receive notifications about transaction */}
                                                <small style={{color: 'red'}}>
                                                    {translate('custom.please_give_notifications_permission_to_get_updates_on_transactions')}
                                                </small>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                ) : null}

                                {this.braceletDetailsRender()}

                                {(!this.event) ? this.addMoneyRender() : null}

                            </Grid>

                            {(!this.event) ? (
                                <>
                                    {this.refundRender()}
                                    {this.feedbackAndDonationPreferencesRender()}
                                </>
                            ) : null}

                            {this.vouchersRender()}
                            {this.transactionsRender(isSmall)}
                        </Grid>
                    </Grid>

                </div>

                <Modal
                    open={this.state.donationModalOpen}
                    // onClose={() => this.setState({donationModalOpen: false})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflowY: 'auto'}}
                >
                    <Card sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: 400,
                            width: '100%',
                            bgcolor: 'background.paper',
                            // border: '2px solid #000',
                            borderRadius: 20,
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <CardContent>

                            <div style={{textAlign: 'right'}}>
                                <IconButton
                                    style={{padding: 3}}
                                    onClick={() => this.setState({donationModalOpen: false})}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>

                            {this.donationRender()}
                        </CardContent>
                    </Card>
                </Modal>

            
            </div>
            </MuiThemeProvider>
        );
    }
}

const styles = theme => ({
});
export default withTheme(withStyles(styles)(Client));