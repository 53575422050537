import React from 'react';
import {
    SimpleForm,
    Toolbar,
    List,
    Datagrid,
    SimpleList,
    TextField,
    Pagination,
    Filter,
    TextInput,
    DateField,
    NumberField,
    useRefresh
} from 'react-admin';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import {translate} from "../i18n/customI18nProvider";
import Button  from '@material-ui/core/Button';
import config from "./../config";
import { convertMessageCodeToMessage, request } from "../util";
import Actions from '../components/reactAdmin/Actions';
import GetAppIcon from '@mui/icons-material/GetApp';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';
import { Grid } from '@material-ui/core';
import { getToken } from '../services/storage';

export const RefundIcon = CallMissedIcon;

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}
  
LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


const PostCreateToolbar = props => (
    <Toolbar {...props} style={{visibility: 'hidden', display: 'none'}} ></Toolbar>
);

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label={translate('custom.file_name')} source="file_name" alwaysOn variant='outlined' />
    </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const DownloadView = ({record}) => {
    if (record.type === 'form') {
        return (
            <GetAppIcon style={{'cursor': 'pointer'}}
                onClick={() => downloadFile(record)}
            />
        );
    }
    return null;
}

const RegenerateView = ({record}) => {
    if (record.type === 'form') {
        return (
            <AutorenewIcon style={{'cursor': 'pointer'}}
                onClick={() => regenerateFile(record)}
            />
        )
    }
    return null;
}

const StatusView = ({record}) => {
    if (record.status) {
        return (
            <Typography>
                {translate('custom.' + record.status)}
            </Typography>
        )
    }
    return null;
}

const CancelProcessView = ({record}) => {

    const refresh = useRefresh();

    if (record.status == config.refund_status.REFUNDING) {
        return (
            <CancelIcon style={{'cursor': 'pointer'}}
                onClick={async () => {
                    await cancelProcess(record)
                    refresh();
                }}
            />
        )
    }
    return null;
}


const DownloadXlsxView = ({record}) => {
    if (record.type === 'form') {
        return (
            <DescriptionIcon style={{'cursor': 'pointer'}}
                onClick={() => downloadXlsxFile(record)}
            />
        )
    }
    return null;
}


function getRequestOptions(method) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    headers['x-access-token'] = getToken();

    let options = {
        method: method,
        headers
    };

    return options;
}

async function downloadFile(record) {
    let response = await fetch(config.apiUrl + '/refunds/' + record.file_name, getRequestOptions('GET'));;
            
    if(response.status !== 200) {
        let responseJson = await response.json();
        alert(responseJson.error);
        return;
    }

    try {
        downloadFileFromResponse(response, record.file_name);
    } catch(e) {
        console.log("Error", e);
        alert(translate('custom.download_failed'));
    }
}

async function regenerateFile(record) {
    let response = await fetch(config.apiUrl + '/refunds/form/' + record.file_name + '/regenerate', getRequestOptions('GET'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        alert(responseJson.error);
        return;
    }

    try {
        downloadFileFromResponse(response, record.file_name);
    } catch(e) {
        console.log("Error", e);
        alert(translate('custom.download_failed'));
    }
}

async function cancelProcess(record) {
    const c = await Swal.fire({
        title: translate('custom.cancel_refund'),
        text: translate('custom.do_you_want_to_cancel_refund'),
        showCancelButton: true,
        confirmButtonColor: config.baseColor,
        didOpen: () => {
            Swal.hideLoading()
        }
    })
    if(!c.isConfirmed)
        return;

    let response = await fetch(config.apiUrl + '/refunds/' + record._id + '/cancel', getRequestOptions('POST'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        alert(responseJson.error);
        return;
    }
}

async function downloadXlsxFile(record) {
    let response = await fetch(config.apiUrl + '/refunds/' + record.file_name + '/xlsx', getRequestOptions('GET'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        alert(responseJson.error);
        return;
    }

    try {
        // change extension of record.file_name to .xlsx
        record.file_name = record.file_name.split('.').slice(0, -1).join('.') + '.xlsx';
        downloadFileFromResponse(response, record.file_name);
    } catch(e) {
        console.log("Error", e);
        alert(translate('custom.download_failed'));
    }
}

async function downloadFileFromResponse(response, file_name=null) {
    const blob = await response.blob();
    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    if(!file_name)
        link.setAttribute('download', translate('custom.payment_file') + '.xml');
    else
        link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blob);
}


class RefundsListClass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form_button_message: translate('custom.start_form_refund'),
            twint_button_message: translate('custom.start_twint_refund'),
            loading: false,
            show_progress: false,
            progress: 0,
            refund_info: {
                total_form_refund_clients: null,
                total_twint_transactions: null
            },
            general_information: null
        };

        this.isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            this.isSmall = true;
        }

        this.startFormRefund = this.startFormRefund.bind(this);
        this.startTwintRefund = this.startTwintRefund.bind(this);

    }


    async componentDidMount() {
        this.updateRefundInfo();

        let general_information = await request('/events/general', 'GET', null);
        if (general_information.error) {
            alert(general_information.error);
            return;
        }
        this.setState({
            general_information: general_information
        });
    }

    async updateRefundInfo() {
        let refundInfo = await fetch(config.apiUrl + '/clients/refund', getRequestOptions('GET'));
        if(refundInfo.status === 200) {
            let refundInfoJson = await refundInfo.json();
            this.setState({
                refund_info: refundInfoJson.data
            });
        }
    }

    sendDonationNotifications = async () => {

        const c = await Swal.fire({
            title: translate('custom.are_you_sure'),
            text: translate('custom.do_you_want_to_send_donation_notifications'),
            showCancelButton: true,
            confirmButtonColor: config.baseColor,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
        if(!c.isConfirmed)
            return;

        try {

            const inProgressSwal = Swal.fire({
                title: translate('custom.processing'),
                allowOutsideClick: false,
                showCancelButton: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            this.setState({
                loading: true
            });

            // get current domain
            let redirectBaseUrl = window.location.protocol + "//" + window.location.host + "/";
            let payload = {
                redirectBaseUrl
            }
            let requestOptions = getRequestOptions('POST');
            requestOptions.body = JSON.stringify(payload);
            let response = await fetch(config.apiUrl + '/notifications/donation', requestOptions);

            // close the in progress swal
            inProgressSwal.close();

            if(response.status !== 200) {
                let responseJson = await response.json();
                // alert(responseJson.error);
                Swal.fire({
                    title: responseJson.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
                this.setState({
                    loading: false
                });
                return;
            }

            Swal.fire({
                title: translate('custom.notifications_sent'),
                icon: 'success',
                confirmButtonColor: config.baseColor,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            this.setState({
                loading: false
            });
        } catch(e) {
            // alert(translate('custom.unknown_error'));
            Swal.fire({
                title: translate('custom.unknown_error'),
                icon: 'error',
                confirmButtonColor: config.baseColor,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            this.setState({
                loading: false
            });
        }
    }
            

    async startFormRefund() {

        const c = await Swal.fire({
            title: translate('custom.form_refund'),
            text: translate('custom.do_you_want_to_start_form_refund'),
            showCancelButton: true,
            confirmButtonColor: config.baseColor,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
        if(!c.isConfirmed)
            return;

        try {

            const inProgressSwal = Swal.fire({
                title: translate('custom.processing'),
                allowOutsideClick: false,
                showCancelButton: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            this.setState({
                loading: true,
                form_button_message: translate('custom.processing')
            });
            let beforeunloadListener = (ev) => {
                ev.preventDefault();
                return ev.returnValue = translate('custom.its_still_processing_are_you_sure_you_want_to_close');
            }
            window.addEventListener("beforeunload", beforeunloadListener);
    
            let response = await fetch(config.apiUrl + '/refunds/form', getRequestOptions('POST'));
            
            if(response.status !== 200) {
                let responseJson = await response.json();
                // alert(responseJson.error);
                Swal.fire({
                    title: responseJson.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
                this.setState({
                    loading: false,
                    form_button_message: translate('custom.start_form_refund')
                });
                window.removeEventListener("beforeunload", beforeunloadListener);
                this.updateRefundInfo();
                return;
            }

            try {

                await downloadFileFromResponse(response);

                Swal.fire({
                    title: translate('custom.download_successful'),
                    icon: 'success',
                    confirmButtonColor: config.baseColor,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
                this.setState({
                    loading: false,
                    form_button_message: translate('custom.start_form_refund')
                });
                window.removeEventListener("beforeunload", beforeunloadListener);
                this.props.refresh();

            } catch(e) {
                console.log("Error", e);
                // alert(translate('custom.download_failed'));
                Swal.fire({
                    title: translate('custom.download_failed'),
                    icon: 'error',
                    confirmButtonColor: config.baseColor,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
                this.setState({
                    loading: false,
                    form_button_message: translate('custom.start_form_refund')
                });
                window.removeEventListener("beforeunload", beforeunloadListener);
                this.updateRefundInfo();
            }
        } catch(e) {
            // alert(translate('custom.unknown_error'));
            Swal.fire({
                title: translate('custom.unknown_error'),
                icon: 'error',
                confirmButtonColor: config.baseColor,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            this.setState({
                loading: false,
                form_button_message: translate('custom.start_form_refund')
            });
            window.removeEventListener("beforeunload", beforeunloadListener);
            this.updateRefundInfo();
        }

        
    };


    async startTwintRefund() {


        const c = await Swal.fire({
            title: translate('custom.twint_refund'),
            text: translate('custom.do_you_want_to_start_twint_refund'),
            showCancelButton: true,
            confirmButtonColor: config.baseColor,
            didOpen: () => {
                Swal.hideLoading()
            }
        })

        if(!c.isConfirmed)
            return;

        try {

            const inProgressSwal = Swal.fire({
                title: translate('custom.processing'),
                text: this.state.progress + ' %',
                allowOutsideClick: false,
                showCancelButton: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            this.setState({
                loading: true,
                twint_button_message: translate('custom.processing'),
                show_progress: true
            });
            let beforeunloadListener = (ev) => {
                ev.preventDefault();
                return ev.returnValue = translate('custom.its_still_processing_are_you_sure_you_want_to_close');
            }
            window.addEventListener("beforeunload", beforeunloadListener);

            let refund;

            refund = await request('/clients/refund', 'POST', null);
            if (refund.error) {
                Swal.fire({
                    title: refund.error_code ? convertMessageCodeToMessage(refund.error_code) : refund.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
                
                this.setState({
                    loading: false,
                    twint_button_message: translate('custom.start_twint_refund'),
                    show_progress: false,
                    progress: 0
                });
                window.removeEventListener("beforeunload", beforeunloadListener);
                this.updateRefundInfo();
                return;
            }

            Swal.fire({
                title: translate('custom.refunding_in_background'),
                icon: 'success',
                confirmButtonColor: config.baseColor,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });

            this.props.refresh();

            this.setState({
                loading: false,
                twint_button_message: translate('custom.start_twint_refund'),
                show_progress: false,
                progress: 0
            });
            window.removeEventListener("beforeunload", beforeunloadListener);

            this.updateRefundInfo();

        } catch(e) {
            // alert(translate('custom.unknown_error'));
            Swal.fire({
                title: translate('custom.unknown_error'),
                icon: 'error',
                confirmButtonColor: config.baseColor,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            this.setState({
                loading: false,
                twint_button_message: translate('custom.start_twint_refund'),
                show_progress: false,
                progress: 0
            });
            window.removeEventListener("beforeunload", beforeunloadListener);
            this.updateRefundInfo();
        }

    };


    render() {
        return (
            <>
                <Grid container spacing={1} style={{marginTop: 10}}>
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            style={(this.state.loading || !this.state?.general_information?.donation?.enabled) ? {backgroundColor: 'gray', color: 'white', margin: 10} : {backgroundColor: config.baseColor, color: 'white', margin: 10}}
                            disabled={this.state.loading || !this.state?.general_information?.donation?.enabled}
                            onClick={this.sendDonationNotifications}
                        >
                            {translate('custom.send_donation_notifications')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            style={this.state.loading ? {backgroundColor: 'gray', color: 'white', margin: 10, marginBottom: 0} : {backgroundColor: '#ff4747', color: 'white', margin: 10, marginBottom: 0}}
                            disabled={this.state.loading}
                            onClick={this.startFormRefund}
                        >
                            {this.state.form_button_message}
                        </Button>
                        <p>
                            <small style={{color: 'gray'}}>
                                {(this.state.refund_info.total_form_refund_clients === null) ? (translate('custom.calculating')) : (this.state.refund_info.total_form_refund_clients) ? (this.state.refund_info.total_form_refund_clients + ' ' + translate('custom.clients')) : translate('custom.no_clients_to_refund')}
                            </small>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            style={this.state.loading ? {backgroundColor: 'gray', color: 'white', margin: 10, marginBottom: 0} : {backgroundColor: '#ff4747', color: 'white', margin: 10, marginBottom: 0}}
                            disabled={this.state.loading}
                            onClick={this.startTwintRefund}
                        >
                            {this.state.twint_button_message}
                        </Button>
                        <p>
                            <small style={{color: 'gray'}}>
                                {(this.state.refund_info.total_twint_transactions === null) ? (translate('custom.calculating')) : (this.state.refund_info.total_twint_transactions) ? (this.state.refund_info.total_twint_transactions + ' ' + translate('custom.transactions')) : translate('custom.no_transactions_to_refund')}
                            </small>
                        </p>
                    </Grid>
                </Grid>

                {this.state.show_progress &&
                    <LinearProgressWithLabel value={this.state.progress} />
                }


                <List {...this.props} hasCreate={false} bulkActionButtons={false} actions={<Actions />} filters={<PostFilter />} pagination={<PostPagination />} perPage={50} >
                    {this.isSmall ? (
                        // <SimpleList
                        //     primaryText={record => record.file_name ? record.file_name : record.id }
                        // />
                        <Datagrid>
                            <TextField label={translate('custom.type')} source="type" />
                            <TextField label={translate('custom.file_name')} source="file_name" />
                            <NumberField label={translate('custom.progress')} source="progress" />
                            <StatusView label={translate('custom.status')} />
                            <CancelProcessView />
                            <RegenerateView />
                            <DownloadView />
                            <DownloadXlsxView />
                        </Datagrid>
                    ) : (
                        <Datagrid>
                            <TextField label={translate('custom.type')} source="type" />
                            <TextField label={translate('custom.file_name')} source="file_name" />
                            <DateField label={translate('custom.creation_date')} source="creation_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                            <NumberField label={translate('custom.progress')} source="progress" />
                            <StatusView label={translate('custom.status')} />
                            <CancelProcessView />
                            <RegenerateView />
                            <DownloadView />
                            <DownloadXlsxView />
                        </Datagrid>
                    )}
                </List>

            </>
        );
    }

};

// Warp & export refunds list class
export const RefundsList = (props) => {

    const refresh = useRefresh();

    return <RefundsListClass refresh={refresh} {...props} />;
}