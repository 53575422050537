import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    SimpleList,
    Filter,
    Pagination,
    ChipField,
    useRecordContext,
    ReferenceField,
    Show,
    SimpleShowLayout,
    ShowButton,
    NumberField,
    TabbedForm,
    FormTab
} from 'react-admin';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Actions from '../components/reactAdmin/Actions';
import { Button, IconButton, useMediaQuery } from '@material-ui/core';
import { translate } from '../i18n/customI18nProvider';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Grid from '@material-ui/core/Grid';
import config from '../config';
import { convertMessageCodeToMessage, downloadFileFromResponse, getRequestOptions } from '../util';
import Swal from 'sweetalert2';
import SmartphoneTransactionsTable from '../components/smartphones/TransactionsTable';
import SmartphoneEntranceChecksTable from '../components/smartphones/EntranceChecksTable';

export const SmartphoneIcon = PhoneAndroidIcon;

const SmartphonePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const LastKnownLocation = () => {
    
    const record = useRecordContext();

    return (
        <>

            <IconButton
                onClick={() => {
                    window.open(`https://maps.google.com/?q=${record.gps_lat},${record.gps_long}`, '_blank');
                }}
                disabled={!record || !record.gps_lat || !record.gps_long}
            >
                <PinDropIcon />
            </IconButton>
        </>
    )
}

const downloadSmartphonesData = async () => {
    let response = await fetch(config.apiUrl + '/smartphones/transactions/export', getRequestOptions('GET'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        Swal.fire({
            title: translate('custom.error'),
            text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
            icon: 'error',
            confirmButtonText: translate('custom.ok'),
            confirmButtonColor: config.baseColor
        });
        return;
    } else {
        await downloadFileFromResponse(response, translate('custom.smartphones') + '.xlsx', false);
    }
}

const SmartphonesFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="smartphone_number" variant='outlined' alwaysOn />
    </Filter>
);

export const SmartphonesList = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <>

            <div>
                <Grid xs={12} style={{textAlign: 'right'}}>
                    <Button
                        onClick={() => {
                            downloadSmartphonesData()
                        }}
                        variant="outlined"
                        style={{borderColor: config.baseColor, color: config.baseColor}}
                    >
                        {translate('custom.export')}
                    </Button>
                </Grid>
            </div>

            <List
                {...props}
                actions={<Actions />}
                filters={<SmartphonesFilter />}
                pagination={<SmartphonePagination />}
                perPage={50}
                bulkActionButtons={false}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={(record) => {
                            return record.smartphone_number
                        }}
                        linkType="show"
                    />
                ) : (
                    <Datagrid hasBulkActions={false}>
                        <TextField label={translate('custom.smartphone')} source="smartphone_number" />
                        <TextField label={translate('custom.date')} source="date" />
                        <ReferenceField label={translate('custom.user')} reference="users" source="user_id" sortable={false} >
                            <ChipField source="email" />
                        </ReferenceField>
                        <ReferenceField label={translate('custom.point_of_sale')} reference="bars" source="bar_id" sortable={false} >
                            <ChipField source="name" />
                        </ReferenceField>
                        <NumberField label={translate('custom.total_transaction_amount')} source="total_transaction_amount" locales={translate('custom.OPTIONS.i18n_number_format')} options={{ style: 'decimal', minimumFractionDigits: 2 }} />
                        <LastKnownLocation label={translate('custom.last_known_location')} />
                        <ShowButton />
                    </Datagrid>
                )}
            </List>

        </>
    );
}

export class SmartphoneShow extends React.Component {

    render() {
        return (
            <Show {...this.props}>
                <SimpleShowLayout>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <p style={{textAlign: 'center'}}>
                                <h3>{translate('custom.smartphone')}</h3>
                                <br />
                                <TextField label={translate('custom.smartphone')} source="smartphone_number" />
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <p style={{textAlign: 'center'}}>
                                <h3>{translate('custom.date')}</h3>
                                <br />
                                <TextField label={translate('custom.date')} source="date" />
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <p style={{textAlign: 'center'}}>
                                <h3>{translate('custom.user')}</h3>
                                <br />
                                <ReferenceField label={translate('custom.user')} reference="users" source="user_id" sortable={false} >
                                    <ChipField source="email" />
                                </ReferenceField>
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <p style={{textAlign: 'center'}}>
                                <h3>{translate('custom.point_of_sale')}</h3>
                                <br />
                                <ReferenceField label={translate('custom.point_of_sale')} reference="bars" source="bar_id" sortable={false} >
                                    <ChipField source="name" />
                                </ReferenceField>
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <p style={{textAlign: 'center'}}>
                                <h3>{translate('custom.total_transaction_amount')}</h3>
                                <br />
                                <TextField label={translate('custom.total_transaction_amount')} source="total_transaction_amount" />
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <p style={{textAlign: 'center'}}>
                                <h3>{translate('custom.last_known_location')}</h3>
                                <br />
                                <LastKnownLocation label={translate('custom.last_known_location')} />
                            </p>
                        </Grid>
                    </Grid>

                    <TabbedForm variant="standard">
                        <FormTab label={translate('custom.transactions')}>
                            <SmartphoneTransactionsTable />
                        </FormTab>

                        <FormTab label={translate('custom.entrance_checks')}>
                            <SmartphoneEntranceChecksTable />
                        </FormTab>
                    </TabbedForm>


                </SimpleShowLayout>
            </Show>
        );
    }

}