import react from 'react';
import V2Layout from './Layout';
import { Box, Card, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import StyledTableRow from '../../components/v2/common/table/StyledTableRow';
import { translate } from '../../i18n/customI18nProvider';
import { deleteDevice, getDeviceList, getDeviceLogs } from '../../services/device.service';
import { visuallyHidden } from '@mui/utils';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import config from '../../config';
import NoLayout from './NoLayout';
import moment from 'moment/moment';

const tableHeader = [
    {
        id: 'event',
        key: 'event',
        align: 'left',
        disablePadding: false,
        label: translate('custom.event'),
        sortable: true
    },
    {
        id: 'device_number',
        key: 'device_number',
        align: 'left',
        disablePadding: false,
        label: translate('custom.device_number'),
        sortable: true
    },
    {
        id: 'user',
        key: 'user',
        align: 'left',
        disablePadding: false,
        label: translate('custom.user'),
        sortable: true
    },
    {
        id: 'bar',
        key: 'bar',
        align: 'left',
        disablePadding: false,
        label: translate('custom.bar'),
        sortable: true
    },
    {
        id: 'where',
        key: 'where',
        align: 'left',
        disablePadding: false,
        label: translate('custom.where'),
        sortable: true
    },
    {
        id: 'added_on',
        key: 'added_on',
        align: 'left',
        disablePadding: false,
        label: translate('custom.added_on'),
        sortable: true
    },
    {
        id: 'removed_on',
        key: 'removed_on',
        align: 'left',
        disablePadding: false,
        label: translate('custom.removed_on'),
        sortable: true
    }
]

class DeviceLogs extends react.Component {

    constructor(props) {
        super(props);

        this.state = {
            device_logs: [],
            count: 0,
            skip: 0,
            limit: 50,
            limitOptions: [10, 50, 100, 250, 500],
            sort_key: '',
            sort_order: '',
            search: '',
            refresh_counter: props.refresh_counter ? props.refresh_counter : 0
        };
    }

    async componentDidMount() {
        this.getDeviceLogs();
    }

    async componentDidUpdate(prevProps) {
        if ((prevProps.event_id !== this.props.event_id) || (prevProps.device_number !== this.props.device_number) || (prevProps.refresh_counter !== this.props.refresh_counter)) {
            await this.setState({
                skip: 0
            });
            this.getDeviceLogs();
        }
    }

    async getDeviceLogs() {
        let options = {
            skip: this.state.skip,
            limit: this.state.limit
        }

        if (this.props.event_id) {
            options.event = this.props.event_id;
        }

        if (this.props.device_type) {
            options.device_type = this.props.device_type;
        }

        if (this.props.device_number) {
            options.device_number = this.props.device_number;
        }
        
        if (this.state.sort_key) {
            options.sort_key = this.state.sort_key;
            options.sort_order = this.state.sort_order;
        }

        if (this.state.search) {
            options.search = this.state.search;
        }

        try {
            let device_logs = await getDeviceLogs(options);
            this.setState({
                device_logs: device_logs.device_logs,
                count: device_logs.count
            });
        } catch (error) {
            this.setState({
                device_logs: [],
                count: 0
            });
        }
    }

    async deleteDeviceFromId(id) {
        let c = await Swal.fire({
            title: translate('custom.are_you_sure'),
            text: translate('custom.do_you_want_to_delete_this_device'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: translate('custom.yes'),
            cancelButtonText: translate('custom.no'),
            confirmButtonColor: 'red'
        });
        if (c.isConfirmed) {
            let response = await deleteDevice(id);
            if (response.error) {
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor
                });
                return;
            }

            Swal.fire({
                title: translate('custom.success'),
                text: response.message_code ? convertMessageCodeToMessage(response.message_code) : response.message,
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false
            });

            this.getDevices();
        }
    }

    render() {
        return (
            <NoLayout>

                <TableContainer component={Card} sx={{ mt: 3 }}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((headCell) => {

                                    if (this.props.hide_columns && this.props.hide_columns.includes(headCell.key)) {
                                        return null;
                                    }

                                    return (
                                        <TableCell
                                            key={headCell.key}
                                            align={headCell.align ? headCell.align : 'left'}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sortDirection={(this.state.sort_key === headCell.key) ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : false}
                                        >
                                            {(headCell.sortable) ? (
                                                <TableSortLabel
                                                    active={this.state.sort_key === headCell.key}
                                                    direction={this.state.sort_key === headCell.key ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : 'asc'}
                                                    onClick={async () => {
                                                        await this.setState({
                                                            sort_key: headCell.key,
                                                            sort_order: this.state.sort_key === headCell.key ? (this.state.sort_order === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
                                                        })
                                                        this.getDeviceLogs();
                                                    }}
                                                >
                                                    {headCell.label}
                                                    {this.state.sort_key === headCell.key ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {this.state.sort_order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            ) : (
                                                <>
                                                    {headCell.label}
                                                </>
                                            )}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.device_logs.map((row) => (
                                <StyledTableRow key={row._id}>
                                    {(this.props.hide_columns && this.props.hide_columns.includes('event')) ? null : (
                                        <TableCell>{row?.event?.name}</TableCell>
                                    )}
                                    {(this.props.hide_columns && this.props.hide_columns.includes('device_number')) ? null : (
                                        <TableCell>{row.device_number}</TableCell>
                                    )}
                                    <TableCell>{row?.user?.email}</TableCell>
                                    <TableCell>{row?.bar?.name}</TableCell>
                                    <TableCell>{row.where}</TableCell>
                                    <TableCell>{row.added_on ? moment(row.added_on).format(translate('custom.OPTIONS.date_time_format')) : ''}</TableCell>
                                    <TableCell>{row.removed_on ? moment(row.removed_on).format(translate('custom.OPTIONS.date_time_format')) : ''}</TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12}> 
                        <TablePagination
                            component="div"
                            count={this.state.count}
                            page={this.state.skip / this.state.limit}
                            onPageChange={async (event, page) => {
                                await this.setState({
                                    skip: page * this.state.limit
                                })
                                this.getDeviceLogs();
                            }}
                            rowsPerPage={this.state.limit}
                            onRowsPerPageChange={async (event) => {
                                await this.setState({
                                    limit: event.target.value
                                })
                                this.getDeviceLogs();
                            }}
                            rowsPerPageOptions={this.state.limitOptions}
                        />
                    </Grid>
                </Grid>
            </NoLayout>
        )
    }
}

export default DeviceLogs;
