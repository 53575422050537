// File Created By MJ

import React, {Component} from 'react';
import ActionBar from '@mui/icons-material/LocalBar';
import { MenuItemLink, Menu } from 'react-admin';
// import { MenuItemLink, getResources } from 'react-admin';  // commented by MJ
import config from "../config";
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getRight, getToken } from '../services/storage';

class BarListSidebarView extends Component {

    constructor() {
        super();

        this.state = {
            token: getToken(),
            right: getRight()
        };

    }

    render() {

        let items = [];
        if(this.state.token && (this.state.right  >= config.permissions.ADMIN.value))
            items = this.props.items ? this.props.items : [];

        let pathname = window.location.pathname;
        const pname = pathname ? pathname.split("/") : null;

        let dateBegin = null;
        let dateEnd = null;
        
        if(pname.length === 4) {
            dateBegin = pname[2];
            dateEnd = pname[3];
        } else if(pname.length === 5) {
            dateBegin = pname[3];
            dateEnd = pname[4];
        }

        let itemsView = items.map((item) =>
            item ?
                // <MenuItemLink
                //     button
                //     key={item.name}
                //     to={ (dateBegin && dateEnd) ? (item.link + '/' + dateBegin + '/' + dateEnd) : (item.link) }
                //     leftIcon={<ActionBar />}
                //     primaryText={item.name}
                // >
                // </MenuItemLink> : null
                <ListItem
                    button
                    component={Link}
                    key={item.name}
                    to={ (dateBegin && dateEnd) ? (item.link + '/' + dateBegin + '/' + dateEnd) : (item.link) }
                >
                    <ListItemIcon>
                        <ActionBar />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem> : null
        );

        return (
            <>
                {itemsView}
            </>
        );
    }
}

export default BarListSidebarView;