import react from 'react';
import V2Layout from './Layout';
import { Box, Card, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import StyledTableRow from '../../components/v2/common/table/StyledTableRow';
import { translate } from '../../i18n/customI18nProvider';
import { deleteDevice, getDeviceList } from '../../services/device.service';
import { visuallyHidden } from '@mui/utils';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import config from '../../config';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

const tableHeader = [
    {
        id: 'type',
        key: 'type',
        align: 'left',
        disablePadding: false,
        label: translate('custom.type'),
        sortable: true
    },
    {
        id: 'number',
        key: 'number',
        align: 'left',
        disablePadding: false,
        label: translate('custom.number'),
        sortable: true
    },
    {
        id: 'app_version',
        key: 'app_version',
        align: 'left',
        disablePadding: false,
        label: translate('custom.app_version'),
        sortable: true
    },
    {
        id: 'updated',
        key: 'updated',
        align: 'left',
        disablePadding: false,
        label: translate('custom.updated'),
        sortable: true
    },
    {
        id: 'actions',
        key: 'actions',
        align: 'left',  
        disablePadding: false,
        label: translate('custom.actions'),
        sortable: false
    }
]

class Devices extends react.Component {

    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            count: 0,
            skip: 0,
            limit: 50,
            limitOptions: [10, 50, 100, 250, 500],
            sort_key: '',
            sort_order: '',
            search: ''
        };
    }

    async componentDidMount() {
        this.getDevices();
    }

    async getDevices() {
        let options = {
            skip: this.state.skip,
            limit: this.state.limit
        }
        
        if (this.state.sort_key) {
            options.sort_key = this.state.sort_key;
            options.sort_order = this.state.sort_order;
        }

        if (this.state.search) {
            options.search = this.state.search;
        }

        let devices = await getDeviceList(options);
        this.setState({
            devices: devices.devices,
            count: devices.count
        });
    }

    async deleteDeviceFromId(id) {
        let c = await Swal.fire({
            title: translate('custom.are_you_sure'),
            text: translate('custom.do_you_want_to_delete_this_device'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: translate('custom.yes'),
            cancelButtonText: translate('custom.no'),
            confirmButtonColor: 'red'
        });
        if (c.isConfirmed) {
            let response = await deleteDevice(id);
            if (response.error) {
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor
                });
                return;
            }

            Swal.fire({
                title: translate('custom.success'),
                text: response.message_code ? convertMessageCodeToMessage(response.message_code) : response.message,
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false
            });

            this.getDevices();
        }
    }

    render() {
        return (
            <V2Layout
                currentMenu='devices'
            >

                <Grid container style={{marginBottom: '10px'}}>
                    <Card style={{width: '100%', padding: '20px'}}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="search-input">{translate('custom.search')}</InputLabel>
                                <OutlinedInput
                                    id="search-input"
                                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                    label={translate('custom.search')}
                                    value={this.state.search}
                                    onChange={async (e) => {
                                        await this.setState({search: e.target.value});
                                        this.getDevices();
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Card>
                </Grid>


                <TableContainer component={Card} style={{maxHeight: '80vh', overflow: 'auto'}}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((headCell) => (
                                    <TableCell
                                        key={headCell.key}
                                        align={headCell.align ? headCell.align : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={(this.state.sort_key === headCell.key) ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : false}
                                    >
                                        {(headCell.sortable) ? (
                                            <TableSortLabel
                                                active={this.state.sort_key === headCell.key}
                                                direction={this.state.sort_key === headCell.key ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : 'asc'}
                                                onClick={async () => {
                                                    await this.setState({
                                                        sort_key: headCell.key,
                                                        sort_order: this.state.sort_key === headCell.key ? (this.state.sort_order === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
                                                    })
                                                    this.getDevices();
                                                }}
                                            >
                                                {headCell.label}
                                                {this.state.sort_key === headCell.key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {this.state.sort_order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            <>
                                                {headCell.label}
                                            </>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.devices.map((row) => (
                                <StyledTableRow key={row._id} hover
                                    onDoubleClick={() => {
                                        this.props.history.push('/v2/admin/devices/' + row._id + '/view');
                                    }}
                                >
                                    <TableCell>{translate('custom.' + row.type)}</TableCell>
                                    <TableCell>{row.number}</TableCell>
                                    <TableCell>{row.app_version}</TableCell>
                                    <TableCell>{row.updated}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            size='small'
                                            onClick={() => {
                                                this.props.history.push('/v2/admin/devices/' + row._id + '/view');
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12}> 
                        <TablePagination
                            component="div"
                            count={this.state.count}
                            page={this.state.skip / this.state.limit}
                            onPageChange={async (event, page) => {
                                await this.setState({
                                    skip: page * this.state.limit
                                })
                                this.getDevices();
                            }}
                            rowsPerPage={this.state.limit}
                            onRowsPerPageChange={async (event) => {
                                await this.setState({
                                    limit: event.target.value
                                })
                                this.getDevices();
                            }}
                            rowsPerPageOptions={this.state.limitOptions}
                        />
                    </Grid>
                </Grid>
            </V2Layout>
        )
    }
}

export default Devices;
