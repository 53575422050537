import React from 'react';
import { Button } from 'react-admin';
import { translate } from '../../i18n/customI18nProvider';
import { convertMessageCodeToMessage, downloadFileFromResponse, request } from '../../util';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../../config';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getToken } from '../../services/storage';


function getRequestOptions(method) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    headers['x-access-token'] = getToken();

    let options = {
        method: method,
        headers
    };

    return options;
}
class DetailedConsumptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() {

        let barId = this.props?.barId;

        return (
            <div style={{textAlign: 'right', margin: 10}}>
                <Button
                    style={{width: '100%'}}
                    label={this.state.loading ? translate('custom.processing') : translate('custom.download_detailed_consumption_statistics')}
                    variant='outlined'
                    onClick={async () => {
                        this.setState({loading: true});
                        let response;
                        try {
                            if(barId)
                                response = await fetch(config.apiUrl + `/statistics/consumptions/detailed?bar_id=${barId}`, getRequestOptions('GET'));
                            else
                                response = await fetch(config.apiUrl + '/statistics/consumptions/detailed', getRequestOptions('GET'));
                            
                            if(response.status !== 200) {
                                let responseJson = await response.json();
                                Swal.fire({
                                    title: translate('custom.error'),
                                    text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
                                    icon: 'error',
                                    confirmButtonText: translate('custom.ok'),
                                    confirmButtonColor: config.baseColor
                                });
                                this.setState({loading: false});
                                return;
                            } else {
                                await downloadFileFromResponse(response, translate('custom.transactions') + '.xlsx', false);
                                this.setState({loading: false});
                            }
                        } catch (error) {
                            Swal.fire({
                                title: translate('custom.error'),
                                text: error.message,
                                icon: 'error',
                                confirmButtonText: translate('custom.ok'),
                                confirmButtonColor: config.baseColor
                            });
                            this.setState({loading: false});
                            return;
                        }
                    }}
                    startIcon={<GetAppIcon />}
                    disabled={this.state.loading}
                />
            </div>
        );
    }
}

export default DetailedConsumptions;