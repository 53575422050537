import React, { useState } from 'react';
import {
    NumberInput,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    Pagination,
    required,
    SimpleList,
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    TopToolbar,
    useRefresh,
    FunctionField,
    FormDataConsumer,
    SelectArrayInput,
    BooleanInput,
    // useNotify
} from 'react-admin';
import RefreshIcon from '@mui/icons-material/Refresh';
import TollIcon from '@mui/icons-material/Toll';
import DeleteIcon from '@mui/icons-material/Delete';
import { request, getChoices, convertMessageCodeToMessage } from "../util";
import {translate, locale} from "../i18n/customI18nProvider";
import config from '../config';
import Button  from '@material-ui/core/Button';

import { validatePositiveNumber } from '../validators/number_validators';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const ZoneIcon = TollIcon;
import Swal from 'sweetalert2';
import {TextField as MUITextField} from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { getToken } from '../services/storage';

const ZoneRefresh = () => {

    const [reloading, setReloading] = useState(false);
    const refresh = useRefresh();

    // get the current tokan
    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={async () => {
                await setReloading(true);
                let response = await request('/zones', 'POST', null, {
                    token: getToken()
                });
                if (response.error) {
                    Swal.fire({
                        title: translate('custom.info'),
                        text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                        icon: 'error',
                        confirmButtonColor: config.baseColor
                    }).then(() => {
                        setReloading(false);
                        refresh();
                    })
                } else if (response.message) {
                    Swal.fire({
                        title: translate('custom.info'),
                        text: response.message_code ? convertMessageCodeToMessage(response.message_code) : response.message,
                        icon: 'info',
                        confirmButtonColor: config.baseColor
                    }).then(() => {
                        setReloading(false);
                        refresh();
                    })
                }
            }}
            disabled={reloading}
            style={{margin: 10}}
        >
            {reloading ? (
                translate('custom.reloading')
            ) : (
                translate('custom.reload_zones')
            )}
        </Button>
    );
}

const ZoneDelete = () => {
    const [deleting, setDeleting] = useState(false);
    const refresh = useRefresh();

    return (
        <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={async () => {

                // Swal to confirm
                let c = await Swal.fire({
                    title: translate('custom.delete_all_zones'),
                    text: translate('custom.do_you_want_to_delete_all_zones'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: config.baseColor,
                    confirmButtonText: translate('custom.yes')
                });
                if (!c.isConfirmed) {
                    return;
                }

                await setDeleting(true);
                await request('/zones', 'DELETE', null);
                await setDeleting(false);
                refresh();
            }}
            disabled={deleting}
            style={{margin: 10, backgroundColor: 'red', color: 'white'}}
        >
            {deleting ? (
                translate('custom.deleting')
            ) : (
                translate('custom.delete_zones')
            )}
        </Button>
    );
}


const ZoneListActions = (props) => (
    <TopToolbar>
        <ZoneRefresh />
        <ZoneDelete />
    </TopToolbar>
);

const Empty = () => (
    <Box textAlign="center" m={10}>
        <ZoneIcon style={{fontSize: '200px', color: 'gray'}} />
        <Typography variant="h4" paragraph>
            {translate('custom.no_zones_available')}
        </Typography>
        <ZoneRefresh />
    </Box>
);

const EpochToDateTimeField = ({ record }) => {
    if (record.date === null || record.date === undefined) {
        return null;
    }
    return <span>{new Date(record.date * 1000).toLocaleString()}</span>;
};

const EpochToDateTimeFieldInput = ({ record }) => {
    if (record.date === null || record.date === undefined) {
        return null;
    }
    let value = new Date(record.date * 1000).toLocaleString();
    return <MUITextField fullWidth label={translate('custom.date')} variant='outlined' defaultValue={value} disabled size='small' style={{marginTop: 8}} />;
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(!record.is_event_enabled) {
        return (
            <>
                <Button
                    startIcon={<CheckIcon />}
                    onClick={async () => {

                        // Swal to confirm
                        let c = await Swal.fire({
                            title: translate('custom.enable_event'),
                            text: translate('custom.do_you_want_to_enable_event'),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: config.baseColor,
                            confirmButtonText: translate('custom.yes')
                        });
                        if (!c.isConfirmed) {
                            return;
                        }

                        try {
                            await request('/events/short/' + record.infomaniak_event_id + '/enable', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px', color: 'green', borderColor: 'green'}}
                    variant="outlined"
                >
                    {translate('custom.enable_event')}
                </Button>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
            </>
        )
    } else {
        return (
            <>
                <Button
                    startIcon={<ClearIcon />}
                    onClick={async () => {

                        // Swal to confirm
                        let c = await Swal.fire({
                            title: translate('custom.disable_event'),
                            text: translate('custom.do_you_want_to_disable_event'),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: config.baseColor,
                            confirmButtonText: translate('custom.yes')
                        });
                        if (!c.isConfirmed) {
                            return;
                        }

                        try {
                            await request('/events/short/' + record.infomaniak_event_id + '/disable', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px', color: 'red', borderColor: 'red'}}
                    variant="outlined"
                >
                    {translate('custom.disable_event')}
                </Button>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
            </>
        )
    }
}

export class ZoneList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reloading: false
        }

    }

    async componentDidMount() {

    }

    render() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (

            <>
    
                <List
                    {...this.props}
                    hasCreate={false}
                    actions={<ZoneListActions />}
                    // filters={<ZoneListFilter />}
                    pagination={<PostPagination />}
                    perPage={50}
                    empty={<Empty />}
                    bulkActionButtons={false}
                >
                    {isSmall ? (
                        <SimpleList
                            primaryText={record => {
                                return new Date(record.date * 1000).toLocaleString()
                            }}
                            secondaryText={record => {
                                if (record.zone_name?.[locale]) {
                                    return record.zone_name[locale]
                                } else {
                                    return record.zone_name.fr
                                }
                            }}
                        />
                    ) : (
                        <Datagrid>
                            <FunctionField
                                label={translate('custom.event_name')}
                                render={record => {
                                    if (record.infomaniak_event_name?.[locale]) {
                                        return (
                                            <TextField
                                                label={translate('custom.event_name')}
                                                source={"infomaniak_event_name." + locale}
                                            />
                                        )
                                    } else {
                                        return (
                                            <TextField
                                                label={translate('custom.event_name')}
                                                source={"infomaniak_event_name.fr"}
                                            />
                                        )
                                    }
                                }}
                            />
                            <TextField label={translate('custom.event_id')} source="infomaniak_event_id" />
                            <EpochToDateTimeField label={translate('custom.date')} />
                            <TextField label={translate('custom.date_id')} source="date_id" />
                            <FunctionField
                                label={translate('custom.zone_name')}
                                render={record => {
                                    if (record.zone_name?.[locale]) {
                                        return (
                                            <TextField
                                                label={translate('custom.zone_name')}
                                                source={"zone_name." + locale}
                                            />
                                        )
                                    } else {
                                        return (
                                            <TextField
                                                label={translate('custom.zone_name')}
                                                source={"zone_name.fr"}
                                            />
                                        )
                                    }
                                }}
                            />
                            <TextField label={translate('custom.zone_id')} source="zone_id" />
                            <CustomButtons />
                        </Datagrid>
                    )}
                </List>
    
            </>
        );
    }
};

const PostTitle = ({ record }) => {
    return <span>{translate('custom.zone')} {record ? `"${record.zone_id}"` : ''}</span>;
};

const voucher_packs_form = (voucher_packs) => {
    return (
        <>
            <h3 style={headerTextStyles}>{translate('custom.voucher_packs')}</h3>
            <ArrayInput source="voucher_packs">
                <SimpleFormIterator disableReordering>

                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            return (
                                <>

                                    <Grid container style={{width: '100%'}} spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <SelectInput
                                                fullWidth={true} label={translate('custom.voucher_packs')} source={getSource("voucher_pack_id")} choices={voucher_packs} validate={required()}
                                                variant='outlined'
                                                record={scopedFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <NumberInput
                                                fullWidth={true} source={getSource("quantity")} label={translate('custom.quantity')} validate={validatePositiveNumber}
                                                step={1} variant='outlined'
                                                record={scopedFormData}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </Grid>
                                    </Grid>

                                </>

                            );
                        }}
                    </FormDataConsumer>
                    
                </SimpleFormIterator>
            </ArrayInput>
        </>
    )
};

const validateZoneForm = async (values) => {
    const errors = {};

    let voucher_packs = values.voucher_packs;

    if(voucher_packs) {
        errors.voucher_packs = [];
        let voucher_packs_length = voucher_packs.length;
        for(let i=0; i<voucher_packs_length; i++) {
            errors.voucher_packs.push({});

            if(voucher_packs[i]) {
                if(!voucher_packs[i].quantity) {
                    if(!errors.voucher_packs)
                        errors.voucher_packs = [];
                    if(!errors.voucher_packs[i])
                        errors.voucher_packs[i] = {};
                    errors.voucher_packs[i].quantity = translate('custom.quantity_is_required');
                } else if(voucher_packs[i].quantity < 1) {
                    if(!errors.voucher_packs)
                        errors.voucher_packs = [];
                    if(!errors.voucher_packs[i])
                        errors.voucher_packs[i] = {};
                    errors.voucher_packs[i].quantity = translate('custom.quantity_must_be_greater_than_0');
                } else if(voucher_packs[i].quantity != parseInt(voucher_packs[i].quantity)) {
                    if(!errors.voucher_packs)
                        errors.voucher_packs = [];
                    if(!errors.voucher_packs[i])
                        errors.voucher_packs[i] = {};
                    errors.voucher_packs[i].quantity = translate('custom.quantity_must_be_an_integer');
                }
            }

        }
        
        for(let i=voucher_packs_length-1; i>=0; i--) {
            for (let j=0; j<i; j++) {
                if (voucher_packs && voucher_packs[i] && voucher_packs[j] && (voucher_packs[i]["voucher_pack_id"] === voucher_packs[j]["voucher_pack_id"])) {
                    errors.voucher_packs[i]['voucher_pack_id'] = translate('custom.same_voucher_pack_not_allowed');
                }
            }
        }
    }

    return errors
}

export class ZoneEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            voucher_packs: []
        };
    }

    async componentDidMount() {

        let voc_packs = [];
        try {
            let voucher_packs = await request('/voucher_packs', 'GET', null);
            if(voucher_packs) {
                if (voucher_packs.error) {
                    alert(voucher_packs.error);
                } else {
                    for (let i = 0; i < voucher_packs.length; i++) {
                        voc_packs.push({
                            id: voucher_packs[i]._id,
                            name: voucher_packs[i].name
                        });
                    }
                    if(voc_packs.length)
                        voc_packs.sort((a, b) => a.name > b.name);
                    this.setState({
                        voucher_packs: voc_packs
                    })
                }
            
            }

        } catch(e) {
            console.log("Error", String(e));
        }

    }

    render() {

        return (
            <Edit title={<PostTitle />} {...this.props}>
                <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={validateZoneForm}>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            {/* <TextInput fullWidth source="zone_name" label={translate('custom.zone_name')} variant="outlined" disabled /> */}
                            <FunctionField
                                label={translate('custom.event_name')}
                                render={record => {
                                    if (record.infomaniak_event_name?.[locale]) {
                                        return (
                                            <TextInput fullWidth label={translate('custom.event_name')} source={"infomaniak_event_name." + locale} disabled variant='outlined' />
                                        )
                                    } else {
                                        return (
                                            <TextInput fullWidth label={translate('custom.event_name')} source={"infomaniak_event_name.fr"} disabled variant='outlined' />
                                        )
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <TextInput fullWidth source="infomaniak_event_id" label={translate('custom.event_id')} variant="outlined" disabled />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            {/* <TextInput fullWidth source="date" label={translate('custom.date')} variant="outlined" disabled /> */}
                            <FunctionField
                                render={record => {
                                    return (
                                        <EpochToDateTimeFieldInput fullWidth label={translate('custom.date')} record={record} />
                                    )
                                }}
                            />
                            {/* <EpochToDateTimeField fullWidth label={translate('custom.date')} /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <TextInput fullWidth source="date_id" label={translate('custom.date_id')} variant="outlined" disabled />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            {/* <TextInput fullWidth source="zone_name" label={translate('custom.zone_name')} variant="outlined" disabled /> */}
                            <FunctionField
                                label={translate('custom.zone_name')}
                                render={record => {
                                    if (record.zone_name?.[locale]) {
                                        return (
                                            <TextInput fullWidth label={translate('custom.zone_name')} source={"zone_name." + locale} disabled variant='outlined' />
                                        )
                                    } else {
                                        return (
                                            <TextInput fullWidth label={translate('custom.zone_name')} source={"zone_name.fr"} disabled variant='outlined' />
                                        )
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <TextInput fullWidth source="zone_id" label={translate('custom.zone_id')} variant="outlined" disabled />
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
                            <SelectArrayInput fullWidth={true} source="ages" label={translate('custom.ages')} choices={getChoices(config.entrance_ages)} variant='outlined' />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                            <SelectArrayInput fullWidth={true} source="payment_types" label={translate('custom.payment_types')} choices={getChoices(config.payment_types)} variant='outlined' />
                        </Grid>

                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                <BooleanInput fullWidth={true} source="is_zone_enabled" label={translate('custom.is_zone_enabled')} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {voucher_packs_form(this.state.voucher_packs)}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ArrayInput source="categories">
                                <SimpleFormIterator disableReordering disableAdd disableRemove>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            let source;
                                            if (scopedFormData.category_names?.[locale] && scopedFormData.category_names?.[locale] !== '') {
                                                source = getSource('category_names.' + locale)
                                            } else {
                                                source = getSource('category_names.fr')
                                            }
                                            return (
                                                <Grid container style={{width: '100%'}} spacing={2}>
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                        <TextInput fullWidth source={getSource("category_id")} label={translate('custom.category_id')} variant="outlined" disabled />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                        <TextInput
                                                            fullWidth
                                                            source={source}
                                                            label={translate('custom.category_name')}
                                                            variant="outlined"
                                                            record={scopedFormData}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                        <TextInput fullWidth source={getSource("category_type")} label={translate('custom.category_type')} variant="outlined" disabled />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>

                    </Grid>

                </SimpleForm>
            </Edit>
        )

    }

}

const styles = {
    disable_button: {
        color: 'red',
        borderColor: 'red',
        marginLeft: 'auto',
        marginRight: '20px'
    },
    enable_button: {
        color: config.baseColor,
        borderColor: config.baseColor,
        marginLeft: 'auto',
        marginRight: '20px'
    }
}

const headerTextStyles = {
    width: '100%',
    borderBottom: '2px solid' + config.baseColor,
    marginBottom: '50px',
    color: config.baseColor
}