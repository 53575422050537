import react from 'react';
import { Autocomplete, Box, Button, Card, CardContent, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { translate } from '../../i18n/customI18nProvider';
import { getEventMyposTerminals as getEventMyposTerminalsMethod, putEventMyposTerminals, forcePutEventMyposTerminals, validateEventMyposTerminals } from '../../services/event.service';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import NoLayout from './NoLayout';
import config from '../../config';
import DeviceLogs from './DeviceLogs';
import { getMyposTerminalList } from '../../services/mypos_terminal.service';

class AddEditEventMyposTerminals extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            devices: [],
            title: translate('custom.mypos_terminals'),
            loading: false,
            is_form_valid: true,
            form_error: {},
            refresh_device_log_counter: 0,
            // ------
            mypos_terminals: [],
            mypos_terminals_to_remove: []
        }
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount() {

        this.setDevices();

        let mode = (this.props?.match?.params?.event_id || this.props?.event_id) ? 'edit' : 'add';
        let event_id = this.props?.match?.params?.event_id || this.props?.event_id;
        await this.setStateAsync({
            loading: true,
            mode,
            event_id
        });

        if (mode === 'edit')
            await this.getEventMyposTerminals();
        
        this.setState({ loading: false });
    }

    async setDevices() {
        let mypos_terminals = await getMyposTerminalList({
            minimal: true
        });

        if (mypos_terminals.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: mypos_terminals.error_code ? convertMessageCodeToMessage(mypos_terminals.error_code) : mypos_terminals.error
            });
            return;
        }

        let mypos_terminals_array = mypos_terminals.mypos_terminals.map((mypos_terminal) => {
            return mypos_terminal.terminal_code;
        });

        this.setState({ devices: mypos_terminals_array });
    }

    async getEventMyposTerminals() {
        let event_mypos_terminals = await getEventMyposTerminalsMethod(this.state.event_id);
        if (event_mypos_terminals.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: event_mypos_terminals.error_code ? convertMessageCodeToMessage(event_mypos_terminals.error_code) : event_mypos_terminals.error
            });
            return;
        }
        event_mypos_terminals = event_mypos_terminals?.event_mypos_terminals;

        await this.setState({
            mypos_terminals: event_mypos_terminals?.mypos_terminals ? event_mypos_terminals?.mypos_terminals : []
        });
    }

    async save(force=false) {

        if (!this.isFormValid()) return;

        if (!force) {
            if (!(await this.validateForm())) return;
        }

        let payload = {
            mypos_terminals: this.state.mypos_terminals
        }

        let response;

        if (force) {
            response = await forcePutEventMyposTerminals(this.state.event_id, payload);
        } else {
            response = await putEventMyposTerminals(this.state.event_id, payload);
        }
        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error
            });
            return;
        }

        Swal.fire({
            icon: 'success',
            title: translate('custom.success'),
            text: translate('custom.saved_successfully'),
            showConfirmButton: false,
            timer: 1500
        });

        // Refresh Device Log
        this.setState({ refresh_device_log_counter: this.state.refresh_device_log_counter + 1 });



    }

    isFormValid(key=null) {

        let form_error = {};

        if (Object.keys(form_error).length > 0) {
            this.setState({ form_error, is_form_valid: false });
            return false;
        } else {
            this.setState({ form_error: {}, is_form_valid: true });
            return true;
        }

    }

    validateForm = async () => {
        
        let form_error = {};
    
    
        let payload = {
            mypos_terminals: this.state.mypos_terminals
        };

        let event_mypos_terminals_validate = await validateEventMyposTerminals(this.state.event_id, payload);
    
        if (event_mypos_terminals_validate?.mypos_terminals) {
            if (event_mypos_terminals_validate.mypos_terminals?.length > 0) {
                form_error['mypos_terminals'] = '';
                for (let i=0; i<event_mypos_terminals_validate.mypos_terminals.length; i++) {
                    if (form_error['mypos_terminals'] != '') {
                        form_error['mypos_terminals'] += ' & ';
                    }
                    form_error['mypos_terminals'] += `${translate('custom.mypos_terminal')} - ${event_mypos_terminals_validate.mypos_terminals[i].mypos_terminal_number} ${translate('custom.is_already_taken_by')} ${event_mypos_terminals_validate.mypos_terminals[i].event_name}`;
                }
            }
        }

        this.setState({ form_error });

        if (Object.keys(form_error).length > 0) {
            return false;
        }

        return true;
    }



    render() {
        return (
            <NoLayout>

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Card>
                    <CardContent>
                        <Grid container spacing={2} sx={{mb: 2}}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>{`${translate('custom.total_mypos_terminals')} : ${this.state.mypos_terminals.length}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disableCloseOnSelect
                                    freeSolo
                                    multiple
                                    id='mypos_terminals'
                                    options={this.state?.devices ? this.state?.devices : []}
                                    value={this.state.mypos_terminals}
                                    onChange={async (e, newValue) => {
                                        let newValues = [];
                                        newValue.forEach((value) => {
                                            newValues = newValues.concat(value.split(/[\s,]+/));
                                        });
                                        await this.setStateAsync({ mypos_terminals: newValues });
                                        this.validateForm();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('custom.mypos_terminals')}
                                            error={this.state.form_error['mypos_terminals'] ? true : false}
                                            helperText={this.state.form_error['mypos_terminals']}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    freeSolo
                                    multiple
                                    value={this.state.mypos_terminals_to_remove}
                                    onChange={async (e, newValue) => {
                                        let newValues = [];
                                        newValue.forEach((value) => {
                                            newValues = newValues.concat(value.split(/[\s,]+/));
                                        });

                                        // Remove the mypos_terminals from the list
                                        let mypos_terminals = this.state.mypos_terminals;
                                        for (let i=0; i<newValues.length; i++) {
                                            let index = mypos_terminals.indexOf(newValues[i]);
                                            if (index > -1) {
                                                mypos_terminals.splice(index, 1);
                                            }
                                        }

                                        await this.setStateAsync({ mypos_terminals, mypos_terminals_to_remove: [] });
                                        this.validateForm();
                                    }}
                                    options={[]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('custom.mypos_terminals_to_remove')}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{mt: 2, justifyContent: 'space-around'}}>
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                                <Button variant='contained' color='primary' fullWidth
                                    onClick={async () => {
                                        await this.save();
                                    }}
                                >{this.state.loading ? translate('custom.loading') : translate('custom.save')}</Button>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                                <Button variant='contained' color='primary' fullWidth
                                    onClick={async () => {
                                        await this.save(true);
                                    }}
                                >{this.state.loading ? translate('custom.loading') : translate('custom.force_save')}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                
                {(this.state.event_id) ? (
                    <DeviceLogs
                        event_id={this.state.event_id}
                        device_type={config.device_types.MYPOS_TERMINAL.key}
                        hide_columns={['event']}
                        refresh_counter={this.state.refresh_device_log_counter}
                    />
                ) : null}


            </NoLayout>
        );
    }
}

export default AddEditEventMyposTerminals;
