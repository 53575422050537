import react from 'react';
import config from '../../config';
import { createTheme, ThemeProvider } from '@mui/material/styles';


// Define your custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: config.baseColor, // Replace with your desired primary color
        },
        secondary: {
            main: '#00ff00', // Replace with your desired secondary color
        },
    },
});

class NoLayout extends react.Component {

    constructor(props) {
        super(props);

        this.state = {
            right: localStorage.getItem('right')
        };
    }

    

    render() {

        let title = this.props.title ? this.props.title : null;

        return (
            <ThemeProvider theme={theme}>
                {this.props.children}
            {/* <Grid container style={{ display: 'flex' }}>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: config.baseColor,
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {title ? title : this.state.currentMenu?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid item xs={12} sm={drawerWidth} style={{ flexShrink: 0 }}>
                    <Drawer
                        variant="temporary"
                        open={this.state.mobileOpen}
                        onTransitionEnd={this.handleDrawerTransitionEnd}
                        onClose={this.handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: config.backgroundColor
                            }
                        }}
                    >
                        {drawer(this.state.right, this.state.currentMenu?.key)}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                        PaperProps={{
                            style: {
                                backgroundColor: config.backgroundColor
                            }
                        }}
                    >
                        {drawer(this.state.right, this.state.currentMenu?.key)}
                    </Drawer>
                </Grid>
                <Grid item xs={12}
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        padding: '16px',
                        backgroundColor: config.backgroundColor
                    }}
                >
                    <Toolbar />
                    {this.props.children}
                </Grid>
            </Grid> */}
            </ThemeProvider>
        )
    }
}

export default NoLayout;
