import React, {Component} from 'react';

import Button  from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { nativeTranslate as translate } from "../i18n/customI18nProvider";

// import Autocomplete from '@material-ui/core/Autocomplete';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { formatCurrencySign, request } from "../util";
import backgroundImage from '../images/client-background-image.jpg';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider, withStyles, withTheme } from '@material-ui/core/styles';
import config from '../config';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import theme from './../components/Theme';
import braceletImage from '../images/bracelet2.png';
import { Hidden } from '@mui/material';

const STATES = {
    ENTER_BRACELET_NUMBER: 1,
    FILL_DETAILS: 2,
    DETAILS_FILLED_SUCCESSFULLY: 3,
    FORM_REFUND_CLOSED: 4,
    LOADING: 5,
    ERROR: 6
}

const customStyles = {
    glassBackgroundMain: {
        background: 'rgba(255, 255, 255, 0.46)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        borderRadius: '20px'
    },
    glassBackground: {
        background: 'rgba(255, 255, 255, 0.17)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        width: '100%'
    },
    iconStyle: {
        fontSize: '30px',
        padding: '5px',
        backgroundColor :'white',
        borderRadius: '5px'
    }
}

const GEN_IBAN_REGEX = /[^a-zA-Z0-9\ ]/g
const GEN_BIC_REGEX = /[^a-zA-Z0-9\ ]/g
const GEN_PHONE_NUMBER_REGEX = /[^0-9\+]/g
const PHONE_NUMBER_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
// const VALID_CHARACTERS_REGEX = /[^a-zA-Z0-9.,;:'+-/()?*[\]{}\`\´~!"#0%&<>÷=@_$£àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ\s\n\r]/g
// const VALID_CHARACTERS_REGEX = /[^a-zA-Z0-9\.,;:'+-/\(\)?\*\[\]\{\}\\`´~ !"#%&<>÷=@_$£àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ\s\n\r]/g
const VALID_CHARACTERS_REGEX = /[^a-zA-Z0-9.,;:'+\-/()?\*\[\]{}\\`´~!"#%&<>÷=@_$£àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ\s\n\r]/g;
// const VALID_CHARACTERS_REGEX = /([a-zA-Z0-9.,;:'+\-/()?\*\[\]{}\\`´~ ]|[!"#%&<>÷=@_$£]|[àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ])/g;
const INVALID_SINGLE_QUOTES_REGEX = /[’]/g

class Refund extends Component {

    constructor(props){
        super(props);
        this.state = {
            CURRENT_STATE: STATES.LOADING,
            general_information: {},
            donation_amounts: config.donation_amounts,
            info_text: '',
            bracelet_number: "",
            loading: false,
            first_name: "",
            last_name: "",
            email: "",
            address: "",
            locality: "",
            postal_code: "",
            iban: "",
            bic: "",
            phone_number: "",
            donation_type: "",
            donation_amount: "",
            pristine: {
                first_name: true,
                last_name: true,
                email: true,
                address: true,
                phone_number: true,
                locality: true,
                postal_code: true,
                iban: true,
                bic: true,
                donation_amount: true
            },
            captcha_verified: false,
            balance: '',
            unpaid: 0,
            currency: ''
        };

        this.myChoices = [];
    }

    async componentDidMount() {
        
        let pathname = window.location.pathname;
        let pathname_split = pathname.split('/');

        let bracelet_number = "";
        
        if(pathname_split.length === 3) {
            bracelet_number = pathname_split[2];
            
            let response = await request('/clients/short_info/'+bracelet_number, 'GET');
            if(response.error) {
                this.setState({loading: false});
                alert(response.error);
                window.location.href = '/refund';
                return;
            }

            let general_information = await request(`/events/general?client_uid=${bracelet_number}`, 'GET');
            if(general_information.error) {
                alert(general_information.error);
                return;
            }

            let refund_res = general_information.form_refund;

            if((!refund_res.enabled) || (new Date(refund_res.dateEnd) < new Date())) {
                this.setState({CURRENT_STATE: STATES.FORM_REFUND_CLOSED, info_text: refund_res.form_refund_closed_text});
                return;
            }

            this.setState({
                general_information: general_information,
                CURRENT_STATE: STATES.FILL_DETAILS,
                info_text: refund_res.form_refund_success_text,
                bracelet_number: bracelet_number,
                captcha_verified: true,
                first_name: response.name ? response.name.split(" ")[0] : '',
                // all occurrences from the second space onwards are part of the last name
                last_name: response.name ? response.name.split(" ").slice(1).join(" ") : '',
                email: response.email ? response.email : '',
                address: response.address ? response.address : '',
                locality: response.locality ? response.locality : '',
                postal_code: response.postal_code ? response.postal_code : '',
                phone_number: response.phone_number ? response.phone_number : '',
                iban: response.iban ? response.iban : '',
                bic: response.bic ? response.bic : '',
                donation_type: response?.feedback?.donationType ? response?.feedback?.donationType : '',
                donation_amount: response?.feedback?.donationAmount ? response?.feedback?.donationAmount : '',
                currency: general_information.currencySymbol ? general_information.currencySymbol : general_information.currencyCode,
                balance: response?.balance ? response?.balance : '',
                unpaid: response?.split_balance?.unpaid ? response?.split_balance?.unpaid : 0,
                spent: response?.split_balance?.spent ? response?.split_balance?.spent : 0
            });

        } else {
            this.setState({CURRENT_STATE: STATES.ENTER_BRACELET_NUMBER});
        }

    }

    setPristine(key, value) {
        let update_pristine = this.state.pristine;
        if(!key) {
            let all_keys = Object.keys(update_pristine);
            for(let i=0; i<all_keys.length; i++) {
                update_pristine[all_keys[i]] = false;
            }
        }
        update_pristine[key] = value;
        this.setState({pristine: update_pristine})
    }

    isRequiredValid(value, type) {
        if(value === "") {
            if(type === "status")
                return false;
            else
                return translate('custom.required_field');
        }

        if(type === "status")
            return true;
        else
            return "";
    }

    isFirstNameValid(type) {

        if(this.state.pristine.first_name) {
            return (type == "status") ? true : ''
        }

        const first_name = this.state.first_name;
        if(!this.isRequiredValid(first_name, "status"))
            return this.isRequiredValid(first_name, type);

        let valid_characters = VALID_CHARACTERS_REGEX;
        if(valid_characters.test(first_name)) {
            return (type === "status") ? false : translate('custom.invalid_characters_found');
        }

        return (type === "status") ? true : '';
    }

    isLastNameValid(type) {

        if(this.state.pristine.last_name) {
            return (type == "status") ? true : ''
        }

        const last_name = this.state.last_name;
        if(!this.isRequiredValid(last_name, "status"))
            return this.isRequiredValid(last_name, type);

        let valid_characters = VALID_CHARACTERS_REGEX;
        if(valid_characters.test(last_name)) {
            return (type === "status") ? false : translate('custom.invalid_characters_found');
        }

        return (type === "status") ? true : '';
    }

    isEmailValid(type) {

        if(this.state.pristine.email) {
            return (type == "status") ? true : ''
        }

        const email = this.state.email;

        if (email == '') {
            return (type === "status") ? true : '';
        }

        const m = email.match(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/);
        if(!m) {
            if(type === "status")
                return false;
            else
                return "Email is not Valid";
        }

        if(type === "status")
            return true;
        else
            return "";
    }

    isAddressValid(type) {
        if(this.state.pristine.address) {
            return (type == "status") ? true : ''
        }

        const address = this.state.address;
        if(!this.isRequiredValid(address, "status"))
            return this.isRequiredValid(address, type);


        // check for invalid characters
        let valid_characters = VALID_CHARACTERS_REGEX;
        if(valid_characters.test(address)) {
            return (type === "status") ? false : translate('custom.invalid_characters_found');
        }

        return (type === "status") ? true : '';
    }

    isLocalityValid(type) {

        if(this.state.pristine.locality) {
            return (type == "status") ? true : ''
        }

        const locality = this.state.locality;
        if(!this.isRequiredValid(locality, "status"))
            return this.isRequiredValid(locality, type);

        let valid_characters = VALID_CHARACTERS_REGEX;
        if(valid_characters.test(locality)) {
            return (type === "status") ? false : translate('custom.invalid_characters_found');
        }

        return (type === "status") ? true : '';
    }

    isPostalCodeValid(type) {

        if(this.state.pristine.postal_code) {
            return (type == "status") ? true : ''
        }

        const postal_code = this.state.postal_code;
        if(!this.isRequiredValid(postal_code, "status"))
            return this.isRequiredValid(postal_code, type);
        return this.isRequiredValid(postal_code, type);
    }

    isIbanValid(type) {

        if(this.state.pristine.iban) {
            return (type == "status") ? true : ''
        }

        let iban = this.state.iban;

        if(!this.isRequiredValid(iban, "status"))
            return this.isRequiredValid(iban, type);
        
        const ibanStripped = iban.replace(/[^A-Z0-9]+/gi,'') //keep numbers and letters only
                                 .toUpperCase(); //calculation expects upper-case

        // remove spaces from iban
        iban = iban.replace(/ /g,'');

        if(ibanStripped != iban.replace(' ','')) {
            return (type === "status") ? false : translate('custom.iban_contains_invalid_characters');
        }

        const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
        if(!m) {
            if(type === "status")
                return false;
            else
                return translate('custom.iban_invalid');
        }

        // Don't support QR IBAN
        if ((ibanStripped.startsWith('CH') || ibanStripped.startsWith('LI'))) {

            // if ibanStripped[4] to ibanStripped[8] is between 30000 and 31999, it is a QR IBAN and its invalid
            if (ibanStripped.substring(4, 9) >= "30000" && ibanStripped.substring(4, 9) <= "31999") {
                if(type === "status")
                    return false;
                else
                    return translate('custom.qr_iban_is_not_supported');
            }
        }

        
        const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g,function(ch){
                              //replace upper-case characters by numbers 10 to 35
                              return (ch.charCodeAt(0)-55); 
                          });
        //The resulting number would be to long for javascript to handle without loosing precision.
        //So the trick is to chop the string up in smaller parts.
        const mod97 = numbericed.match(/\d{1,7}/g)
                                .reduce(function(total, curr){ return Number(total + curr)%97},'');
      
        let valid = (mod97 === 1);
        if (ibanStripped.startsWith('CH')) {
            valid = valid && (ibanStripped.length === 21);
        }
        if(type === "status")
            return valid;
        else
            if(valid)
                return "";
            else
                return translate('custom.iban_invalid');
    }

    isBicValid(type) {

        if(this.state.pristine.bic) {
            return (type == "status") ? true : ''
        }

        let bic = this.state.bic;

        if(!this.isRequiredValid(bic, "status"))
            return this.isRequiredValid(bic, type);
        
        const bicStripped = bic.replace(/[^A-Z0-9]+/gi,'') //keep numbers and letters only
                                 .toUpperCase(); //calculation expects upper-case


        // remove spaces from bic
        bic = bic.replace(/ /g,'');

        if(bicStripped != bic) {
            return (type === "status") ? false : translate('custom.bic_contains_invalid_characters');
        }

        const m = bicStripped.match(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/);
        if(!m) {
            if(type === "status")
                return false;
            else
                return translate('custom.bic_invalid');
        }

        let iban = this.state.iban;

        if (iban.startsWith('CH')) {
            if(type === "status")
                return true
            else
                return "";
        } else {
            if (bic[4] == iban[0] && bic[5] == iban[1]) {
                if(type === "status")
                    return true
                else
                    return "";
            } else {
                if(type === "status")
                    return false
                else
                    return translate('custom.bic_invalid');
            }
        }
    }

    isPhoneNumberValid(type) {

        if(this.state.pristine.phone_number) {
            return (type == "status") ? true : ''
        }

        const phone_number = this.state.phone_number;
        
        if (phone_number === '') {
            return (type === "status") ? true : '';
        }
        
        const m = phone_number.match(PHONE_NUMBER_REGEX);
        if(!m) {
            if(type === "status")
                return false;
            else
                return translate('custom.phone_number_is_not_valid');
        }

        if(type === "status")
            return true;
        else
            return "";

    }

    validateForm() {
        
        if(!this.isFirstNameValid("status") || !this.isLastNameValid("status") || !this.isAddressValid("status")
            || !this.isLocalityValid("status") || !this.isPostalCodeValid("status") || !this.isIbanValid("status") || !this.isPhoneNumberValid("status")
            || !this.isEmailValid("status")
        ) {
            return false;
        }

        if (!this.state.iban.startsWith('CH') && !this.isBicValid("status")) {
            return false;
        }
        
        return true;
    }

    validate = async () => {

        if(!this.state.bracelet_number || this.state.bracelet_number == '' || !this.state.captcha_verified)
            return

        this.setState({
            loading: true
        });

        if(this.state.CURRENT_STATE == STATES.ENTER_BRACELET_NUMBER) {
            let response = await request('/clients/short_info/'+this.state.bracelet_number, 'GET');
            if(response.error) {
                this.setState({loading: false});
                if(response.error === 'Le client est introuvable') {
                    alert(translate('custom.wrong_wristband_number'));
                } else {
                    alert(response.error);
                }
                return;
            }

            window.location.href = '/refund/'+this.state.bracelet_number;

        } else {

            if(!this.validateForm()) {
                this.setState({loading: false});
                return;
            }

            let data_obj = {
                "name" : this.state.first_name + (this.state.last_name ? " " + this.state.last_name : ""),
                "email" : this.state.email,
                "address" : this.state.address,
                "phone_number" : this.state.phone_number,
                "locality" : this.state.locality,
                "postal_code" : this.state.postal_code,
                "iban" : (this.state.iban).replace(/[^A-Z0-9]+/gi,'').toUpperCase(),
                "bic" : (this.state.bic).replace(/[^A-Z0-9]+/gi,'').toUpperCase(),
            }

            if (this.state.donation_amount > 0) {
                data_obj.feedback = {
                    donationChoice: 'yes',
                    donationType: this.state.donation_type,
                    donationAmount: this.state.donation_amount
                }
            }

            let response = await request('/clients/short_info/'+this.state.bracelet_number, 'PATCH', null, data_obj);
            if(response.error) {
                alert(response.error)
            } else {
                this.setState({
                    loading: false,
                    CURRENT_STATE: STATES.DETAILS_FILLED_SUCCESSFULLY
                })
            }

            this.setState({loading: false});

        }
    }

    handleVerificationSuccess = (token, ekey) => {
        this.setState({
            captcha_verified: true
        });
    }

    form_refund_closed_render = () => {
        if(this.state.CURRENT_STATE != STATES.FORM_REFUND_CLOSED) {
            return null;
        }
        return (
            <div align="center">
                <div dangerouslySetInnerHTML={{ __html: this.state.info_text }}></div>
            </div>
        )
    }

    details_filled_successfully_render = () => {
        if(this.state.CURRENT_STATE != STATES.DETAILS_FILLED_SUCCESSFULLY) {
            return null;
        }
        return (
            <div align="center">

                <div style={{display: 'flex'}}>
                    <IconButton
                        style={{
                            backgroundColor: config.baseColor,
                            color: 'white',
                            borderRadius: '50%',
                            padding: '5px',
                        }}
                        onClick={() => {
                            // this.setState({
                            //     CURRENT_STATE: STATES.FILL_DETAILS
                            // })
                            window.location.reload();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </div>

                <div dangerouslySetInnerHTML={{ __html: this.state.info_text }}></div>
            
            
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: config.baseColor,
                        color: 'white',
                        margin: '10px'
                    }}
                    onClick={() => {
                        if(this.state.bracelet_number) {
                            window.location.href = '/client/'+this.state.bracelet_number;
                        } else {
                            window.location.href = '/refund';
                        }
                    }}
                >
                    {translate('custom.ok')}
                </Button>

                <Typography
                    variant="h6"
                    style={{
                        marginTop: '20px',
                        fontSize: '16px',
                        color: 'gray',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        window.location.href = '/refund';
                    }}
                >
                    {translate('custom.new_form_refund')}
                </Typography>

                {/* <Button
                    variant="contained"
                    style={{
                        backgroundColor: config.baseColor,
                        color: 'white',
                        margin: '10px'
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        window.location.href = '/refund';
                    }}
                >
                    {translate('custom.new_form_refund')}
                </Button> */}
            </div>
        )
    }

    enter_bracelet_number_render = () => {
        if(this.state.CURRENT_STATE != STATES.ENTER_BRACELET_NUMBER) {
            return null;
        }
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems:"center",
                padding: 20
            }}>

                <TextField
                    style={styles}
                    autoFocus={true}
                    label={translate('custom.bracelet_number')}
                    onChange={(event) => {this.setState({bracelet_number: event.target.value})}}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.validate();
                        }
                    }}
                />

                <HCaptcha
                    sitekey="01524f3e-b48c-4f98-a880-7ea38d035f8e"
                    onVerify={(token,ekey) => this.handleVerificationSuccess(token, ekey)}
                    onExpire={() => {
                        this.setState({
                            captcha_verified: false
                        })
                    }}
                />

                {this.state.bracelet_number && this.state.bracelet_number != '' && this.state.captcha_verified &&
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: config.baseColor,
                            color: 'white',
                            margin: '10px',
                            marginTop: 20,
                            width: 200
                        }}
                        onClick={() => {
                            this.validate()
                        }}
                        disabled={!this.state.bracelet_number || this.state.bracelet_number == '' || !this.state.captcha_verified}
                    >
                        {translate('custom.validate')}
                    </Button>
                }
            </div>
        );
    }

    fill_details_render = () => {
        if(this.state.CURRENT_STATE != STATES.FILL_DETAILS) {
            return null;
        }
        return (

            <>

            <div style={{display: 'flex'}}>
                <IconButton
                    style={{
                        backgroundColor: config.baseColor,
                        color: 'white',
                        borderRadius: '50%',
                        padding: '5px',
                    }}
                    onClick={() => {
                        let urlParams = new URLSearchParams(window.location.search);
                        let redirect_url = urlParams.get('redirect_url');
                        if(redirect_url) {
                            window.location.href = '/client/'+this.state.bracelet_number;
                        } else {
                            window.location.href = '/refund';
                        }
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems:"center",
                padding: 20
            }}>

                <TextField
                    style={styles}
                    label={translate('custom.first_name')}
                    value={this.state.first_name}
                    onBlur={(event) => { this.setPristine('first_name', false) }}
                    onChange={(event) => {
                        this.setPristine('first_name', false)
                        if(event.target.value.length > 35) {
                            return;
                        }
                        // remove non-valid characters
                        let value = event.target.value.replace(INVALID_SINGLE_QUOTES_REGEX, "'").replace(VALID_CHARACTERS_REGEX,'');
                        this.setState({first_name: value})
                    }}
                    required
                    error={!this.isFirstNameValid('status')}
                    helperText={ this.isFirstNameValid('message') ? this.isFirstNameValid('message') : translate("custom.remaining_characters") + " : " + (35 - this.state.first_name.length)}
                />
                <br />
                <TextField
                    style={styles}
                    label={translate('custom.last_name')}
                    value={this.state.last_name}
                    onBlur={(event) => { this.setPristine('last_name', false) }}
                    onChange={(event) => {
                        this.setPristine('last_name', false)
                        if(event.target.value.length > 35) {
                            return;
                        }
                        // remove non-valid characters
                        let value = event.target.value.replace(INVALID_SINGLE_QUOTES_REGEX, "'").replace(VALID_CHARACTERS_REGEX,'');
                        this.setState({last_name: value})
                    }}
                    required
                    error={!this.isLastNameValid('status')}
                    helperText={ this.isLastNameValid('message') ? this.isLastNameValid('message') : translate("custom.remaining_characters") + " : " + (35 - this.state.last_name.length)}
                />
                <br />
                <TextField
                    style={styles}
                    label={translate('custom.address')}
                    value={this.state.address}
                    onBlur={(event) => { this.setPristine('address', false) }}
                    onChange={(event) => {
                        this.setPristine('address', false)
                        if(event.target.value.length > 35) {
                            return;
                        }
                        // remove non-valid characters
                        let value = event.target.value.replace(INVALID_SINGLE_QUOTES_REGEX, "'").replace(VALID_CHARACTERS_REGEX,'');
                        this.setState({address: value})
                    }}
                    multiline={true}
                    maxLength={35}
                    rows={3}
                    required
                    error={!this.isAddressValid('status')}
                    // helperText={ translate("custom.remaining_text") + " : " + (35 - this.state.address.length) + this.isAddressValid('message')}
                    helperText={ this.isAddressValid('message') ? this.isAddressValid('message') : translate("custom.remaining_characters") + " : " + (35 - this.state.address.length)}
                />
                <br />
                <TextField
                    style={styles}
                    label={translate('custom.postal_code')}
                    value={this.state.postal_code}
                    onBlur={(event) => { this.setPristine('postal_code', false) }}
                    onChange={(event) => {
                        this.setPristine('postal_code', false)
                        if(event.target.value.length > 16) {
                            return;
                        }
                        this.setState({postal_code: event.target.value})
                    }}
                    required
                    error={!this.isPostalCodeValid('status')}
                    helperText={ this.isPostalCodeValid('message') ? this.isPostalCodeValid('message') : translate("custom.remaining_characters") + " : " + (16 - this.state.postal_code.length)}
                />
                <br />
                <TextField
                    style={styles}
                    label={translate('custom.locality')}
                    value={this.state.locality}
                    onBlur={(event) => { this.setPristine('locality', false) }}
                    onChange={(event) => {
                        this.setPristine('locality', false)
                        if(event.target.value.length > 16) {
                            return;
                        }
                        // remove non-valid characters
                        let value = event.target.value.replace(INVALID_SINGLE_QUOTES_REGEX, "'").replace(VALID_CHARACTERS_REGEX,'');
                        this.setState({locality: value})
                    }}
                    required
                    error={!this.isLocalityValid('status')}
                    helperText={ this.isLocalityValid('message') ? this.isLocalityValid('message') : translate("custom.remaining_characters") + " : " + (16 - this.state.locality.length)}
                />
                <br />
                <TextField
                    style={styles}
                    label="IBAN"
                    value={this.state.iban}
                    onBlur={(event) => { this.setPristine('iban', false) }}
                    onChange={(event) => {
                        this.setPristine('iban', false)
                        // remove non alphanumeric characters except space
                        let value = event.target.value.replace(GEN_IBAN_REGEX, '');
                        this.setState({iban: (value).toUpperCase()})
                    }}
                    required
                    error={!this.isIbanValid('status')}
                    helperText={this.isIbanValid('message')}
                />
                {(this.state.iban.length && this.isIbanValid('status') && !this.state.iban.startsWith('CH')) ? (
                    <Typography
                        style={{
                            color: config.dangerColor,
                            fontSize: '12px',
                            textAlign: 'left',
                        }}
                    >
                        {translate('custom.TEXT.ch_iban_warning')}
                    </Typography>
                ) : null}
                <br />
                {(this.state.iban.length && this.isIbanValid('status') && !this.state.iban.startsWith('CH')) ? (
                    <>
                        <TextField
                            style={styles}
                            label="BIC"
                            value={this.state.bic}
                            onBlur={(event) => { this.setPristine('bic', false) }}
                            onChange={(event) => {
                                this.setPristine('bic', false)
                                // remove non alphanumeric characters except space
                                let value = event.target.value.replace(GEN_BIC_REGEX, '');
                                this.setState({bic: (value).toUpperCase()})
                            }}
                            required
                            error={!this.isBicValid('status')}
                            helperText={this.isBicValid('message')}
                        />
                        <br />
                    </>
                )
                : null}
                
                {/* <TextField
                    style={styles}
                    label={translate('custom.bank') + ' / ' + translate('custom.post')}
                    value={this.state.bank}
                    onBlur={(event) => { this.setPristine('bank', false) }}
                    onChange={(event) => {
                        this.setPristine('bank', false)
                        this.setState({bank: event.target.value})
                    }}
                    required
                    error={!this.isBankValid('status')}
                    helperText={this.isBankValid('message')}
                />
                <br />
                <TextField
                    style={styles}
                    label={translate('custom.establishment_address')}
                    value={this.state.establishment_address}
                    onBlur={(event) => { this.setPristine('estabishment_address', false) }}
                    onChange={(event) => {
                        this.setPristine('estabishment_address', false)
                        if(event.target.value.length > 35) {
                            return;
                        }
                        this.setState({establishment_address: event.target.value})
                    }}
                    multiline={true}
                    rows={3}
                    required
                    error={!this.isEstablishmentAddressValid('status')}
                    // helperText={this.isEstablishmentAddressValid('message')}
                    helperText={ translate("custom.remaining_characters") + " : " + (35 - this.state.establishment_address.length)}
                />
                <br /> */}


                <div style={styles}>
                    <br /><br />
                    {translate('custom.incase_there_is_a_problem_with_payment_we_will_contact_you_by_email_or_phone')}
                    <br />
                    {'( ' + translate('custom.both_are_optional') + ' )'}
                </div>
                <TextField
                    style={styles}
                    label={translate('custom.email')}
                    value={this.state.email}
                    onBlur={(event) => { this.setPristine('email', false) }}
                    onChange={(event) => {
                        this.setPristine('email', false)
                        this.setState({email: event.target.value})
                    }}
                    error={!this.isEmailValid('status')}
                    helperText={this.isEmailValid('message')}
                />
                <br />
                <TextField
                    style={styles}
                    label={translate('custom.phone_number')}
                    value={this.state.phone_number}
                    onBlur={(event) => { this.setPristine('phone_number', false) }}
                    onChange={(event) => {
                        this.setPristine('phone_number', false)
                        // remove non-valid characters
                        let value = event.target.value.replace(GEN_PHONE_NUMBER_REGEX,'');
                        this.setState({phone_number: value})
                    }}
                    error={!this.isPhoneNumberValid('status')}
                    helperText={this.isPhoneNumberValid('message')}
                />
                <br />

                {(this.state.general_information?.donation?.enabled) ? (
                    <>

                        <div style={styles}>
                            <br />
                            {translate('custom.donate') + ' :'}
                            <br />
                        </div>

                        <Grid container spacing={3} style={{padding: '10px', justifyContent: 'center'}}>
                            {(this.state.donation_amounts.map((amount, idx) => {
                                return (
                                    <Button
                                        key={idx}
                                        variant={this.state.donation_amount == amount ? "contained" : "outlined"}
                                        color="primary"
                                        style={{margin: '10px', textTransform: 'none'}}
                                        onClick={()=>{
                                            if(this.state.donation_amount == amount) {
                                                this.setState({donation_amount: '', donation_type: ''});
                                                return;
                                            }
                                            this.setState({donation_amount: amount, donation_type: 'fixed'});
                                        }}
                                    >
                                        {this.state?.general_information?.currencyCode ? this.state.general_information.currencyCode : ''} {amount}
                                    </Button>
                                )
                            }))}

                            <Button
                                variant={this.state.donation_type == 'custom' ? "contained" : "outlined"}
                                color="primary"
                                style={{margin: '10px', textTransform: 'none'}}
                                onClick={()=>{
                                    if(this.state.donation_type == 'custom') {
                                        this.setState({donation_type: '', donation_amount: ''});
                                        return;
                                    }
                                    this.setState({donation_type: 'custom', donation_amount: ''});
                                }}
                            >
                                {translate('custom.custom')}
                            </Button>

                        </Grid>

                        {(this.state.donation_type == 'custom') ? (
                            <TextField
                                style={styles}
                                type="number"
                                label={translate('custom.donation_amount')}
                                value={this.state.donation_amount}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    try {
                                        if(value != '')
                                            value = parseFloat(value);
                                    } catch(e) {
                                        value = ''
                                    }
                                    this.setState({donation_amount: value})
                                }}
                                onWheel={(e) => {
                                    e.target.blur();
                                }}
                            />
                        ) : null}
                    </>
                ) : null}
                
                <br />

                <Button
                    variant="contained"
                    style={{
                        backgroundColor: config.baseColor,
                        color: 'white',
                        margin: '10px',
                        marginTop: 20,
                        width: 200
                    }}
                    onClick={() => {
                        this.setPristine(null, false);
                        this.validate()
                    }}
                >
                    {translate('custom.validate')}
                </Button>

            </div>

            </>
        )
    }

    loading_render = () => {
        if(this.state.CURRENT_STATE != STATES.LOADING) {
            return null;
        }
        return (
            <div style={{textAlign: 'center'}}>
                <p>{translate('custom.loading')}</p>
            </div>
        )
    }

    braceletHeaderRender() {

        let event_name = this.state.general_information.name ? this.state.general_information.name : null;

        return (

            <div style={{textAlign: 'center'}}>
                {(this.state.name || this.state.short_id) ? (
                    <h3 style={{color: config.baseColor}}>{translate('custom.bracelet')} {this.state.name ? this.state.name : this.state.short_id}</h3>
                ) : null}

                <img alt="bracelet" style={{width: '100%'}} src={braceletImage} />

                {event_name ? (
                    <h4 style={{color: config.baseColor}}>{event_name}</h4>
                ) : null}

            </div>

        )
    }

    braceletDetailsRender() {
        return (
            <div>
                <Hidden xsDown>
                    <Grid container justifyContent='space-around' style={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            <h4>{translate('custom.balance')}</h4>
                            <h3 style={(this.state.balance < 0) ? {color: '#c00'} : {color: '#060'}}>{this.state.currency} {formatCurrencySign(this.state.balance)}</h3>
                        </Grid>
                        {(this.state.unpaid > 0) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.unpaid')}</p>
                                <h4 style={{color: 'gray'}}>{this.state.currency} {formatCurrencySign(this.state.unpaid)}</h4>
                            </Grid>
                        : null}
                        {(this.state.spent != null) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.total_spent')}</p>
                                <h4 style={{color: config.baseColor}}>{this.state.currency} {formatCurrencySign(this.state.spent)}</h4>
                            </Grid>
                        : null}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <div style={{margin: '20px'}}>
                        <h4>
                            <span>{translate('custom.balance')}</span>
                            <span style={{color: 'green', float: 'right', fontWeight: '800'}}>{this.state.currency} {formatCurrencySign(this.state.balance)}</span>
                        </h4>
                    </div>
                    {(this.state.unpaid > 0) ?
                        <div style={{margin: '20px'}}>
                            <h4>
                                <span>{translate('custom.unpaid')}</span>
                                <span style={{color: 'gray', float: 'right', fontWeight :'800'}}>{this.state.currency} {formatCurrencySign(this.state.unpaid)}</span>
                            </h4>
                        </div>
                    : null}
                </Hidden>

                

            </div>
        )
    }

    render() {


        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <MuiThemeProvider theme={theme}>
            <div>

                <div
                    style={{
                        backgroundImage: "linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(" + backgroundImage + ")",
                        height: '100vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        overflow: 'auto',
                        backgroundPosition: 'center center'
                    }}
                >

                    <Grid container alignItems='center' justifyContent="center" direction="column">
                        <Grid item xs={12} sm={12} md={8} lg={6}
                            style={customStyles.glassBackground}
                        >

                            <Grid item
                                style={{
                                    ...customStyles.glassBackgroundMain,
                                    marginBottom: 20
                                }}
                            >
                                {this.braceletHeaderRender()}

                                {this.braceletDetailsRender()}

                            </Grid>


                            <Grid item
                                style={customStyles.glassBackgroundMain}
                            >
                                {this.form_refund_closed_render()}
                                {this.details_filled_successfully_render()}
                                {this.enter_bracelet_number_render()}
                                {this.fill_details_render()}
                                {this.loading_render()}
                            </Grid>

                        </Grid>
                    </Grid>

                </div>

                
            
            </div>
            </MuiThemeProvider>
        );

    }
}

const styles = {
    width: "100%",
    maxWidth: 400,
    margin: 10,
    color: config.baseColor
};

const styles2 = theme => ({
    input: {
        color: config.baseColor
    }
});
export default withTheme(withStyles(styles2)(Refund));