import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getDeviceList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/devices' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getDeviceDetails = async (device_id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/devices/' + device_id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const saveDeviceDetails = async (device_id, data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.apiV2Url + '/devices/' + device_id, data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const createDevice = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/devices', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteDevice = async (id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.apiV2Url + '/devices/' + id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const importDevices = async (formData) => {
    try {
        let headers = getRequestHeaders();
        headers['Content-Type'] = 'multipart/form-data';    
        let response = await axios.post(config.apiV2Url + '/devices/import', formData,
            {
                mode: 'cors',
                headers: headers
            }
        )
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getDeviceLogs = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/devices/logs' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getDeviceList,
    getDeviceDetails,
    saveDeviceDetails,
    createDevice,
    deleteDevice,
    importDevices,
    getDeviceLogs
}