import react from 'react';
import V2Layout from './Layout';
import { Box, Card, CardContent, Grid, LinearProgress, TextField } from '@mui/material';
import { translate } from '../../i18n/customI18nProvider';
import { getDeviceDetails } from '../../services/device.service';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import moment from 'moment';
import DeviceLogs from './DeviceLogs';

const PHONE_NUMBER_REGEX = /^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

class ViewDevice extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            device_id: null,
            title: translate('custom.devices'),
            loading: false,
            is_form_valid: true,
            form_error: {},
            // ------
            updated: '', // date field ( read-only )
            app_version: '', // read-only
            type: '', // smartphone, modem, terminal_pos
            number: '', // string
        }
    }

    async componentDidMount() {
        let device_id = this.props?.match?.params?.device_id ? this.props.match.params.device_id : null;
        await this.setState({
            loading: true,
            device_id,
            title: translate('custom.device_details')
        });
        await this.getDevice();
        this.setState({ loading: false });
    }   

    async getDevice() {
        // if device_id, fetch device details
        let device = await getDeviceDetails(this.state.device_id);
        if (device.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: device.error_code ? convertMessageCodeToMessage(device.error_code) : device.error
            });
            return;
        }
        device = device.device;

        await this.setState({
            updated: device.updatedAt ? moment(device.updatedAt) : null,
            app_version: device.app_version ? device.app_version : '',
            type: device.type ? device.type : '',
            number: device.number ? device.number : '',
        });
    }

    render() {
        return (
            <V2Layout
                currentMenu='devices'
                title={this.state.title}
            >

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                                <TextField fullWidth label={translate('custom.number')} variant='outlined' disabled size='small'
                                    value={this?.state?.number ? this.state.number : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                                <TextField fullWidth label={translate('custom.type')} variant='outlined' disabled size='small'
                                    value={this?.state?.type ? translate(`custom.${this.state.type}`) : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} xl={3}>
                                <TextField fullWidth label={translate('custom.app_version')} variant='outlined' disabled size='small'
                                    value={this?.state?.app_version ? this.state.app_version : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} xl={3}>
                                <TextField fullWidth label={translate('custom.updated')} variant='outlined' disabled size='small'
                                    value={this?.state?.updated ? moment(this.state.updated).format(translate('custom.OPTIONS.date_time_format')) : ''}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                {(this.state.number) ? (
                    <DeviceLogs
                        device_number={this.state.number}
                        hide_columns={['device_number']}
                    />
                ) : null}

            </V2Layout>
        );
    }
}

export default ViewDevice;
