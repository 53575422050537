import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DateField,
    Pagination,
    SimpleList,
    FunctionField,
    NumberField
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { formatCurrency, formatCurrencyWithCode } from "../util";
import {translate} from "../i18n/customI18nProvider";
import Actions from '../components/reactAdmin/Actions';

import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export const SuspiciousClientIcon = SentimentDissatisfiedIcon;

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CustomViewButton = ({record}) => {
    return (
        <EditButton to={'/clients/' + record.uid} />
    )
}

export const SuspiciousClientList = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <>
            <List {...props} hasCreate={false} bulkActionButtons={false} actions={<Actions />} pagination={<PostPagination />} perPage={50} >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.name ? record.name : record.uid }
                        secondaryText={record => record.name ? record.uid : record.short_id}
                        tertiaryText={record => formatCurrencyWithCode(record.balance, props.options.currencyCode)}
                    />
                ) : (
                    <Datagrid hasBulkActions={false} >
                        <TextField label="UID" source="uid" />
                        <TextField label={translate('custom.short_id')} source="short_id" />
                        <DateField label={translate('custom.creation_date')} source="creation_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                        <NumberField label={translate('custom.balance')} source="balance" locales={translate('custom.OPTIONS.i18n_number_format')} options={{ style: 'decimal', minimumFractionDigits: 2 }}/>
                        <TextField label={translate('custom.name')} source="name" />
                        <FunctionField label={translate('custom.suspicious_activity')} render={record => translate('custom.'+record.suspicious_activity)} />
                        {/* <EditButton /> */}
                        <CustomViewButton />
                        {/* <EditButton onClick={(record) => {
                            console.log("Record: ", record);
                        }} /> */}
                    </Datagrid>
                )}
            </List>

        </>
    );
};
