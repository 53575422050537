import { Button, Grid } from "@material-ui/core";
import React, { Component } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";
import { requestMultipart } from "../../util";

class AssetUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  onChange = e => {
    let files = e.target.files;
    this.setState({ files: files });
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
    }
    
    requestMultipart('/assets', 'POST', null, formData)
        .then((response) => {
            if (response.error) {
              if (this.props.uploadError)
                this.props.uploadError(response.error);
            } else {
              if (this.props.uploadSuccess)
                this.props.uploadSuccess(response?.data?.assets ? response.data.assets : []);
            }
        })
        .catch((error) => {
            console.log(error);
            alert(error.message);
        });
  };

//   upload = () => {
//     const formData = new FormData();
//     for (let i = 0; i < this.state.files.length; i++) {
//         formData.append("file" + i, this.state.files[i]);
//     }
//     formData.append("file", this.state.file);
//     console.log(formData);
//     // this.props.uploadAsset(formData);
//   };

  render() {
    return (
    //   <div>
    //     <form onSubmit={this.onSubmit}>
    //       <input type="file" onChange={this.onChange} />
    //       <button type="submit">Submit</button>
    //     </form>
    //   </div>
        <Grid container>
          <Grid item xs={12}>
            <Button
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                // onClick={this.upload}
            >
                <input
                  type="file"
                  onChange={this.onChange}
                  multiple={this.props.multiple}
                  accept={this.props.accept}
                />
            </Button>
          </Grid>
          <Grid item xs={12}>
            {(this.props?.helperText) ? (
                <small style={{color: 'gray'}}>{this.props.helperText}</small>
            ) : null}
          </Grid>
        </Grid>
    );
  }
}

export default AssetUpload;